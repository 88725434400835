import { HStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { VARIANTS } from 'shared';

import { Participant } from 'features/participants/config/types';
import { useParticipantsQuery } from 'features/participants/hooks';

import ButtonLink from 'shared/components/Button/ButtonLink';

import { HeaderTabsParticipantsMenu } from './ParticipantsMenu';

const PARTICIPANTS = {
  subRoute: '/coach/participants',
  label: 'Participants',
};
const TASK_MANAGER = {
  subRoute: '/coach/task-manager',
  label: 'Control Center',
};

const participantMapper = ({
  userName,
  userId,
  userEmail,
  userPicture,
}: Participant) => {
  return {
    userName,
    userId,
    userEmail,
    pictureUrl: userPicture.url,
  };
};

export const MainHeaderTabs = () => {
  const location = useLocation();

  // @ts-ignore
  const { participants } = useParticipantsQuery(participantMapper);

  const [, first, second] = location.pathname.split('/');
  const subRoute = `/${first}/${second}`;

  const isParticipantsActive = subRoute === PARTICIPANTS.subRoute;
  const isTaskManagerActive = subRoute === TASK_MANAGER.subRoute;

  return (
    <HStack>
      <HeaderTabsParticipantsMenu
        participants={participants}
        isActive={isParticipantsActive}
      />
      <ButtonLink
        size="xs"
        to={TASK_MANAGER.subRoute}
        variant={isTaskManagerActive ? VARIANTS.PRIMARY : VARIANTS.SECONDARY}
      >
        {TASK_MANAGER.label}
      </ButtonLink>
    </HStack>
  );
};
