import { useMemo } from 'react';

import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';

import {
  CHALLENGE_DIFFICULTY_RATINGS,
  CHALLENGE_TYPES,
  DEFAULT_EMOJI,
  DIMENSIONS,
  TARGET_TYPES,
} from 'features/challenge/config';

export const useInitialChallenge = () => {
  const weeksCount = useSprintDurationFeatureFlag();

  return useMemo(
    () => ({
      title: '',
      description: '',
      emoji: DEFAULT_EMOJI,
      dimensions: [DIMENSIONS.HEALTH],
      goals: [],
      passes: 0,
      target: {
        type: TARGET_TYPES.WEEKLY,
        weekly: new Array(weeksCount).fill(1),
      },
      tags: [],
      type: CHALLENGE_TYPES.REPEATING,
      private: false,
      proofRequired: false,
      difficultyRating: CHALLENGE_DIFFICULTY_RATINGS.MEDIUM,
    }),
    [weeksCount]
  );
};
