import { logError } from 'lib/sentry/logError';

import { SESSION_DATE_TIME_OVERLAP_ERROR } from 'features/sessions/config';
import { ScheduledSessionConfigType } from 'features/sessions/config/types';
import { useSessionScheduleMutation } from 'features/sessions/hooks/mutation/useSessionSchedule';

import { showErrorToast } from 'shared/components/Toast';

type SessionScheduleProps = {
  userId: string;
  previousSessionId: string;
  onSuccess?: () => void;
  options?: any;
};

export const useSessionSchedule = ({
  userId,
  options,
  previousSessionId,
  onSuccess,
}: SessionScheduleProps) => {
  const { mutateAsync, isLoading, error } = useSessionScheduleMutation({
    userId,
    previousSessionId,
    options,
  });

  const isSessionDateTimeOverlapError =
    error?.name === SESSION_DATE_TIME_OVERLAP_ERROR;

  const handleSessionSchedule = async ({
    endAt,
    ...rest
  }: ScheduledSessionConfigType) => {
    try {
      await mutateAsync(rest);
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (!isSessionDateTimeOverlapError) {
        showErrorToast('Failed to schedule session. Please try again.');
      }
      logError(e);
    }
  };

  return {
    handleSessionSchedule,
    isLoading,
    isSessionDateTimeOverlapError,
  };
};
