import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';

import { showErrorToast, showSuccessToast } from 'shared/components/Toast';

export const useParticipantsTableCancelMutateOptions = (sessionId: string) => {
  const queryClient = useQueryClient();

  const onSuccess = () => {
    showSuccessToast('Session cancelled successfully.');
    queryClient.invalidateQueries([queryKeys.participants]);
    queryClient.removeQueries([queryKeys.coachSession, sessionId]);
  };

  const onError = (err: Error) => {
    showErrorToast('Failed to cancel session. Please try again later.');
    logError(err);
  };

  return {
    onSuccess,
    onError,
  };
};
