import { Box } from 'shared';

type TJournalCardUnreadIndicator = { options: any };

export const JournalCardUnreadIndicator = ({
  options,
}: TJournalCardUnreadIndicator) => (
  <Box
    style={{
      ...options,
      position: 'absolute',
      width: '0.5rem',
      height: '0.5rem',
      borderRadius: '50%',
      backgroundColor: 'var(--bgCompAccent)',
    }}
    data-testid="journal-sidebar-card-unread-indicator"
  />
);
