import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { ModalBody, ModalFooter, ModalHeader } from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';
import { Typography } from 'shared/components/Typography';

type TGoalDeleteDialog = {
  onSubmit: () => Promise<void>;
};

export const GoalDeleteDialog = ({ onSubmit }: TGoalDeleteDialog) => (
  <>
    <ModalHeader>
      <Typography as="h1" type="heading" fontWeight="semiBold" color="black">
        Delete this goal?
      </Typography>
    </ModalHeader>
    <ModalBody>
      <Typography color="gray" type="meta">
        You won’t be able to restore the goal after you delete it.
      </Typography>
    </ModalBody>
    <ModalFooter>
      <Spacers sizes={['zr md zr zr', 'zr sm zr zr']}>
        <ModalButton variant={VARIANTS.SECONDARY}>Cancel</ModalButton>
      </Spacers>
      <ModalButton
        dataTestid="delete-goal-dialog-window"
        variant={VARIANTS.CRITICAL}
        onClick={onSubmit}
      >
        Delete
      </ModalButton>
    </ModalFooter>
  </>
);
