import { AbsoluteCenter } from '@chakra-ui/react';
import { When } from 'react-if';

import { Box, ButtonIcon, Typography, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';

import {
  ParticipantsTableActionWrapper,
  ParticipantsTableCounter,
} from 'features/participants/components/Table/Actions/';

type TParticipantTableNotesButton = {
  onClick: () => void;
  unreadCount: number;
  unreadItemDateTime: Nullable<string>;
};

export const ParticipantTableNotesButton = ({
  onClick,
  unreadCount,
  unreadItemDateTime,
}: TParticipantTableNotesButton) => (
  <ButtonIcon
    ariaLabel="open notes"
    icon={
      <ParticipantsTableActionWrapper>
        <Box position="relative">
          <When condition={unreadCount > 0}>
            <AbsoluteCenter insetY="5px">
              <Typography
                as="span"
                color="black"
                type="xsm"
                data-testid="unread-new-note-counter-participant-list"
              >
                {unreadCount}
              </Typography>
            </AbsoluteCenter>
          </When>
          <Box data-testid="message-icon-container">
            <Icon name={iconNames.message} width={21} height={21} />
          </Box>
          {unreadItemDateTime && (
            <ParticipantsTableCounter
              value={unreadItemDateTime}
              dataTestid="journal-unread-item-time"
            />
          )}
        </Box>
      </ParticipantsTableActionWrapper>
    }
    onClick={onClick}
    size="sm"
    variant={VARIANTS.TERTIARY}
    dataTestid="participant-notes-btn"
  />
);
