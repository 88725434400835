import { ReactNode } from 'react';

import { Flex } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

type TJournalCardContainer = {
  children: ReactNode;
  dataTestid?: string;
};

export const JournalCardContainer = ({
  children,
  dataTestid = '',
}: TJournalCardContainer) => (
  <Box
    style={{
      backgroundColor: 'var(--bgSecondary)',
      borderRadius: 'var(--border-radius12)',
      width: '100%',
    }}
    dataTestid={dataTestid}
  >
    <Spacer size="sm md">
      <Flex gap="0.5rem" flexDirection="column">
        {children}
      </Flex>
    </Spacer>
  </Box>
);
