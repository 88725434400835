import { logError } from 'lib/sentry/logError';

import {
  Goal,
  GOAL_DEEP_DIVE_DEFAULT_VALUE,
  IGoal,
  useEditGoalMutation,
  useGoalPageMatcher,
  useGoalsUpdateQuery,
} from 'features/goal';
import { useUploadGoalCover } from 'features/goal/hooks';
import { useUserId } from 'features/user/hooks/useUserId';

import { showErrorToast } from 'shared/components/Toast';
import { removeBase64Metadata } from 'shared/utils/base64';

import { useOpenEditGoalModal } from './useOpenEditGoalModal';

export const useEditGoal = ({ goal }: { goal: IGoal | Goal }) => {
  const editGoalMutation = useEditGoalMutation({
    goalId: goal.goalId,
    modifiedColumnId: goal.columnId,
  });
  const userId = useUserId();

  const { invalidateGoalQuery, invalidateGoalsQuery } = useGoalsUpdateQuery({
    userId,
    modifiedColumnId: '',
  });

  const isUserInTheGoalPage = useGoalPageMatcher();

  const uploadGoalCoverMutation = useUploadGoalCover({
    options: {
      onSuccess: () => {
        if (isUserInTheGoalPage) {
          invalidateGoalQuery(goal.goalId);
        } else {
          invalidateGoalsQuery();
        }
      },
    },
  });

  const onSubmit = async (goal: IGoal & { imageToUpload?: string }) => {
    try {
      await editGoalMutation.mutateAsync(goal);

      if (goal.imageToUpload) {
        await uploadGoalCoverMutation.mutateAsync({
          goal,
          file: removeBase64Metadata(goal.imageToUpload),
        });
      }
    } catch (err) {
      showErrorToast(
        "There's an error with editing the goal. Please try again later."
      );
      logError(err);
    }
  };

  const openEditGoalModal = useOpenEditGoalModal('Edit the goal');

  return {
    onEditGoalClick: () =>
      openEditGoalModal({
        onSubmit,
        goal: {
          ...goal,
          deepDive: goal.deepDive || GOAL_DEEP_DIVE_DEFAULT_VALUE,
        },
      }),
  };
};
