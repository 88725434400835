import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';

import { showSuccessToast } from 'shared/components/Toast';

export const useSessionCancelMutation = (
  sessionId: string,
  options: any = {}
) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const prevSessionId = searchParams.get('prevSessionId');

  return useMutation(
    ({ reason }: { reason: string }) =>
      request({
        url: `/api/coach/coaching-sessions/${sessionId}/cancel`,
        body: {
          reason,
        },
      }),
    {
      onSuccess: () => {
        showSuccessToast('Session cancelled successfully.');
        queryClient.removeQueries([queryKeys.coachSession, sessionId]);
        queryClient.removeQueries([queryKeys.coachSession, prevSessionId]);
      },
      ...options,
    }
  );
};
