import { When } from 'react-if';

import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  TABLE_CELL_DEFAULT_VALUE,
  SESSION_ATTENDANCE_STATUS,
} from 'features/sessions/config';
import { SessionsTableRowDataType } from 'features/sessions/config/types';
import {
  getSessionDurationText,
  getSessionTimeFrame,
} from 'features/sessions/utils';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type SessionsTableTimeCellProps = {
  value: number;
  row: SessionsTableRowDataType;
};

export const SessionsTableTimeCell = ({
  value: sessionDuration,
  row,
}: SessionsTableTimeCellProps) => {
  const durationText = getSessionDurationText(sessionDuration);
  const { attendance, startAt, endAt } = row.original;

  const isTimeVisible =
    attendance === SESSION_ATTENDANCE_STATUS.ATTENDED ||
    attendance === SESSION_ATTENDANCE_STATUS.SCHEDULED ||
    attendance === SESSION_ATTENDANCE_STATUS.RESCHEDULED;

  return (
    <Flex gap="var(--spacing02)" justifyContent="flex-start">
      {isTimeVisible ? (
        <>
          <When condition={!!startAt && !!endAt}>
            <Typography
              type="small"
              color="gray"
              fontWeight="regular"
              whiteSpace="nowrap"
              dataTestid="session-time-frame-cell"
            >
              {getSessionTimeFrame(startAt!, endAt!)}
            </Typography>
          </When>
          <Badge bgColor="gray">
            <Typography
              type="small"
              whiteSpace="nowrap"
              color="black"
              dataTestid="session-duration-cell"
            >
              {durationText}
            </Typography>
          </Badge>
        </>
      ) : (
        <Typography
          type="small"
          color="gray"
          fontWeight="regular"
          whiteSpace="nowrap"
        >
          {TABLE_CELL_DEFAULT_VALUE}
        </Typography>
      )}
    </Flex>
  );
};
