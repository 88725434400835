import { FormControl, FormLabel, Switch } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { useProofFieldConfig } from 'features/challenge/hooks//useProofFieldConfig';

export const ProofField = () => {
  const { checked, onChange } = useProofFieldConfig();

  return (
    <FormControl>
      <Flex>
        <FormLabel>Automatically prompt journal</FormLabel>
        <Switch
          isChecked={checked}
          onChange={onChange}
          size="sm"
          data-testid={`challenge-dialog-proof-field-button${
            checked ? '-checked' : ''
          }`}
        />
      </Flex>
    </FormControl>
  );
};
