import Box from 'shared_DEPRECATED/components/Box';
import {
  ReactionsButtonsRow,
  ReactionsSelectionRow,
  ReactionEmojiPicker,
} from 'shared_DEPRECATED/components/Reactions';
import {
  ACTION_TYPES,
  DEFAULT_REACTIONS,
  DEFAULT_REACTIONS_OPTIONS,
} from 'shared_DEPRECATED/components/Reactions/config';
import {
  ReactionActionType,
  ReactionsProp,
} from 'shared_DEPRECATED/components/Reactions/types';

import styles from 'shared_DEPRECATED/components/Reactions/Reactions.module.css';

export type ReactionsPropsType = {
  emojis?: string[];
  reactions?: ReactionsProp;
  onEmojiSelect: (data: { emoji: string; type: ReactionActionType }) => void;
  theme?: 'light' | 'dark';
  options?: any;
};

export const Reactions = ({
  emojis = DEFAULT_REACTIONS,
  onEmojiSelect,
  reactions = {},
  theme = 'light',
  options = DEFAULT_REACTIONS_OPTIONS,
}: ReactionsPropsType) => {
  const handleEmojiSelect = (emoji: string) => {
    const isMyReaction = reactions[emoji]?.isMy;
    const type = isMyReaction ? ACTION_TYPES.REMOVE : ACTION_TYPES.ADD;

    onEmojiSelect({
      emoji,
      type,
    });
  };

  return (
    <Box>
      <div className={styles.reactions}>
        <ReactionsButtonsRow>
          <ReactionsSelectionRow
            onEmojiSelect={handleEmojiSelect}
            reactions={reactions}
            theme={theme}
          />
          <ReactionEmojiPicker
            handleEmojiSelect={handleEmojiSelect}
            options={options}
            emojis={emojis}
          />
        </ReactionsButtonsRow>
      </div>
    </Box>
  );
};
