import { Nullable } from 'shared_DEPRECATED/types';

import { GoalYears, GoalDeadline } from 'features/goal';

export const GoalBadge = ({ deadline, years, completedAt }: TGoalBadge) => {
  return (
    <>
      {deadline || completedAt ? (
        <GoalDeadline deadline={deadline} completedAt={completedAt} />
      ) : (
        ''
      )}
      {years ? <GoalYears years={years} /> : ''}
    </>
  );
};

type TGoalBadge = {
  deadline: Nullable<string>;
  years?: string;
  completedAt: Nullable<string>;
};
