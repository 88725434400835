import { CONSTANTS } from 'config/envVariables';

export const getUserName = (name: string, email: string) => name || email;

let apolloWindow: any = null;

export const navigateToApollo = () => {
  if (apolloWindow && !apolloWindow.closed) {
    apolloWindow.focus();
  } else {
    // @ts-ignore
    apolloWindow = window.open(CONSTANTS.APOLLO_HOST, '_blank');
  }
};
