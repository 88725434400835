import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

const useFinishSprintMutation = ({ sprintId, onSettled, onSuccess }) => {
  const { request } = useMutationHTTPRequest();

  const mutation = useMutation(
    async () => {
      await request({ url: `/api/sprints/${sprintId}/finish` });
    },
    {
      onSettled,
      onSuccess,
      onError: (error) => {
        logError(error);
      },
    }
  );

  return mutation;
};

export default useFinishSprintMutation;
