import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import useEventsSubscription from 'shared_DEPRECATED/hooks/useEventsSubscription';

import { Modal, SIZES, ModalProvider } from 'shared/components/Modal';
import {
  MODAL_OPEN_EVENT,
  MODAL_CLOSE_EVENT,
} from 'shared/components/Modal/eventPublishers';

const initialContent = null;
const initialProps = {
  size: SIZES.SM,
  title: '',
};
const initialContext = {};

export const ModalContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [content, setContent] = useState(initialContent);
  const [modalProps, setModalProps] = useState(initialProps);
  const [context, setContext] = useState(initialContext);

  const handleOpen = useCallback(
    (event: CustomEvent) => {
      const { content, props, context: newContext } = event.detail;

      setContent(content);
      setModalProps(Object.assign(initialProps, props || {}));
      setContext(Object.assign(initialContext, newContext || {}));

      onOpen();
    },
    [onOpen]
  );

  const events = useMemo(
    () => [
      [MODAL_OPEN_EVENT, handleOpen] as [string, Function],
      [MODAL_CLOSE_EVENT, onClose] as [string, Function],
    ],
    [handleOpen, onClose]
  );

  useEventsSubscription(events);

  return (
    <ModalProvider {...context}>
      <Box title={modalProps.title}>
        <Modal
          {...modalProps}
          onClose={onClose}
          isOpen={isOpen}
          isCentered={true}
        >
          {content}
        </Modal>
      </Box>
    </ModalProvider>
  );
};
