import { When } from 'react-if';

import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { Typography } from 'shared/components/Typography';

type SessionsTableDateCellProps = {
  value: Nullable<string>;
};

export const SessionsTableDateCell = ({
  value: sessionDate,
}: SessionsTableDateCellProps) => {
  return (
    <When condition={!!sessionDate}>
      <Typography
        type="small"
        color="gray"
        fontWeight="regular"
        dataTestid="session-date-cell"
      >
        {dateUtils.abbreviatedMonthDate(sessionDate)}
      </Typography>
    </When>
  );
};
