import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import {
  createTemporaryJournalNote,
  JournalNote,
  JournalNotePOSTType,
  useJournalQueryActions,
} from 'features/journal';
import { ParticipantSprint } from 'features/sprint';
import { useSprints } from 'features/sprint/hooks';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

import { showErrorToast } from 'shared/components/Toast';

export const useJournalAddNoteMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const user = useCurrentModeUser();
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const { data: sprints = [] } = useSprints(user.userEmail || user.email) as {
    data: ParticipantSprint[];
  };

  const [currentSprint] = sprints;

  const {
    cancelJournalNoteQueries,
    updateJournalNoteQueryData,
    updateJournalRelevantQueries,
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
  } = useJournalQueryActions();

  return useMutation(
    async (data: JournalNotePOSTType) =>
      await request({
        url: `/api/journal/notes/create`,
        body: mapJournalNotePostData({
          ...data,
          sprintId: currentSprint?.sprintId,
          isSprintActive: currentSprint.isActive,
        }),
      }),
    {
      onMutate: async (note: JournalNotePOSTType) => {
        cancelJournalNoteQueries();

        const temporaryJournalNote = createTemporaryJournalNote({
          userId: user.userId,
          sprint: currentSprint,
          ...note,
        });
        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: [temporaryJournalNote, ...page.notes],
          }));
        });

        return {
          previousJournalNotesData,
          temporaryJournalNoteId: temporaryJournalNote.noteId,
        };
      },
      onSuccess: (newNote: JournalNote, _, context) => {
        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) =>
              context?.temporaryJournalNoteId === note.noteId ? newNote : note
            ),
          }));
        });

        updateJournalNoteQueryData(newNote.noteId, () => newNote);
        onSuccess?.();
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with creating note. Please try again later."
        );

        logError(err);

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });
      },
    }
  );
};

const mapJournalNotePostData = ({
  sprintId,
  isSprintActive,
  ...note
}: JournalNotePOSTType & { isSprintActive: boolean }) => ({
  ...note,
  ...(isSprintActive && { sprintId }),
});
