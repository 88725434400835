import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { IBaseChallenge } from 'features/challenge/config/types';
import {
  JournalReplies,
  JournalNotes,
  JournalNote,
  JOURNAL_SPRINT_FILTERS,
  JournalReaction,
  JournalAuthor,
  JournalAuthorPicture,
  JOURNAL_CHALLENGE_FILTERS,
  JournalNoteType,
  FailReasonsType,
  JournalQueryParamsType,
  JournalItemType,
  JOURNAL_NOTE_ITEM_TYPES,
} from 'features/journal';
import { ParticipantSprint } from 'features/sprint';
import { IFinalSprint } from 'features/sprint/config/types';
import { User } from 'features/user/User';

import { sidebar } from 'shared/components/Sidebar';
import { getUuId } from 'shared/utils/uuid';

export const createTemporaryJournalNote = ({
  userId,
  text,
  noteType,
  challengeFailReasons,
  challengeId,
  sprint,
}: {
  userId: string;
  text?: string;
  noteType: JournalNoteType;
  challengeFailReasons: FailReasonsType;
  challengeId?: Nullable<string>;
  sprint: ParticipantSprint;
}) => {
  const temporaryNoteId = getUuId();
  const challengeTitle = challengeId && sprint.getChallenge(challengeId)?.title;

  return {
    noteId: temporaryNoteId,
    authorId: userId,
    text,
    noteType,
    sprintId: sprint.sprintId,
    challenge: {
      id: challengeId,
      title: challengeTitle,
      status: '',
      statusDate: dateUtils(new Date()).format('YYYY-MM-DD'),
      failReasons: challengeFailReasons,
    },
    mediaList: [],
    repliesCount: 0,
    createdAt: dateUtils().toISOString(),
    reactions: [],
    isRead: true,
    hasUnreadReplies: false,
  };
};

export const createTemporaryJournalReaction = ({
  user,
  reaction,
}: {
  user: User;
  reaction: string;
}) => {
  const temporaryReactionId = parseInt(getUuId());

  return {
    reactionId: temporaryReactionId,
    author: {
      userId: user.userId,
      picture: { url: user.picture.url } as JournalAuthorPicture,
      fullName: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
    },
    temporaryReactionId,
    reaction,
    createdAt: new Date().toISOString(),
  };
};

export const createTemporaryJournalReply = ({
  user,
  noteId,
  text,
}: {
  user: User;
  noteId: string;
  text: string;
}) => {
  const temporaryReplyId = getUuId();

  return {
    author: {
      userId: user.userId,
      picture: { url: user.picture.url } as JournalAuthorPicture,
      fullName: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
    },
    createdAt: new Date().toISOString(),
    replyId: temporaryReplyId,
    noteId,
    text,
    updatedAt: null,
    reactions: [],
    isRead: true,
  };
};

export const groupedReactionsByAuthor = (
  reactions: JournalReaction[],
  userId: string
) => {
  return reactions?.reduce((acc, reaction) => {
    const authorId = reaction.author.userId;

    if (!acc[authorId]) {
      acc[authorId] = {
        author: reaction.author,
        isMy: Boolean(userId === authorId),
        reactions: [],
      };
    }

    acc[authorId].reactions.push(reaction);

    return acc;
  }, {} as { [authorId: string]: { author: JournalAuthor; isMy: boolean; reactions: JournalReaction[] } });
};

export const getNotesItems = (
  notes: JournalNote[],
  sprints: IFinalSprint[]
): JournalItemType[] => {
  let sprintId: Nullable<string> = null;
  let date: Nullable<string> = null;
  let sprintEndDate: Nullable<string> = null;

  const sprintsById = sprints.reduce((items, sprint) => {
    items[sprint.sprintId] = sprint;

    return items;
  }, {} as { [sprintId: string]: IFinalSprint });

  return notes.reduce<JournalItemType[]>((items, note) => {
    const noteDate =
      note.challenge?.statusDate ??
      dateUtils(note.createdAt).format('YYYY-MM-DD');

    const isSprintChanged = note.sprintId && note.sprintId !== sprintId;
    const isDateChanged = noteDate !== date;

    if (isSprintChanged) {
      sprintId = note.sprintId;
      const sprint = sprintsById[sprintId];
      sprintEndDate = sprint?.endDate;

      sprint &&
        items.push({
          type: JOURNAL_NOTE_ITEM_TYPES.SPRINT_SECTION,
          data: {
            sprintTitle: sprint.title,
            sprintDates: sprint.formattedDate,
          },
        });
    }

    const isNoteCreatedBeforeSprintEnd =
      !note.sprintId &&
      sprintEndDate &&
      dateUtils(noteDate).isBefore(sprintEndDate);

    if (isNoteCreatedBeforeSprintEnd) {
      sprintEndDate = null;

      items.push({
        type: JOURNAL_NOTE_ITEM_TYPES.NOTE_WITHOUT_CHALLENGES,
        data: {
          title: 'Notes without challenges',
        },
      });
    }

    if (isDateChanged) {
      date = noteDate;

      items.push({
        type: JOURNAL_NOTE_ITEM_TYPES.DATE_SECTION,
        data: {
          date,
        },
      });
    }

    items.push({
      type: JOURNAL_NOTE_ITEM_TYPES.NOTE,
      data: {
        note,
      },
    });

    return items;
  }, []);
};

export const createJournalSprintFilterState = (
  selectedSprintIds: string[],
  currentSprint: IFinalSprint,
  pastSprints: IFinalSprint[]
): Record<string, boolean> => {
  const currentSprintId = currentSprint?.sprintId;

  return pastSprints.reduce<Record<string, boolean>>(
    (acc, { sprintId }) => {
      const isPastSelected = selectedSprintIds.includes(sprintId);

      return {
        ...acc,
        [`${JOURNAL_SPRINT_FILTERS.PAST}-${sprintId}`]: isPastSelected,
      };
    },
    {
      [JOURNAL_SPRINT_FILTERS.ALL]: selectedSprintIds.includes(
        JOURNAL_CHALLENGE_FILTERS.ALL
      ),
      [JOURNAL_SPRINT_FILTERS.CURRENT]:
        selectedSprintIds.includes(currentSprintId),
    }
  );
};

export const createJournalChallengeFilterState = (
  selectedChallengeIds: string[],
  challenges: IBaseChallenge[]
): Record<string, boolean> =>
  challenges.reduce<Record<string, boolean>>(
    (acc, { challengeId }) => ({
      ...acc,
      [`${JOURNAL_CHALLENGE_FILTERS.CURRENT_CHALLENGES}-${challengeId}`]:
        selectedChallengeIds.includes(challengeId),
    }),
    {
      [JOURNAL_CHALLENGE_FILTERS.ALL]:
        challenges.length !== 0 &&
        selectedChallengeIds.length === challenges.length,
      [JOURNAL_CHALLENGE_FILTERS.WITHOUT_CHALLENGES]:
        selectedChallengeIds.length === 0,
    }
  );

export const openJournalSidebar = ({
  user,
  participantId,
  participantEmail,
  filters,
}: {
  user: User;
  participantEmail: string;
  participantId?: string;
  filters?: JournalQueryParamsType;
}) =>
  sidebar.open({
    content: <JournalNotes />,
    props: { title: 'Journal Notes Sidebar' },
    context: {
      user,
      location: 'notes',
      participantEmail,
      participantId,
      filters,
    },
  });

export const openJournalRepliesSidebar = ({
  noteId,
  user,
  participantEmail,
  participantId,
  filters,
}: {
  noteId: string;
  user: User;
  participantEmail: string;
  participantId: string;
  filters?: JournalQueryParamsType;
}) =>
  sidebar.open({
    content: <JournalReplies />,
    props: { title: 'Journal Replies Sidebar' },
    context: {
      noteId,
      user,
      location: 'replies',
      participantEmail,
      participantId,
      filters,
    },
  });
