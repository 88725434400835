import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalAddFormNameSection,
  GoalAddFormTimelineSection,
  GoalAddFormDeadlineSection,
  GoalAddFormDimensionsSection,
  GoalAddFormNoteSection,
  GoalAddDialogHeader,
  GoalAddDialogFooter,
  IGoal,
} from 'features/goal';
import { GoalAddFormCoverSection } from 'features/goal/components/Button/Add/Form/Cover';

import { ModalBody, ModalHeader, ModalFooter } from 'shared/components/Modal';

type TGoalAddDialog = {
  onSubmit: (goal: IGoal) => void;
  goal: IGoal;
  title: string;
};

const GoalAddDialog = ({ onSubmit, goal, title }: TGoalAddDialog) => {
  const methods = useForm<IGoal>({
    defaultValues: { ...goal },
  });
  const { reset, handleSubmit } = methods;

  const onSubmitButtonClick = handleSubmit((goal: IGoal) => onSubmit(goal));

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <>
      <ModalHeader>
        <GoalAddDialogHeader title={title} />
      </ModalHeader>
      <FormProvider {...methods}>
        <ModalBody>
          <Spacer size="sm" />
          <GoalAddFormCoverSection />
          <GoalAddFormNameSection />
          <GoalAddFormTimelineSection />
          <GoalAddFormDeadlineSection />
          <GoalAddFormDimensionsSection />
          <GoalAddFormNoteSection />
        </ModalBody>
        <ModalFooter>
          <GoalAddDialogFooter onSubmit={onSubmitButtonClick} />
        </ModalFooter>
      </FormProvider>
    </>
  );
};

export default GoalAddDialog;
