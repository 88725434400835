import { IconButton } from '@chakra-ui/react';

import { Button, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useOpenJournalAddNoteModal } from 'features/journal';

export const JournalAddNoteButton = () => {
  const openJournalNoteAddModal = useOpenJournalAddNoteModal();

  return (
    <IconButton
      aria-label="Add note"
      onClick={() => openJournalNoteAddModal()}
      icon={<Icon stroke="var(--fgPrimaryInverse)" name={iconNames.plus} />}
      position="absolute"
      bottom="10px"
      right="10px"
      as={Button}
      variant={VARIANTS.PRIMARY}
      zIndex={11}
    />
  );
};
