import { Box, HStack } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { Badge, Typography } from 'shared';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { SessionsModalSection } from 'features/sessions/components/Modal';
import { getTimeLabel } from 'features/sessions/utils';

import { CoachSessionsScheduleEndTimeSelector } from './EndTimeSelector';
import { CoachSessionsScheduleStartTimeSelector } from './StartTimeSelector';

export const CoachSessionsScheduleModalTimeSelector = () => {
  const [sessionStartDate, sessionEndDate] = useWatch({
    name: ['startAt', 'endAt'],
  });

  return (
    <Box position="relative">
      <SessionsModalSection
        title="Session time"
        dataTestid="session-time-selector"
      >
        <Box position="absolute" right={0} top="-8px">
          <Badge bgColor="lightGray">
            <Typography color="black" type="small">
              {getTimeLabel(
                dateUtils.localDate(sessionStartDate).toISOString(),
                dateUtils.localDate(sessionEndDate).toISOString()
              )}
            </Typography>
          </Badge>
        </Box>
        <HStack
          gap={5}
          divider={
            <Typography type="meta" color="gray">
              -
            </Typography>
          }
        >
          <CoachSessionsScheduleStartTimeSelector />
          <CoachSessionsScheduleEndTimeSelector />
        </HStack>
      </SessionsModalSection>
    </Box>
  );
};
