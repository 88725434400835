import { useState } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { DatePicker } from 'shared_DEPRECATED/components/Form/DatePicker';
import { SIZES } from 'shared_DEPRECATED/components/Form/DatePicker/config';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  SESSION_DATE_FORMAT,
  SESSION_DATE_LABEL,
  SESSION_TIME_INTERVAL,
} from 'features/participants/config';
import { getSessionDateWithDefaultTime } from 'features/participants/utils';
import {
  getFilterTimeSlots,
  getInitialSessionDate,
} from 'features/sessions/utils';

import { ButtonIcon } from 'shared/components/Button';

type SessionDateProps = {
  sessionDateString?: string;
  onDateChange: (date: Nullable<string>) => Promise<void>;
};

export const SessionDateSelector = ({
  sessionDateString,
  onDateChange,
}: SessionDateProps) => {
  const currentSessionDate = !!sessionDateString
    ? dateUtils.localDate(sessionDateString).toDate()
    : null;

  const [sessionDate, setSessionDate] =
    useState<Nullable<Date>>(currentSessionDate);

  const onCalendarClose = async () => {
    if (
      !sessionDate ||
      (currentSessionDate &&
        sessionDate &&
        dateUtils(currentSessionDate).isSame(sessionDate))
    ) {
      return;
    }

    await onDateChange(
      dateUtils(dateUtils.utc(sessionDate)).formatAsISOString()
    );
  };

  const onSessionDateCancel = async () => {
    setSessionDate(null);

    await onDateChange(null);
  };

  const onCalendarDateChange = (date: Date, dayElement: Nullable<Date>) => {
    const sessionDate =
      dayElement && !currentSessionDate
        ? getSessionDateWithDefaultTime(date)
        : date;

    setSessionDate(sessionDate);
  };

  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      dataTestid="participant-next-session-date"
    >
      {/*@ts-ignore*/}
      <DatePicker
        onCalendarClose={onCalendarClose}
        selected={sessionDate}
        onChange={onCalendarDateChange}
        format={SESSION_DATE_FORMAT}
        //@ts-ignore
        minDate={dateUtils().toDate()}
        placeholderText={SESSION_DATE_LABEL}
        //@ts-ignore
        size={SIZES.SM}
        showTimeSelect={true}
        filterTime={getFilterTimeSlots}
        timeIntervals={SESSION_TIME_INTERVAL}
        openToDate={getInitialSessionDate()}
      />
      {sessionDate && (
        <ButtonIcon
          ariaLabel="remove session date"
          dataTestid="participants-session-date-cancel"
          icon={<Icon name={iconNames.cross} width={10} height={10} />}
          onClick={onSessionDateCancel}
        />
      )}
    </Flex>
  );
};
