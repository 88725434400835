import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import BaseInput from 'shared_DEPRECATED/components/Form/Input/BaseInput';

const NumberInputPropTypes = {
  /**
   * @type {string}
   * @memberof NumberInputPropTypes
   * @name defaultValue
   * @example <BaseInput defaultValue="John Doe" />
   */
  defaultValue: PropTypes.string,
  /**
   * @type {string}
   * @memberof NumberInputPropTypes
   * @name name
   * @example <BaseInput name="John Doe" />
   */
  name: PropTypes.string.isRequired,
  /**
   * @type {string}
   * @memberof NumberInputPropTypes
   * @name placeholder
   * @example <BaseInput placeholder="John Doe" />
   */
  placeholder: PropTypes.string,
  /**
   * @type {('sm'|'lg')}
   * @required
   * @memberof NumberInputPropTypes
   * @name size
   * @example <BaseInput size="sm" />
   */
  size: PropTypes.oneOf(['sm', 'lg']),
  /**
   * @type {boolean}
   * @required
   * @memberof NumberInputPropTypes
   * @name error
   * @example <BaseInput error />
   */
  error: PropTypes.bool,
  /**
   * @type {number}
   * @memberof NumberInputPropTypes
   * @name min
   * @example <BaseInput min={0} />
   */
  min: PropTypes.number,
};

const NumberInput = forwardRef(
  ({ name, placeholder = '', min = 0, ...props }, ref) => {
    const handleKeyDown = (e) => {
      const allowedControlKeys = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Tab',
      ];
      if (!/[0-9]/.test(e.key) && !allowedControlKeys.includes(e.key)) {
        e.preventDefault();
      }
    };

    return (
      <BaseInput
        name={name}
        placeholder={placeholder}
        ref={ref}
        type="number"
        min={min}
        onKeyDown={handleKeyDown}
        {...props}
      />
    );
  }
);

NumberInput.propTypes = NumberInputPropTypes;

export default NumberInput;
