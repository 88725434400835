import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useCancelSession } from 'features/sessions/hooks/useCancelSession';

import { MenuItem } from 'shared/components/Menu';

type SessionsTableMoreOptionsCancelMenuItemProps = {
  sessionId: string;
  options?: any;
};

export const SessionsTableMoreOptionsCancelMenuItem = ({
  sessionId,
  options,
}: SessionsTableMoreOptionsCancelMenuItemProps) => {
  const { handleCancelModalOpen } = useCancelSession(sessionId, options);

  return (
    <MenuItem
      onClick={(evt) => {
        evt.stopPropagation();
        handleCancelModalOpen();
      }}
      icon={
        <Icon
          name={iconNames.cancelCircle}
          fill="var(--fgPrimary)"
          width={16}
          height={16}
        />
      }
    >
      Cancel
    </MenuItem>
  );
};
