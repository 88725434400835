import { memo, useCallback } from 'react';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { ChallengeCard } from 'features/challenge/components/ChallengeCard';
import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
} from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import {
  PlanningSprintHeader,
  PlanningBoardColumn,
  PlanningBoardColumnItemsCount,
  usePlanningAnalyticsEvents,
  ChallengeType,
  PlanningDraggableItem,
  getNextLeftChallengeId,
  DRAGGABLE_ITEM_TYPES,
  PlanningSprintRestartedHeader,
} from 'features/planning';
import { useDeleteCoachingToolFromSprintMutation } from 'features/planning/hooks/mutation/useDeleteCoachingToolFromSprint';
import { useMovePlanningChallengeMutation__NEW } from 'features/planning/hooks/mutation/useMovePlanningChallenge__NEW';
import { useCoachingToolsOnDrop } from 'features/planning/hooks/useCoachingToolsOnDrop';
import { SPRINT_STATES } from 'features/sprint/config';
import { SprintRestartedMetaData } from 'features/sprint/config/types';
import { useSprintLink } from 'features/sprint/hooks';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
} from 'shared/components/Board';
import { Droppable } from 'shared/components/DragNDrop/Droppable';
import { getDraggedItemIndex } from 'shared/components/DragNDrop/utils/getDraggedItemIndex';

import { CoachingToolsDraggable } from './Backlog/CoachingToolsDraggable';
import { CoachingToolDroppable } from './Backlog/CoachingToolsDroppable';
import { PlanningBoardColumnItem } from './Column/Item';

type PlanningActiveSprintProps = {
  sprintId: string;
  sprintTitle: string;
  challenges: ChallengeType[];
  coachingTools: CoachingTool[];
  sprintFormattedDate: string;
  sprintIndex: number;
  sprintWeeksCount?: number;
  sprintDurationInWeeks: Nullable<number>;
  sprintRestartedMetaData: Nullable<SprintRestartedMetaData>;
  sprintStartDate: string;
};

export const PlanningActiveSprint = memo(
  ({
    sprintId,
    sprintTitle,
    sprintFormattedDate,
    challenges,
    coachingTools,
    sprintWeeksCount,
    sprintRestartedMetaData,
    sprintDurationInWeeks,
    sprintStartDate,
  }: PlanningActiveSprintProps) => {
    const sprintLink = useSprintLink(sprintId);
    const { sendPlanningViewCurrentSprintClickedEvent } =
      usePlanningAnalyticsEvents();
    const { mutate } = useMovePlanningChallengeMutation__NEW();
    const navigate = useNavigate();
    const onViewButtonClick = useCallback(() => {
      sendPlanningViewCurrentSprintClickedEvent();

      navigate(sprintLink);
    }, [navigate, sendPlanningViewCurrentSprintClickedEvent, sprintLink]);

    const handleOnDrop = useCoachingToolsOnDrop({ coachingTools });
    const { mutateAsync: removeCoachingToolFromSprint } =
      useDeleteCoachingToolFromSprintMutation();

    return (
      <Droppable
        acceptType={[DRAGGABLE_ITEM_TYPES.CHALLENGE]}
        onDrop={(item: PlanningDraggableItem) => {
          const { id, parentId, location } = item;

          mutate({
            id,
            fromBacklog: parentId === CHALLENGE_LOCATIONS.BACKLOG,
            fromSprintId:
              parentId === CHALLENGE_LOCATIONS.BACKLOG ? undefined : parentId,
            toBacklog: false,
            toSprintId: sprintId,
            underChallengeId: getNextLeftChallengeId({
              challenges: challenges,
              id,
              newIndex: challenges.length,
              isNew: true,
            }),
            prevLocation: location,
            newLocation: CHALLENGE_LOCATIONS.ACTIVE_SPRINT,
          });

          item.parentId = sprintId;
          item.location = CHALLENGE_LOCATIONS.ACTIVE_SPRINT;
        }}
        onHover={(item: PlanningDraggableItem) => {
          item.index = challenges.length;
        }}
      >
        <PlanningBoardColumn shouldScrollIntoView={true}>
          <Box
            style={{
              height: '100%',
              width: '100%',
              borderRadius: 'var(--border-radius12)',
            }}
          >
            <CoachingToolDroppable
              sprintId={sprintId}
              onDrop={async (item: any) => {
                await handleOnDrop(
                  item,
                  sprintId,
                  CHALLENGE_LOCATIONS.ACTIVE_SPRINT
                );
              }}
            >
              <BoardColumnWrapper fraction={1}>
                <BoardColumnHeader minHeight="3rem">
                  <PlanningSprintHeader
                    title={sprintTitle}
                    sprintState={SPRINT_STATES.ACTIVE}
                    sprintFormattedDate={sprintFormattedDate}
                    sprintId={sprintId}
                    durationInWeeks={sprintDurationInWeeks}
                    onViewButtonClick={onViewButtonClick}
                    sprintStartDate={sprintStartDate}
                  >
                    {sprintRestartedMetaData ? (
                      <PlanningSprintRestartedHeader
                        restartedAt={dateUtils(
                          sprintRestartedMetaData!.restartedAt
                        ).format('MMM D')}
                      />
                    ) : null}
                  </PlanningSprintHeader>
                </BoardColumnHeader>
                <BoardColumn>
                  <When condition={challenges.length > 0}>
                    <PlanningBoardColumnItemsCount
                      name="challenge"
                      count={challenges.length}
                    />

                    {challenges.map((challenge, challengeIndex) => {
                      return (
                        <PlanningBoardColumnItem
                          key={`${challenge.challengeId}-${challengeIndex}`}
                          type="challenge"
                          id={challenge.challengeId!}
                          index={challengeIndex}
                          onDrop={(item: PlanningDraggableItem) => {
                            const { id, parentId, location } = item;

                            mutate({
                              id,
                              fromBacklog:
                                parentId === CHALLENGE_LOCATIONS.BACKLOG,
                              fromSprintId:
                                parentId === CHALLENGE_LOCATIONS.BACKLOG
                                  ? undefined
                                  : parentId,
                              toBacklog: false,
                              toSprintId: sprintId,
                              underChallengeId: getNextLeftChallengeId({
                                challenges,
                                id,
                                newIndex: challengeIndex,
                                isNew: false,
                              }),
                              prevLocation: location,
                              newLocation: CHALLENGE_LOCATIONS.ACTIVE_SPRINT,
                            });

                            item.parentId = sprintId;
                            item.location = CHALLENGE_LOCATIONS.ACTIVE_SPRINT;
                          }}
                          onHover={(
                            draggedItem: PlanningDraggableItem,
                            hoveredItem: PlanningDraggableItem
                          ) => {
                            draggedItem.index = getDraggedItemIndex<
                              PlanningDraggableItem,
                              PlanningDraggableItem
                            >(
                              draggedItem,
                              hoveredItem,
                              challenges.findIndex(
                                ({ challengeId }) =>
                                  challengeId === draggedItem.id
                              )
                            );
                          }}
                          parentId={sprintId}
                          location={CHALLENGE_LOCATIONS.ACTIVE_SPRINT}
                        >
                          <ChallengeCard
                            title={challenge.title}
                            picture={challenge.picture}
                            emoji={challenge.emoji}
                            dimensions={challenge.dimensions}
                            target={challenge.target}
                            goals={challenge.goals}
                            tags={challenge.tags}
                            sprintWeeksCount={sprintWeeksCount}
                          />
                        </PlanningBoardColumnItem>
                      );
                    })}
                  </When>
                  <When condition={coachingTools.length > 0}>
                    <PlanningBoardColumnItemsCount
                      name="coaching tool"
                      count={coachingTools.length}
                    />
                    {coachingTools.map(({ name, description, picture, id }) => (
                      <CoachingToolsDraggable
                        key={id}
                        item={{
                          id,
                          type: DRAGGABLE_ITEM_TYPES.COACHING_TOOL,
                          fromSprintId: sprintId,
                          fromLocation: CHALLENGE_LOCATIONS.ACTIVE_SPRINT,
                        }}
                      >
                        <CoachingToolCard
                          name={name}
                          description={description}
                          url={picture?.url}
                          id={id}
                        >
                          <CoachingToolCardMenu toolId={id}>
                            <CoachingToolCardMenuCopyToSprint toolId={id} />
                            <Button
                              _focus={{ outline: 'none' }}
                              width="100%"
                              onClick={() =>
                                removeCoachingToolFromSprint({
                                  toolId: id,
                                  sprintId,
                                })
                              }
                              justifyContent="flex-start"
                            >
                              <Typography color="black">Remove</Typography>
                            </Button>
                          </CoachingToolCardMenu>
                        </CoachingToolCard>
                      </CoachingToolsDraggable>
                    ))}
                  </When>
                </BoardColumn>
              </BoardColumnWrapper>
            </CoachingToolDroppable>
          </Box>
        </PlanningBoardColumn>
      </Droppable>
    );
  }
);
