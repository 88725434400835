import { ButtonIcon, VARIANTS } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useSessionDeletion } from 'features/sessions/hooks';

type SessionsListDeleteButtonProps = {
  sessionId: string;
};

export const SessionsListDeleteButton = ({
  sessionId,
}: SessionsListDeleteButtonProps) => {
  const { openConfirmationModal } = useSessionDeletion(sessionId);

  return (
    <Flex justifyContent="flex-end" dataTestid="session-list-delete-button">
      <ButtonIcon
        ariaLabel="remove session"
        icon={<Icon name={iconNames.bin} />}
        onClick={(e) => {
          e.stopPropagation();
          openConfirmationModal();
        }}
        dataTestid={`sessions-list-delete-btn-${sessionId}`}
        variant={VARIANTS.TERTIARY}
      />
    </Flex>
  );
};
