import { ReactNode, useMemo } from 'react';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Sortable } from 'shared/components/DragNDrop/Sortable';

type TBoardColumnItem = {
  type: string;
  id: string;
  columnIndex: number;
  index: number;
  children: ReactNode;
  columnId: string;
  onHover: (
    draggedItem: {
      id: string;
      index: number;
      columnIndex: number;
      isColumnChanged: boolean;
    },
    hoveredItem: {
      id: string;
      index: number;
      columnIndex: number;
      isColumnChanged: boolean;
    }
  ) => void;
  onDrop: (item: { id: string; index: number; columnId: string }) => void;
};

export const BoardColumnItem = ({
  type,
  id,
  columnIndex,
  index,
  children,
  columnId,
  onHover,
  onDrop,
}: TBoardColumnItem) => {
  const item = useMemo(
    () => ({
      type,
      id,
      index,
      columnIndex,
      isColumnChanged: false,
    }),
    [id, columnIndex, index, type]
  );

  return (
    <Spacer size={'sm md'}>
      <Sortable
        key={`${columnIndex}-${id}`}
        item={item}
        onHover={onHover}
        onDrop={({ id, index }) => {
          onDrop({
            id,
            index,
            columnId,
          });
        }}
      >
        {children}
      </Sortable>
    </Spacer>
  );
};
