import {
  IMPROVED_GROUP_VIEW_FEATURE_FLAG,
  RenderIfFFEnabled,
} from 'featureFlags';
import { OptionsMenu } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  GoalMenuEditButton,
  GoalMenuDeleteButton,
  useEditGoal,
} from 'features/goal';
import { useGoalCardContext } from 'features/goal/context/cardContext';
import { usePrioritizeToggleOnBoard } from 'features/goal/hooks/usePrioritizeToggleOnBoard';

import { GoalCardMenuPrioritizeButton } from './Menu/PrioritizeButton';

export const GoalCardMenu = () => {
  const { goal } = useGoalCardContext();
  const { goalId, prioritizedAt, columnId } = goal;

  const { onEditGoalClick } = useEditGoal({ goal });

  const togglePrioritizeOnBoard = usePrioritizeToggleOnBoard();

  return (
    //@ts-ignore
    <OptionsMenu icon={iconNames.ellipsisHorizontal}>
      <GoalMenuEditButton onClick={onEditGoalClick} />
      <RenderIfFFEnabled featureFlag={IMPROVED_GROUP_VIEW_FEATURE_FLAG}>
        <GoalCardMenuPrioritizeButton
          goalId={goalId}
          prioritizedAt={prioritizedAt}
          columnId={columnId}
          togglePrioritize={togglePrioritizeOnBoard}
        />
      </RenderIfFFEnabled>
      <GoalMenuDeleteButton goalId={goalId} columnId={columnId} />
    </OptionsMenu>
  );
};
