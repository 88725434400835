import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useSessionDeletion } from 'features/sessions/hooks';

import { MenuItem } from 'shared/components/Menu';

type SessionsTableMoreOptionsDeleteMenuItemProps = {
  sessionId: string;
};

export const SessionsTableMoreOptionsDeleteMenuItem = ({
  sessionId,
}: SessionsTableMoreOptionsDeleteMenuItemProps) => {
  const queryClient = useQueryClient();
  const { openConfirmationModal } = useSessionDeletion(sessionId, () => {
    queryClient.invalidateQueries([queryKeys.participants]);
  });

  return (
    <MenuItem
      onClick={(evt) => {
        evt.stopPropagation();
        openConfirmationModal();
      }}
      icon={
        <Icon
          name={iconNames.binFilled}
          width={16}
          height={18}
          fill="var(--fgPrimary)"
        />
      }
    >
      Delete
    </MenuItem>
  );
};
