import { Box, Flex, Typography } from 'shared';

type TJournalNotesSprintItem = {
  sprintTitle: string;
  sprintDates: string;
};

export const JournalNotesSprintItem = ({
  sprintTitle,
  sprintDates,
}: TJournalNotesSprintItem) => (
  <Box py="1rem">
    <Flex flexDirection="column" alignItems="center" gap="4px">
      <Typography
        color="black"
        fontWeight="semiBold"
        dataTestid="journal-sidebar-item-sprint-title"
      >
        {sprintTitle}
      </Typography>
      <Typography
        color="gray"
        type="small"
        dataTestid="journal-sidebar-item-sprint-period"
      >
        {sprintDates}
      </Typography>
    </Flex>
  </Box>
);
