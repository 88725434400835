import { useQuery } from '@tanstack/react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getJournalQueryKey } from 'api/utils';

import { BaseChallenge } from 'features/challenge/BaseChallenge';
import { IBaseChallenge } from 'features/challenge/config/types';
import { FinalSprint } from 'features/sprint';
import { useIsCoachMode } from 'features/user/hooks/useIsCoachMode';

type TUseJournalMetadataQuery = {
  participantId: string;
};
export const useJournalMetadataQuery = ({
  participantId,
}: TUseJournalMetadataQuery) => {
  const { request } = useQueryHTTPRequest();

  const isCoachMode = useIsCoachMode();

  const queryKey = getJournalQueryKey({ participantId, isCoachMode });

  return useQuery<{
    unreadReplyReferences: { noteId: string; replyId: string }[];
    sprints: FinalSprint[];
    challenges: IBaseChallenge[];
  }>(
    [queryKey(), 'metadata'],
    async () => {
      const coachPrefix = isCoachMode ? `/coach/${participantId}` : '';

      return await request({
        url: `/api${coachPrefix}/journal/metadata`,
      });
    },
    {
      select: (data) => ({
        unreadReplyReferences: data?.unreadReplyReferences ?? [],
        sprints: data?.sprints.map((sprint) => new FinalSprint(sprint)) ?? [],
        challenges:
          data?.challenges.map((challenge) => new BaseChallenge(challenge)) ??
          [],
      }),
    }
  );
};
