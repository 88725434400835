import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { SessionListItemType } from 'features/sessions/config/types';

export const useSessionDeleteMutation = (sessionId: string) => {
  const { email: userId } = useParams();
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    () =>
      request({
        url: `/api/coach/coaching-sessions/${sessionId}/delete`,
      }),
    {
      onMutate: () => {
        const prevData = queryClient.getQueryData<{
          coachingSessions: SessionListItemType[];
        }>([queryKeys.coachSessions, userId]);

        queryClient.setQueryData<{ coachingSessions: SessionListItemType[] }>(
          [queryKeys.coachSessions, userId],
          (old) => ({
            coachingSessions: (old?.coachingSessions || []).filter(
              (session) => session.coachingSessionId !== sessionId
            ),
          })
        );

        return { coachingSessions: prevData?.coachingSessions || [] };
      },
      onSuccess: () => {
        // This will remove sessions from cache, rn we don't have other option
        // as we don't know in which session other session the session we are deleting is referenced
        queryClient.removeQueries([queryKeys.coachSession]);
      },
      onError: (err, _, prevCoachingSessions) => {
        queryClient.setQueryData(
          [queryKeys.coachSessions, userId],
          prevCoachingSessions
        );
      },
    }
  );
};
