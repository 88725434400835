import { dateUtils } from 'shared_DEPRECATED/utils/date';

import { Typography } from 'shared/components/Typography';

type TJournalNotesDateItem = {
  date: string;
};

export const JournalNotesDateItem = ({ date }: TJournalNotesDateItem) => (
  <Typography
    textAlign="center"
    color="gray"
    type="small"
    dataTestid="journal-sidebar-item-created-date"
  >
    {dateUtils(date).format('MMM D')}
  </Typography>
);
