import { useParams } from 'react-router-dom';

import { COACHING_MODE } from 'features/participants/config';
import { useParticipantQuery } from 'features/participants/hooks';

export const useParticipantCoachingMode = () => {
  const { email } = useParams();
  const { participant } = useParticipantQuery(email);

  return participant?.coachingMode || COACHING_MODE.WEEKLY_60_MIN;
};
