import {
  NEW_SESSION_CREATION_FLOW_FEATURE_FLAG,
  RenderIfFFEnabled,
} from 'featureFlags';
import { Nullable } from 'shared_DEPRECATED/types';

import { SessionDateCellContent } from 'features/participants/components/Table/SessionDateCellContent';
import { SessionDateCellContent__OLD } from 'features/participants/components/Table/SessionDateCellContent__OLD';
import { ParticipantsTableRowDataType } from 'features/participants/config/types';
import { SessionAttendanceStatusType } from 'features/sessions/config/types';
import { IDraftSprint, ISprint } from 'features/sprint/config/types';

type SessionDateCellProps = {
  value: Nullable<{
    appointmentId: string;
    startAt: string;
    attendance: SessionAttendanceStatusType;
    sessionId: string;
    latestSprint: ISprint | IDraftSprint;
  }>;
  row: ParticipantsTableRowDataType;
};

export const SessionDateCell = ({
  value: sessionAppointment,
  row,
}: SessionDateCellProps) => {
  const { email, userId } = row.original.userInfo;

  return (
    <RenderIfFFEnabled
      featureFlag={NEW_SESSION_CREATION_FLOW_FEATURE_FLAG}
      fallback={
        <>
          {/*eslint-disable-next-line react/jsx-pascal-case */}
          <SessionDateCellContent__OLD
            email={email}
            appointmentId={sessionAppointment?.appointmentId}
            startAt={sessionAppointment?.startAt}
          />
        </>
      }
    >
      <SessionDateCellContent
        userEmail={email}
        userId={userId}
        attendance={sessionAppointment?.attendance!}
        sessionId={sessionAppointment?.appointmentId!}
        startAt={sessionAppointment?.startAt!}
        // @ts-ignore
        latestSprint={row.original.latestSprint}
        coachingMode={row.original.coachingMode}
      />
    </RenderIfFFEnabled>
  );
};
