import { Flex } from 'shared_DEPRECATED/components/Flex';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { ParticipantsTableJournalNotes } from 'features/participants/components/Table/Actions/JournalNotes';
import { ParticipantsTableRowDataType } from 'features/participants/config/types';

type JournalsCellProps = {
  row: ParticipantsTableRowDataType;
};

export const JournalsCell = ({ row }: JournalsCellProps) => {
  const { userInfo, latestSprint, journal } = row.original;

  const unreadItemDateTime = journal.firstUnreadJournalRecordDatetime
    ? dateUtils.calculateRelativeTimeAgo(
        journal.firstUnreadJournalRecordDatetime
      )
    : null;

  return (
    <Flex gap="1rem" justifyContent="flex-start">
      <ParticipantsTableJournalNotes
        userInfo={userInfo}
        latestSprint={latestSprint}
        unreadJournalRecordsNumber={journal.unreadJournalRecordsNumber}
        unreadItemDateTime={unreadItemDateTime}
      />
    </Flex>
  );
};
