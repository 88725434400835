import { IconButton } from '@chakra-ui/react';
import { Else, If, Then } from 'react-if';

import {
  Menu,
  MenuButton,
  MenuList,
  Typography,
  VARIANTS,
  MenuItem,
  useSidebarContext,
} from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  useJournalDeleteNoteMutation,
  useJournalMarkNoteAsUnreadMutation,
  useJournalMarkNoteAsReadMutation,
} from 'features/journal/hooks';
import { openJournalSidebar } from 'features/journal/utils';

type TJournalCardMenu = {
  isAuthor: boolean;
  onEdit: () => void;
  noteId: string;
  isNoteRead: boolean;
};

export const JournalCardMenu = ({
  isAuthor,
  onEdit,
  noteId,
  isNoteRead,
}: TJournalCardMenu) => {
  const { participantEmail, participantId } = useSidebarContext();

  const { mutateAsync: markNotesAsUnread } = useJournalMarkNoteAsUnreadMutation(
    { noteId }
  );
  const { mutateAsync: markNotesAsRead } = useJournalMarkNoteAsReadMutation({
    noteId,
  });

  const { mutateAsync: deleteNote } = useJournalDeleteNoteMutation({
    noteId,
  });

  const { location, user, filters } = useSidebarContext();

  const handleNoteReadStatus = async () => {
    const toggleNoteReadStatus = isNoteRead
      ? markNotesAsUnread
      : markNotesAsRead;
    await toggleNoteReadStatus();
  };

  const handleDeleteNote = async () => {
    await deleteNote();

    location === 'replies' &&
      openJournalSidebar({
        user,
        participantEmail,
        filters,
        participantId,
      });
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon name={iconNames.ellipsisHorizontal} />}
        variant={VARIANTS.TERTIARY}
        size="sm"
        data-testid="journal-sidebar-card-menu-options"
      />
      <MenuList fontSize="small">
        <If condition={isAuthor}>
          <Then>
            {() => (
              <>
                <MenuItem onClick={onEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDeleteNote}>
                  <Typography color="red">Delete</Typography>
                </MenuItem>
              </>
            )}
          </Then>
          <Else>
            <MenuItem
              onClick={handleNoteReadStatus}
              data-testid="journal-sidebar-card-status"
            >
              <Typography>Mark as {isNoteRead ? 'unread' : 'read'}</Typography>
            </MenuItem>
          </Else>
        </If>
      </MenuList>
    </Menu>
  );
};
