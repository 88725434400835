import Box from 'shared_DEPRECATED/components/Box';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type ParticipantsTableCounterProps = {
  value: string;
  dataTestid: string;
};

export const ParticipantsTableCounter = ({
  value,
  dataTestid,
}: ParticipantsTableCounterProps) => (
  <Box
    style={{ position: 'absolute', top: '-15px', left: '13px' }}
    data-testid={dataTestid}
  >
    <Badge bgColor="blue" size="sm" h="20px" minW="20px" py="2px">
      <Typography as="span" color="whiteTotal" type="small">
        {value}
      </Typography>
    </Badge>
  </Box>
);
