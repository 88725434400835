import { Typography } from 'shared';

type TJournalNotesWithoutChallengesItem = {
  title: string;
};

export const JournalNotesWithoutChallengesItem = ({
  title,
}: TJournalNotesWithoutChallengesItem) => (
  <Typography color="gray" type="small">
    {title}
  </Typography>
);
