import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Typography } from 'shared';
import { DatePickerTime } from 'shared_DEPRECATED/components/Form/DatePicker';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  COACHING_MODE_SESSION_DURATION,
  DEFAULT_SESSION_DURATION,
} from 'features/sessions/config';
import {
  getDateStringWithUpdatedTime,
  getSessionEndTime,
} from 'features/sessions/utils';

import { useModalContext } from 'shared/components/Modal';

export const CoachSessionsScheduleEndTimeSelector = () => {
  const { setValue } = useFormContext();
  const { coachingMode } = useModalContext();
  const [sessionStartDate, sessionEndDate, coachingSessionDate] = useWatch({
    name: ['startAt', 'endAt', 'coachingSessionDate'],
  });

  const filterSessionEndDateTime = (endDate: Date) =>
    dateUtils(endDate).isAfter(sessionStartDate);

  const onTimeChange = (date: Date) => {
    const updatedEndDate = getDateStringWithUpdatedTime(sessionEndDate, date);

    setValue('endAt', updatedEndDate, {
      shouldDirty: true,
    });

    const sessionStartDate = dateUtils(updatedEndDate)
      .subtract(
        COACHING_MODE_SESSION_DURATION[coachingMode] ||
          DEFAULT_SESSION_DURATION,
        'minute'
      )
      .toISOString();

    setValue('startAt', sessionStartDate);
  };

  useEffect(() => {
    if (dateUtils(sessionEndDate).isSameOrBefore(sessionStartDate)) {
      const sessionEndDate = getSessionEndTime(sessionStartDate, coachingMode);
      setValue('endAt', sessionEndDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionEndDate]);

  useEffect(() => {
    const updatedEndDate = getDateStringWithUpdatedTime(
      coachingSessionDate,
      sessionEndDate
    );
    setValue('endAt', updatedEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachingSessionDate, sessionEndDate]);

  return (
    <Typography as="div" type="meta" dataTestid="session-end-time">
      <DatePickerTime
        date={dateUtils(sessionEndDate).toDate()}
        onChange={onTimeChange}
        filterTime={filterSessionEndDateTime}
      />
    </Typography>
  );
};
