export const ENVS = {
  DEV: 'dev',
  LOCAL: 'local',
  PROD: 'prod',
  STAGING: 'staging',
  TEST: 'test',
};

const AUTH_CLIENT_IDS = {
  [ENVS.DEV]: '5y9DsxNXr48mqhbNeYXIzbNa9v3lSbRU',
  [ENVS.PROD]: 'M3Bo0j96rYTUwZsnrT79eOnqJUvyVG0s',
  [ENVS.STAGING]: 'QUznw9d0WZL7kvqUko03qznlPs7ol7ua',
};

const envConfigs = {
  [ENVS.DEV]: () => ({
    AUTH_AUDIENCE: 'dev.api.challenge.hosinc.co',
    AUTH_DOMAIN: 'hos-dev.us.auth0.com',
    AUTH_CLIENT_ID: AUTH_CLIENT_IDS[ENVS.DEV],
    SOCKET_URL: 'wss://dev.ws.challenge.hosinc.co',
    SESSION_TOOL_EXTENSION_ID: 'cpjhbkhfadjgapineikoigbnldikamop',
    HOS_INC_STORE_EXTENSION_ID: 'djgcjlpafijnhgopkchpekeojbiamahb',
  }),
  [ENVS.LOCAL]: (apiEnv) => ({
    AUTH_AUDIENCE: `${
      apiEnv !== ENVS.PROD ? `${apiEnv}.` : ''
    }api.challenge.hosinc.co`,
    AUTH_DOMAIN: `hos-${apiEnv}.us.auth0.com`,
    AUTH_CLIENT_ID: AUTH_CLIENT_IDS[apiEnv],
    SOCKET_URL: `wss://${
      apiEnv !== ENVS.PROD ? `${apiEnv}.` : ''
    }ws.challenge.hosinc.co`,
    SESSION_TOOL_EXTENSION_ID: 'cpjhbkhfadjgapineikoigbnldikamop',
    HOS_INC_STORE_EXTENSION_ID: 'djgcjlpafijnhgopkchpekeojbiamahb',
    APOLLO_HOST: 'https://apollo.staging.hosinc.co',
  }),
  [ENVS.PROD]: () => ({
    AUTH_AUDIENCE: 'api.challenge.hosinc.co',
    AUTH_DOMAIN: 'auth.hosinc.co',
    AUTH_CLIENT_ID: AUTH_CLIENT_IDS[ENVS.PROD],
    SOCKET_URL: 'wss://ws.challenge.hosinc.co',
    HOTJAR_TRACKING_ID: '3724444',
    AMPLITUDE_API_KEY: '66adbeb46a71205b88905c1e1a07b4cb',
    SESSION_TOOL_EXTENSION_ID: 'cpjhbkhfadjgapineikoigbnldikamop',
    HOS_INC_STORE_EXTENSION_ID: 'djgcjlpafijnhgopkchpekeojbiamahb',
    APOLLO_HOST: 'https://apollo.hosinc.co',
  }),
  [ENVS.STAGING]: () => ({
    AUTH_AUDIENCE: 'staging.api.challenge.hosinc.co',
    AUTH_DOMAIN: 'hos-staging.us.auth0.com',
    AUTH_CLIENT_ID: AUTH_CLIENT_IDS[ENVS.STAGING],
    SOCKET_URL: 'wss://staging.ws.challenge.hosinc.co',
    SESSION_TOOL_EXTENSION_ID: 'cpjhbkhfadjgapineikoigbnldikamop',
    HOS_INC_STORE_EXTENSION_ID: 'djgcjlpafijnhgopkchpekeojbiamahb',
    APOLLO_HOST: 'https://apollo.staging.hosinc.co',
  }),
  [ENVS.TEST]: () => ({
    SOCKET_URL: 'ws://localhost:8080',
  }),
};

// TODO: Use env variables
export const CONSTANTS = (function (env = ENVS.LOCAL, apiEnv = ENVS.STAGING) {
  let conf = {
    AUTH_REDIRECT_URI: window.location.origin,
    AUTH_SCOPE: 'read:current_user update:current_user_metadata',
  };

  return { ...conf, ...envConfigs[env](apiEnv), ENV: env };
})(
  process.env.REACT_APP_FRONTEND_ENVIRONMENT,
  process.env.REACT_APP_API_ENVIRONMENT
);
