import { When } from 'react-if';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { useGoalsTableQuery } from 'features/goal';

import { RoadmapDataProvider } from '../context/roadmapData';
import { GoalBoardColumns } from './Board/Columns';

export const GoalsBoard = () => {
  const { data, isLoading } = useGoalsTableQuery();

  if (isLoading) {
    return <Loader size="sm" />;
  }

  return (
    <When condition={Boolean(data)}>
      <RoadmapDataProvider>
        <Flex
          width="100%"
          height="calc(100% - var(--logo-header-height)"
          alignItems="stretch"
          gap="4px"
        >
          <Spacer size="md" />
          <GoalBoardColumns />
          <Spacer size="md" />
        </Flex>
      </RoadmapDataProvider>
    </When>
  );
};
