import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { Nullable } from 'shared_DEPRECATED/types';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import { TChallengeLocation } from 'features/challenge/config/types';
import {
  ChallengeType,
  IPlanningSprint,
  PlanningDraftSprint,
  usePlanningAnalyticsEvents,
  usePlanningBacklogQueryActions,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning';
import { useSprintsQueryActions } from 'features/sprint/hooks/query/sprints/useSprintsActions';

import { updateToast } from 'shared/components/Toast';
import { deleteItemMutative, insertItemMutative } from 'shared/utils/array';

type MovePlanningChallengeOptionsType = {
  id: string;
  fromBacklog: boolean;
  fromSprintId?: string;
  toBacklog?: boolean;
  toSprintId?: string;
  underChallengeId?: Nullable<string>;
  prevLocation: TChallengeLocation;
  newLocation: TChallengeLocation;
};

const CUSTOM_TOAST_ID = 'copy-challenge-to-new-sprint';

export const useMovePlanningChallengeMutation__NEW = () => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();
  const {
    sendPlanningChallengeMovedEvent,
    sendPlanningChallengeOrderChangedEvent,
  } = usePlanningAnalyticsEvents();

  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    invalidateDraftSprintsQuery,
    updateDraftSprints,
  } = usePlanningDraftSprintsQueryActions();
  const { cancelBacklogQueries, getBacklog, updateBacklog } =
    usePlanningBacklogQueryActions();
  const {
    cancelSprintsQueries,
    getSprints,
    updateSprints,
    invalidateSprintsQuery,
  } = useSprintsQueryActions();

  return useMutation(
    // @ts-ignore
    async ({
      id,
      fromBacklog,
      fromSprintId,
      toBacklog,
      toSprintId,
      underChallengeId,
    }: MovePlanningChallengeOptionsType) =>
      await request({
        url: `/api/web/sprint-planning/challenges/${id}/move`,
        body: {
          fromBacklog,
          fromSprintId,
          toBacklog,
          toSprintId,
          underChallengeId,
        },
      }),
    {
      onMutate: async ({
        id,
        fromSprintId,
        toSprintId,
        prevLocation,
        newLocation,
        underChallengeId,
      }) => {
        await cancelDraftSprintsQueries();
        await cancelBacklogQueries();
        await cancelSprintsQueries();

        const previousBacklogValue = getBacklog();
        const previousDraftSprintsValue = getDraftSprints();
        const previousSprintsValue = getSprints();
        let fromSprintIndex: number;
        let fromSprint: PlanningDraftSprint | IPlanningSprint | undefined;
        let movedChallenge: ChallengeType | undefined;
        let movedChallengeIndex: number;

        switch (prevLocation) {
          case CHALLENGE_LOCATIONS.BACKLOG:
            movedChallenge = previousBacklogValue!.items.find(
              (challenge) => challenge.challengeId === id
            );

            updateBacklog(
              previousBacklogValue!.items.filter(
                (item) => item.challengeId !== id
              )
            );

            break;
          case CHALLENGE_LOCATIONS.DRAFT_SPRINT:
            fromSprintIndex = previousDraftSprintsValue!.items.findIndex(
              (sprint) => sprint.sprintId === fromSprintId
            );
            fromSprint = previousDraftSprintsValue!.items[fromSprintIndex];
            movedChallengeIndex = fromSprint?.challenges.findIndex(
              (challenge) => challenge.challengeId === id
            ) as number;
            movedChallenge = fromSprint?.challenges[movedChallengeIndex];

            updateDraftSprints(
              produce(previousDraftSprintsValue!.items, (draft) => {
                deleteItemMutative(
                  draft[fromSprintIndex].challenges,
                  movedChallengeIndex!
                );
              })
            );

            break;
          case CHALLENGE_LOCATIONS.ACTIVE_SPRINT:
          case CHALLENGE_LOCATIONS.FINISHED_SPRINT:
            fromSprintIndex = previousSprintsValue!.items.findIndex(
              (sprint) => sprint.sprintId === fromSprintId
            );
            fromSprint = previousSprintsValue!.items[fromSprintIndex];
            movedChallengeIndex = fromSprint?.challenges.findIndex(
              (challenge) => challenge.challengeId === id
            ) as number;
            movedChallenge = fromSprint?.challenges[movedChallengeIndex];

            if (newLocation === prevLocation) {
              updateSprints(
                produce(previousSprintsValue!.items, (draft) => {
                  deleteItemMutative(
                    draft[fromSprintIndex].challenges,
                    movedChallengeIndex
                  );
                })
              );
            } else {
              toast.loading('Copying challenge to new sprint...', {
                ...toastConfig,
                toastId: CUSTOM_TOAST_ID,
              });
              return;
            }
        }
        const updatedBacklogValue = getBacklog();
        const updatedDraftSprintsValue = getDraftSprints();
        const updatedSprintsValue = getSprints();

        switch (newLocation) {
          case CHALLENGE_LOCATIONS.BACKLOG:
            updateBacklog(
              produce(updatedBacklogValue!.items, (draft) => {
                const underChallengeIndex = draft.findIndex(
                  (item) => item.challengeId === underChallengeId
                );

                insertItemMutative(
                  draft,
                  underChallengeIndex + 1,
                  movedChallenge
                );
              })
            );

            break;
          case CHALLENGE_LOCATIONS.DRAFT_SPRINT:
            updateDraftSprints(
              produce(updatedDraftSprintsValue!.items, (draft) => {
                const toSprint = draft.find(
                  (item) => item.sprintId === toSprintId
                );
                const underChallengeIndex = toSprint!.challenges.findIndex(
                  (item) => item.challengeId === underChallengeId
                );

                insertItemMutative(
                  toSprint!.challenges,
                  underChallengeIndex + 1,
                  movedChallenge
                );
              })
            );
            break;
          case CHALLENGE_LOCATIONS.ACTIVE_SPRINT:
            updateSprints(
              produce(updatedSprintsValue!.items, (draft) => {
                const toSprint = draft.find(
                  (item) => item.sprintId === toSprintId
                );
                const underChallengeIndex = toSprint!.challenges.findIndex(
                  (item) => item.challengeId === underChallengeId
                );

                insertItemMutative(
                  toSprint!.challenges,
                  underChallengeIndex + 1,
                  movedChallenge
                );
              })
            );
        }

        return {
          previousBacklogValue,
          previousDraftSprintsValue,
          previousSprintsValue,
        };
      },
      onError: (err: Error, _: any, context) => {
        if (context) {
          if (context.previousBacklogValue) {
            updateBacklog(context.previousBacklogValue.items);
          }
          if (context.previousDraftSprintsValue) {
            updateDraftSprints(context.previousDraftSprintsValue.items);
          }
          if (context.previousSprintsValue) {
            updateSprints(context.previousSprintsValue.items);
          }
        }

        logError(err);
        updateToast(CUSTOM_TOAST_ID, {
          render: 'Failed to move challenge',
          type: 'error',
          isLoading: false,
          icon: false,
        });
      },
      onSuccess: (_, { fromBacklog, fromSprintId, toBacklog, toSprintId }) => {
        (fromBacklog || toBacklog) &&
          queryClient.invalidateQueries([queryKeys.challenges]);
        if (fromSprintId || toSprintId) {
          invalidateDraftSprintsQuery();
          invalidateSprintsQuery();
        }
        sendPlanningChallengeMovedEvent();

        if (fromSprintId === toSprintId || (fromBacklog && !toSprintId)) {
          sendPlanningChallengeOrderChangedEvent();
        }

        toast.dismiss(CUSTOM_TOAST_ID);
      },
    }
  );
};
