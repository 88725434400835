import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  GOAL_DEFAULT_VALUE,
  IGoal,
  PERIODS_FOR_ACHIEVEMENT,
  useAddGoalMutation,
  useGoalsNavigationLink,
  useGoalsUpdateQuery,
  useOpenAddGoalModal,
} from 'features/goal';
import { useUploadGoalCover } from 'features/goal/hooks';
import { useUserId } from 'features/user/hooks/useUserId';

import { showLoadingToast, updateToast } from 'shared/components/Toast';
import { removeBase64Metadata } from 'shared/utils/base64';

export const useAddGoal = () => {
  const navigate = useNavigate();
  const addGoalMutation = useAddGoalMutation();
  const userId = useUserId();

  const url = useGoalsNavigationLink();

  const { invalidateGoalsQuery } = useGoalsUpdateQuery({
    userId,
    modifiedColumnId: '',
  });

  const uploadGoalCoverMutation = useUploadGoalCover({
    options: {
      onSuccess: () => {
        invalidateGoalsQuery();
      },
    },
  });

  const onSubmit = useCallback(
    async (goal: IGoal & { imageToUpload?: string }) => {
      const toastId = showLoadingToast('Saving goal...');

      try {
        const createdGoal = await addGoalMutation.mutateAsync(goal);

        if (goal.imageToUpload) {
          await uploadGoalCoverMutation.mutateAsync({
            goal: createdGoal,
            file: removeBase64Metadata(goal.imageToUpload),
          });
        }

        updateToast(toastId, {
          render: 'Goal created!',
          type: 'success',
          isLoading: false,
          icon: false,
        });

        navigate(url, { replace: true });
      } catch (err) {
        updateToast(toastId, {
          render:
            "There's an error with creating the goal. Please try again later.",
          type: 'error',
          isLoading: false,
          icon: false,
        });
      }
    },
    [navigate, url, uploadGoalCoverMutation, addGoalMutation]
  );

  const openGoalAddModal = useOpenAddGoalModal();

  const onAddGoalClick = useCallback(
    (columnId: string = PERIODS_FOR_ACHIEVEMENT.BACKLOG) => {
      openGoalAddModal({
        onSubmit,
        goal: {
          ...GOAL_DEFAULT_VALUE,
          columnId,
        },
      });
    },
    [openGoalAddModal, onSubmit]
  );

  return {
    onAddGoalClick,
  };
};
