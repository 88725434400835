import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { IChallengeTimelineItem } from 'features/challenge/ChallengeTimelineItem';
import { IChallenge } from 'features/challenge/config/types';

type TUseEditSprintChallengesMutation = {
  sprintId: string;
  options?: any;
};

export const useEditSprintChallengesMutation = ({
  sprintId,
  options,
}: TUseEditSprintChallengesMutation) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    (challenge: IChallenge | IChallengeTimelineItem) =>
      request({
        url: `/api/web/sprints/${sprintId}/challenges/edit`,
        body: { challenges: [challenge] },
      }),
    {
      ...options,
    }
  );
};
