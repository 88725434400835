import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';

import { SessionType } from 'features/sessions/config/types';

export const useSessionsQueryActions = () => {
  const queryClient = useQueryClient();
  const { email: clientId } = useParams();

  const getSessionList = () => {
    const previousData = queryClient.getQueryData<{
      coachingSessions: SessionType[];
    }>([queryKeys.coachSessions, clientId]);

    return previousData?.coachingSessions || [];
  };

  const updateSession = (
    sessionId: string,
    updatedSession: Partial<SessionType>
  ) => {
    const oldSession = queryClient.getQueryData<SessionType>([
      queryKeys.coachSession,
      sessionId,
    ]);

    if (!oldSession) {
      return;
    }

    queryClient.setQueryData<SessionType>(
      [queryKeys.coachSession, sessionId],
      () => ({ ...oldSession, ...updatedSession })
    );
  };

  const updateSessions = (clientId: string, updatedSessions: SessionType[]) => {
    const previousData = queryClient.getQueryData<{
      coachingSessions: SessionType[];
    }>([queryKeys.coachSessions, clientId]);

    queryClient.setQueryData([queryKeys.coachSessions, clientId], {
      ...previousData,
      coachingSessions: updatedSessions,
    });
  };

  const getSessionById = (sessionId: string) => {
    return queryClient.getQueryData<SessionType>([
      queryKeys.coachSession,
      sessionId,
    ]);
  };

  return {
    getSessionList,
    updateSession,
    getSessionById,
    updateSessions,
  };
};
