import { ButtonIcon } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useAddGoal } from 'features/goal/hooks';

export const BoardColumnAddGoalButton = ({
  columnId,
}: {
  columnId: string;
}) => {
  const { onAddGoalClick } = useAddGoal();

  return (
    <ButtonIcon
      ariaLabel="add goal"
      size="sm"
      icon={<Icon name={iconNames.plus} width={12} height={12} />}
      onClick={() => onAddGoalClick(columnId)}
    />
  );
};
