import { Flex } from 'shared';

import {
  JournalCardReplies,
  JournalNote,
  JournalNoteCardReactions,
  JournalCardUnreadIndicator,
  JournalCard,
} from 'features/journal';

type TJournalNotesItem = {
  note: JournalNote;
  isAuthor: boolean;
  setEditedNote: (note: JournalNote) => void;
};

export const JournalNotesItem = ({
  note,
  isAuthor,
  setEditedNote,
}: TJournalNotesItem) => (
  <Flex
    flexDirection="column"
    width="100%"
    gap="1rem"
    position="relative"
    data-testid="journal-sidebar-item-card"
  >
    {!note.isRead && (
      <JournalCardUnreadIndicator
        options={{
          left: '-0.75rem',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
    )}
    <JournalCard note={note} setEditedNote={setEditedNote} isAuthor={isAuthor}>
      <JournalCardReplies
        repliesCount={note.repliesCount}
        noteId={note.noteId}
        hasUnreadReplies={note.hasUnreadReplies}
        isNoteRead={note.isRead}
      />
      <JournalNoteCardReactions
        noteId={note.noteId}
        reactions={note.reactions}
        isNoteRead={note.isRead}
      />
    </JournalCard>
  </Flex>
);
