import { Suspense, lazy } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { modal } from 'shared/components/Modal';

const ExploreDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/challenge/components/ExploreDialog/DialogContent'),
    'ExploreChallengesDialog'
  )
);

export const useExploreChallengesDialog =
  // You should pass sprintFormMethods if modal is used on Sprint Form page. Otherwise pass onSubmit


    ({ sprint, sprintFormMethods, onSubmit }) =>
    () =>
      modal.open({
        content: (
          <Suspense
            fallback={<Loader dataTestid="explore-dialog-loader" size="sm" />}
          >
            <ExploreDialogContent />
          </Suspense>
        ),
        context: { sprintFormMethods, onSubmit, sprint },
        props: {
          size: 'md',
          title: 'Explore Challenges',
        },
      });
