import {
  Tooltip as Component,
  TooltipProps as ComponentProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { PLACEMENTS } from 'shared/components/Tooltip';

export type TooltipProps = Omit<ComponentProps, 'bg'> & {
  anchor: ReactNode;
  offsetValue?: [number, number];
  color?: string;
  backgroundColor?: string;
  dataTestid?: string;
};

export const Tooltip = ({
  children,
  color,
  backgroundColor,
  anchor,
  placement = PLACEMENTS.BOTTOM,
  hasArrow = true,
  offsetValue,
  width = 'auto',
  dataTestid,
  ...props
}: TooltipProps) => (
  <Component
    color={color}
    bg={backgroundColor}
    label={children}
    hasArrow={hasArrow}
    placement={placement}
    offset={offsetValue}
    borderRadius="var(--border-radius2)"
    maxWidth={width}
    width={width}
    shouldWrapChildren={true}
    data-testid={dataTestid}
    {...props}
  >
    {anchor}
  </Component>
);
