import { BoxProps, HStack } from '@chakra-ui/react';

import { Box } from 'shared';

type TBoardColumnHeader = BoxProps;

export const BoardColumnHeader = ({
  children,
  minHeight = 'auto',
}: TBoardColumnHeader) => (
  <Box
    width="100%"
    minHeight={minHeight}
    borderBottom="var(--border-secondary)"
  >
    <HStack w="full" p="4px 10px 4px 15px" justifyContent="space-between">
      {children}
    </HStack>
  </Box>
);
