import { useFormContext, useWatch } from 'react-hook-form';
import { For } from 'react-loops';

import { Flex, Typography } from 'shared';
import { Nullable } from 'shared_DEPRECATED/types';

import {
  useJournalMetadataQuery,
  JournalAddNoteFormSection,
  JournalAddNoteFormSectionOption,
} from 'features/journal';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const JournalAddNoteFormChallengesSection = () => {
  const user = useCurrentModeUser();

  const { data: { challenges = [], sprints = [] } = { challenges: [] } } =
    useJournalMetadataQuery({ participantId: user.userId });

  const { setValue } = useFormContext();

  const selectedChallengeId = useWatch({ name: 'challengeId' });

  const handleChallengeClick = (challengeId: Nullable<string>) => {
    setValue('challengeId', challengeId);
  };

  const [currentSprint] = sprints;

  return currentSprint?.isActive ? (
    <JournalAddNoteFormSection>
      <Flex width="100%" justifyContent="space-between">
        <Typography
          as="label"
          color="black"
          fontWeight="medium"
          type="smallHeading"
        >
          Challenges
        </Typography>
        <Typography as="span" color="gray" fontWeight="regular" type="small">
          Only 1 is required
        </Typography>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-start" gap="0.75rem">
        <JournalAddNoteFormSectionOption
          isActive={!selectedChallengeId}
          onClick={() => handleChallengeClick(null)}
        >
          Without challenge
        </JournalAddNoteFormSectionOption>
        <Flex gap="8px" flexWrap="wrap" width="100%">
          <For of={challenges}>
            {({ title, challengeId }) => (
              <JournalAddNoteFormSectionOption
                isActive={Boolean(selectedChallengeId === challengeId)}
                onClick={() => handleChallengeClick(challengeId)}
              >
                <Typography cropped={true} wordBreak="break-word">
                  {title}
                </Typography>
              </JournalAddNoteFormSectionOption>
            )}
          </For>
        </Flex>
      </Flex>
    </JournalAddNoteFormSection>
  ) : null;
};
