import { Box, Flex, Typography } from 'shared';
import { isImage } from 'shared_DEPRECATED/components/File/utils';
import { MediaType } from 'types/common';

import {
  JournalCardImageAttachment,
  JournalCardVideoAttachment,
} from 'features/journal';

import { partitionItems } from 'shared/utils/array';

type TJournalCardMediaList = {
  mediaList: MediaType[];
};

export const JournalCardMediaList = ({ mediaList }: TJournalCardMediaList) => {
  const {
    firstItems: visibleMediaPreviews,
    remainingCount: remainingMediaPreviewCount,
  } = partitionItems(mediaList, MAX_VISIBLE_MEDIA_PREVIEW);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      data-testid="journal-sidebar-card-media-file"
    >
      <Box position="relative" display="flex">
        {visibleMediaPreviews.map(({ url, contentType, thumbnail }, index) => {
          const isRotatedPreviewImage = index === 1;

          return (
            <Box
              key={url}
              position={isRotatedPreviewImage ? 'absolute' : 'relative'}
              style={{
                transform: isRotatedPreviewImage ? 'rotate(-20deg)' : 'none',
                marginLeft: isRotatedPreviewImage ? '-4px' : '0',
                order: isRotatedPreviewImage ? 0 : 1,
                top: isRotatedPreviewImage ? '0px' : '5px',
              }}
            >
              {isImage(contentType) ? (
                <JournalCardImageAttachment media={mediaList} url={url} />
              ) : (
                <JournalCardVideoAttachment
                  media={mediaList}
                  src={url}
                  previewSrc={thumbnail?.url ?? ''}
                />
              )}
            </Box>
          );
        })}
      </Box>
      {remainingMediaPreviewCount > 0 && (
        <Typography color="blue" type="meta">
          +{remainingMediaPreviewCount} more
        </Typography>
      )}
    </Flex>
  );
};

const MAX_VISIBLE_MEDIA_PREVIEW = 2;
