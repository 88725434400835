import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { useCreateChallengeModal } from 'features/challenge/hooks/useCreateChallengeModal';
import { IPlanningDraftSprint } from 'features/planning';
import { useAddChallengesToSprintMutation } from 'features/sprint/hooks/mutation/useAddChallengesToSprint';

type TUseAddDraftSprintChallenge = {
  sprint: IPlanningDraftSprint;
  options?: any;
};

export const useAddDraftSprintChallenge = ({
  sprint,
  options,
}: TUseAddDraftSprintChallenge) => {
  const { mutateAsync: addChallenge } = useAddChallengesToSprintMutation({
    sprint,
    options,
  });

  const openAddChallengeModal = useCreateChallengeModal({
    mutateFunc: async (challenge) => {
      await addChallenge([
        challenge as IRepeatedChallenge | INonRepeatedChallenge,
      ]);
    },
    sprint: sprint,
  });

  return openAddChallengeModal;
};
