import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

export const ChallengeProofIcon = () => (
  <Box
    as="span"
    style={{
      display: 'inline-block',
      width: '1rem',
      height: '1rem',
    }}
    dataTestid="journal-sidebar-card-proof-note-status"
  >
    <Flex
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Icon name={iconNames.camera} width={16} height={16} />
    </Flex>
  </Box>
);
