import { useParams } from 'react-router-dom';

import { dateUtils, updateArrayById } from 'shared_DEPRECATED/utils';

import { SESSION_ATTENDANCE_STATUS } from 'features/sessions/config';
import { SessionType } from 'features/sessions/config/types';
import { useSessionsQueryActions } from 'features/sessions/hooks/useSessionsQueryActions';

export const useSessionsPageRescheduleMutateOptions = (sessionId: string) => {
  const { getSessionList, updateSessions } = useSessionsQueryActions();
  const { email: userId } = useParams();

  const onMutate = ({
    reason,
    startAt,
    endAt,
  }: {
    reason: string;
    startAt: string;
    endAt: string;
  }) => {
    const oldSessions = getSessionList();

    const updatedSessions = updateArrayById({
      array: oldSessions,
      idProperty: 'coachingSessionId',
      updatedItem: {
        ...oldSessions.find((item) => item.coachingSessionId === sessionId),
        attendance: SESSION_ATTENDANCE_STATUS.RESCHEDULED,
        attendanceNote: reason,
        startAt: dateUtils.localDate(startAt).toISOString(),
        endAt: dateUtils.localDate(endAt).toISOString(),
      },
    });

    updateSessions(userId!, updatedSessions);

    return { oldSessions };
  };

  const onError = (
    err: Error,
    _: any,
    context: { oldSessions: SessionType[] }
  ) => {
    updateSessions(userId!, context.oldSessions);
  };

  return {
    onMutate,
    onError,
  };
};
