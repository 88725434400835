import { AbsoluteCenter } from '@chakra-ui/react';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  ParticipantsTableActionDisabled,
  ParticipantsTableActionWrapper,
  ParticipantsTableCounter,
} from 'features/participants/components/Table/Actions/';
import {
  ParticipantsTableLatestSprint,
  ParticipantsTableUserInfo,
} from 'features/participants/config/types';
import { useChatMessagesCountChange } from 'features/participants/hooks';
import { useLoadFirstUnreadMessage } from 'features/participants/hooks/useLoadFirstUnreadMessageTime';
import { useTimelineOrSprintNavigationLink } from 'features/timeline/hooks/useTimelineOrSprintNavigationLink';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

type ParticipantsTableMessagesPropTypes = {
  userInfo: ParticipantsTableUserInfo;
  latestSprint: ParticipantsTableLatestSprint;
  chatId: Nullable<string>;
  unreadChatMessagesNumber: number;
  isChatMarkedAsUnread: boolean;
};

export const ParticipantsTableMessages = ({
  userInfo,
  latestSprint,
  chatId,
  unreadChatMessagesNumber,
  isChatMarkedAsUnread,
}: ParticipantsTableMessagesPropTypes) => {
  const { email: userEmail } = userInfo;

  const navigate = useNavigate();
  const sprintLink = useTimelineOrSprintNavigationLink({
    sprintId: latestSprint.sprintId,
    userEmail: userInfo.email,
  });

  useChatMessagesCountChange({
    chatId,
    userEmail,
  });

  const hasUnreadMessages = unreadChatMessagesNumber > 0;

  const firstUnreadMessage = useLoadFirstUnreadMessage({
    chatId: chatId!,
    unreadChatMessagesNumber,
  });

  const firstUnreadMessageTime = firstUnreadMessage?.createdAt
    ? dateUtils.calculateRelativeTimeAgo(firstUnreadMessage.createdAt)
    : '';

  const handleMessageClick = () => {
    const chatState = {
      openChat: true,
    };

    if (!latestSprint?.sprintId) {
      navigate(`/coach/participants/${userEmail}/dashboard`, {
        state: chatState,
      });
    }

    navigate(sprintLink, {
      state: { ...chatState, timelineStartDate: latestSprint.startDate },
    });
  };

  const isTimeAgoBadgeVisible =
    (hasUnreadMessages && firstUnreadMessageTime) ||
    (!hasUnreadMessages && isChatMarkedAsUnread);

  return chatId ? (
    <ButtonIcon
      ariaLabel="open chat"
      icon={
        <ParticipantsTableActionWrapper>
          <Box position="relative">
            <When condition={hasUnreadMessages}>
              <AbsoluteCenter insetY="6px">
                <Typography
                  lineHeight="20px"
                  textAlign="center"
                  type="xsm"
                  dataTestid="unread-messages-counter"
                  color="black"
                >
                  {unreadChatMessagesNumber}
                </Typography>
              </AbsoluteCenter>
            </When>
            <Icon
              name={iconNames.chat}
              stroke="var(--fgPrimary)"
              width={24}
              height={24}
            />
            <When condition={isTimeAgoBadgeVisible}>
              <ParticipantsTableCounter
                dataTestid="unread-chat-badge"
                value={firstUnreadMessageTime}
              />
            </When>
          </Box>
        </ParticipantsTableActionWrapper>
      }
      onClick={handleMessageClick}
      size="sm"
      variant={VARIANTS.TERTIARY}
      dataTestid="participant-messages-btn"
    />
  ) : (
    <ParticipantsTableActionDisabled
      icon={iconNames.chat}
      label="User has an inactive coaching status"
    />
  );
};
