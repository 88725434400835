import { Suspense, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';

import { JournalNoteAddDialog } from 'features/journal';
import { useIsCoachMode } from 'features/user/hooks/useIsCoachMode';

import { modal, SIZES } from 'shared/components/Modal';

export const useOpenJournalAddNoteModal = ({
  challengeId,
  onAddNoteSuccess,
}: { challengeId?: string; onAddNoteSuccess?: () => void } = {}) => {
  const ENV = process.env.REACT_APP_FRONTEND_ENVIRONMENT;
  const isDev = ENV === 'dev' || ENV === 'local';
  const isCoachMode = useIsCoachMode();

  return useCallback(
    ({ challengeStatusDate }: { challengeStatusDate?: string } = {}) => {
      if (isCoachMode) {
        return;
      }

      if (isCoachMode && isDev) {
        console.error('Coaches cannot add notes on behalf of users');
      }

      modal.open({
        content: (
          <Suspense fallback={<Loader size="sm" />}>
            <JournalNoteAddDialog
              challengeId={challengeId}
              challengeStatusDate={challengeStatusDate}
              onAddNoteSuccess={onAddNoteSuccess}
            />
          </Suspense>
        ),
        props: {
          size: SIZES.MD,
        },
      });
    },
    [challengeId, onAddNoteSuccess, isCoachMode, isDev]
  );
};
