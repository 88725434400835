import { logError } from 'lib/sentry/logError';

import { CoachSessionCancelModal } from 'features/sessions/components/Form/Actions/CancelModal';
import { useSessionCancelMutation } from 'features/sessions/hooks/mutation/useSessionCancel';

import { modal, SIZES } from 'shared/components/Modal';
import { showErrorToast } from 'shared/components/Toast';

export const useCancelSession = (sessionId: string, options?: any) => {
  const { mutateAsync } = useSessionCancelMutation(sessionId, options);

  const cancelSession = async ({ reason }: { reason: string }) => {
    try {
      await mutateAsync({ reason });
    } catch (err) {
      showErrorToast('Failed to cancel session. Please try again.');
      logError(err);
    }
  };

  const handleCancelModalOpen = () => {
    modal.open({
      content: <CoachSessionCancelModal onSubmit={cancelSession} />,
      props: { size: SIZES.SM, title: 'Cancel session' },
    });
  };

  return {
    cancelSession,
    handleCancelModalOpen,
  };
};
