import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import { IChallenge } from 'features/challenge/config/types';
import { useEditSprintChallengesMutation } from 'features/sprint/hooks/mutation/useEditChallenges';
import { useUserId } from 'features/user/hooks/useUserId';

import { IPlanningDraftSprint } from '..';
import { usePlanningDraftSprintsQueryActions } from './query';
import { useOpenChallengeDialog } from './useOpenChallengeDialog';

type TUseOpenEditDraftSprintChallengeDialog = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const useOpenEditDraftSprintChallengeDialog = ({
  challenge,
  sprint,
}: TUseOpenEditDraftSprintChallengeDialog) => {
  const queryClient = useQueryClient();
  const userId = useUserId();

  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
    invalidateDraftSprintsQuery,
  } = usePlanningDraftSprintsQueryActions();

  const { mutateAsync: onChallengeSubmit } = useEditSprintChallengesMutation({
    sprintId: sprint.sprintId,
    options: {
      onMutate: async (updatedChallenge: IChallenge) => {
        await cancelDraftSprintsQueries();

        const previousDraftSprintsValue = getDraftSprints();

        if (previousDraftSprintsValue) {
          const updatedSprint = {
            ...sprint,
            challenges: updateArrayById({
              array: sprint.challenges,
              updatedItem: { ...updatedChallenge, goals: [] },
              idProperty: 'challengeId',
            }),
          };

          const updatedItems = updateArrayById({
            array: previousDraftSprintsValue!.items,
            updatedItem: updatedSprint,
            idProperty: 'sprintId',
          });

          updateDraftSprints(updatedItems);
        }

        return { previousDraftSprintsValue };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.challenges]);
        invalidateDraftSprintsQuery();
      },
      onError: (
        _: Error,
        __: any,
        context: { previousDraftSprintsValue?: IPlanningDraftSprint[] }
      ) => {
        queryClient.setQueryData(
          [queryKeys.draftSprints, userId],
          context?.previousDraftSprintsValue
        );
      },
    },
  });

  const openChallengeModal = useOpenChallengeDialog();

  return openChallengeModal({
    onChallengeSubmit,
    challenge,
    sprint,
  });
};
