import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryKeys } from 'api/config';
import { getJournalQueryKey } from 'api/utils';
import { produce } from 'immer';

import { JournalNote, JournalNoteReply } from 'features/journal/types';
import { useIsCoachMode } from 'features/user/hooks/useIsCoachMode';
import { useUserId } from 'features/user/hooks/useUserId';

export const useJournalQueryActions = () => {
  const userId = useUserId();
  const queryClient = useQueryClient();

  const isCoachMode = useIsCoachMode();
  const queryKey = getJournalQueryKey({ participantId: userId, isCoachMode });

  const getJournalRelevantQueries = useCallback(() => {
    const journalNotesQueries = queryClient.getQueriesData({
      queryKey: [queryKeys.journal],
    });

    return journalNotesQueries.filter(([_, data]) =>
      // @ts-ignore
      Array.isArray(data?.pages)
    );
  }, [queryClient]);

  const cancelJournalRelevantQueries = useCallback(async () => {
    const relevantQueries = getJournalRelevantQueries();

    await Promise.all(
      relevantQueries.map(([key]) => queryClient.cancelQueries(key))
    );
  }, [getJournalRelevantQueries, queryClient]);

  const updateJournalRelevantQueries = useCallback(
    (updateFn: (data: any) => any) => {
      const relevantQueries = getJournalRelevantQueries();

      relevantQueries.forEach(([key, data]) => {
        if (!data) {
          return;
        }

        queryClient.setQueryData(key, (oldData: any) =>
          produce(oldData, (draft: any) => {
            if (draft && draft.pages) {
              updateFn(draft);
            }
          })
        );
      });

      return relevantQueries.map(([key, data]) => ({ key, data }));
    },
    [getJournalRelevantQueries, queryClient]
  );

  const cancelJournalNoteQueries = useCallback(
    async (noteId?: string) => {
      await queryClient.cancelQueries(queryKey(noteId));
    },
    [queryClient, queryKey]
  );

  const getPreviousJournalNoteValue = useCallback(
    (noteId: string) => {
      queryClient.getQueryData<{
        note: JournalNote;
        replies: JournalNoteReply[];
      }>(queryKey(noteId));
    },
    [queryClient, queryKey]
  );

  const updateJournalNoteQueryData = useCallback(
    (noteId: string, updateFn: (oldData: any) => any) => {
      queryClient.setQueryData(queryKey(noteId), (oldData: any) =>
        produce(oldData, (draft: any) => {
          if (draft) {
            updateFn(draft);
          }
        })
      );
    },
    [queryClient, queryKey]
  );

  const updateNotesList = useCallback(
    (updateFn: (oldData: any) => any) => {
      queryClient.setQueryData(queryKey(), (oldData: any) =>
        produce(oldData, (draft: any) => {
          updateFn(draft);
        })
      );
    },
    [queryClient, queryKey]
  );

  const invalidateJournalNoteQueries = useCallback(
    (noteId?: string) => {
      queryClient.invalidateQueries(queryKey(noteId));
    },
    [queryClient, queryKey]
  );

  return {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    updateJournalRelevantQueries,
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
    updateNotesList,
    invalidateJournalNoteQueries,
  };
};
