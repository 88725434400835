import { FormProvider, useForm } from 'react-hook-form';
import { When } from 'react-if';

import { Flex, WarningText } from 'shared';
import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { CoachingMode } from 'features/participants/config/types';
import {
  SessionsModalDatePicker,
  SessionsModalPhaseSelector,
  SessionsModalSprintSelector,
} from 'features/sessions/components/Modal';
import { CoachSessionsScheduleModalTimeSelector } from 'features/sessions/components/ScheduleModal/TimeSelector';
import { SESSION_PHASE } from 'features/sessions/config';
import { useSessionSchedule } from 'features/sessions/hooks/useSessionSchedule';
import { getDefaultScheduleModalFormData } from 'features/sessions/utils';
import { SprintsType } from 'features/sprint/config/types';
import { getSprintsWithoutDraft } from 'features/sprint/utils';

import {
  modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';
import { Typography } from 'shared/components/Typography';

type CoachSessionScheduleModalContextType = {
  sprints: SprintsType;
  userId: string;
  options?: any;
  previousSessionId: string;
  coachingMode: CoachingMode;
};

export const CoachSessionScheduleModal = () => {
  const {
    sprints,
    userId,
    options,
    previousSessionId,
    coachingMode,
  }: CoachSessionScheduleModalContextType = useModalContext();
  const notDraftSprints = getSprintsWithoutDraft(sprints);

  const formMethods = useForm({
    defaultValues: getDefaultScheduleModalFormData(sprints, coachingMode),
  });

  const coachingSessionPhase = formMethods.watch('coachingSessionPhase');

  const { handleSessionSchedule, isLoading, isSessionDateTimeOverlapError } =
    useSessionSchedule({
      userId,
      options,
      previousSessionId,
      onSuccess: () => {
        modal.close();
      },
    });

  return (
    <FormProvider {...formMethods}>
      <ModalHeader>
        <Typography as="h3" type="body" fontWeight="semiBold" color="black">
          Schedule session
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          gap="var(--spacing03)"
        >
          <SessionsModalPhaseSelector sprints={notDraftSprints} />
          {coachingSessionPhase === SESSION_PHASE.SPRINT && (
            <SessionsModalSprintSelector sprints={notDraftSprints} />
          )}

          <SessionsModalDatePicker isWarningVisible={false} />
          <CoachSessionsScheduleModalTimeSelector />
          <When condition={isSessionDateTimeOverlapError}>
            <WarningText>
              <Typography type="meta">
                Please select another date and time. The date and time you
                selected overlaps with the next session scheduled for this user.
              </Typography>
            </WarningText>
          </When>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ModalButton variant={VARIANTS.SECONDARY}>Cancel</ModalButton>
        <Spacer size="zr md" />
        <ModalButton
          onClick={formMethods.handleSubmit(handleSessionSchedule)}
          variant={VARIANTS.PRIMARY}
          dataTestid="schedule-session-bnt"
          isLoading={isLoading}
          shouldClose={false}
        >
          Schedule
        </ModalButton>
      </ModalFooter>
    </FormProvider>
  );
};
