import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { CoachingMode } from 'features/participants/config/types';
import { useRescheduleSession } from 'features/sessions/hooks/useRescheduleSession';
import { getSessionEndTime } from 'features/sessions/utils';

import { MenuItem } from 'shared/components/Menu';

type SessionsTableMoreOptionsRescheduleMenuItemProps = {
  sessionId: string;
  sessionStartAt: string;
  sessionEndAt?: string;
  options?: any;
  coachingMode: CoachingMode;
};

export const SessionsTableMoreOptionsRescheduleMenuItem = ({
  sessionId,
  sessionStartAt,
  sessionEndAt,
  coachingMode,
  options,
}: SessionsTableMoreOptionsRescheduleMenuItemProps) => {
  const updatedSessionEndAt = sessionEndAt
    ? sessionEndAt
    : getSessionEndTime(sessionStartAt, coachingMode);

  const { handleRescheduleModalOpen } = useRescheduleSession({
    sessionId,
    sessionStartAt,
    sessionEndAt: updatedSessionEndAt,
    options,
    coachingMode,
  });

  return (
    <MenuItem
      onClick={(evt) => {
        evt.stopPropagation();
        handleRescheduleModalOpen();
      }}
      icon={
        <Icon
          name={iconNames.editCalendar}
          fill="var(--fgPrimary)"
          width={16}
          height={16}
        />
      }
    >
      Reschedule
    </MenuItem>
  );
};
