import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';

import { showSuccessToast } from 'shared/components/Toast';

export const useSessionsTableScheduleSessionMutateOptions = () => {
  const queryClient = useQueryClient();
  const { email: userId } = useParams();

  return {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.coachSessions, userId]);
      showSuccessToast('Session scheduled successfully.');
    },
  };
};
