import { HStack, VStack } from '@chakra-ui/react';

import { Typography } from 'shared';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { UserInfoTimezone } from 'features/user/components/Info/Timezone';

type TimezoneWithCurrentTimeProps = {
  timezone: Nullable<string>;
};

export const TimezoneWithCurrentTime = ({
  timezone,
}: TimezoneWithCurrentTimeProps) => {
  const tz = timezone || dateUtils.tz();
  const time = dateUtils.utc().tz(tz).format('h:mm a');

  return (
    <VStack gap={0.5} alignItems="start">
      <UserInfoTimezone timezone={timezone} />
      <HStack gap={1}>
        <Typography color="black" type="small">
          {time}
        </Typography>
        <Typography color="gray" type="small">
          Current time
        </Typography>
      </HStack>
    </VStack>
  );
};
