import { Link } from 'react-router-dom';

import { Avatar } from 'shared_DEPRECATED/components/Avatar';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import { useChatMemberOnlineStatus } from 'features/chat/hooks/useChatMemberStatus';
import {
  ParticipantsTableRowDataType,
  ParticipantsTableUserInfo,
} from 'features/participants/config/types';
import {
  getParticipantName,
  getParticipantUrl,
} from 'features/participants/utils';
import { UserInfoName } from 'features/user/components/Info/';
import { userInfo } from 'features/user/config/defaults';

const OnlineIndicatorIcon = ({ isActive }: { isActive: boolean }) => {
  return (
    <Box
      data-testid="participants-chat-messages"
      as="span"
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'inline-block',
        width: '0.5rem',
        height: '0.5rem',
        borderRadius: '50%',
        backgroundColor: isActive
          ? 'var(--bgCompSuccess)'
          : 'var(--fgSecondary)',
      }}
    />
  );
};

type UserInfoCellProps = {
  row: ParticipantsTableRowDataType;
  value: ParticipantsTableUserInfo;
};

export const ParticipantsTableUserInfoCell = ({
  value,
  row,
}: UserInfoCellProps) => {
  const isOnline = useChatMemberOnlineStatus(
    row.original.chatId,
    row.original.userInfo.email
  );

  return (
    <Box style={{ position: 'relative' }}>
      <Spacer size="zr zr zr lg">
        <Link
          data-testid={`user-info-${value.email}`}
          style={{ textDecoration: 'none' }}
          to={getParticipantUrl(value.email)}
        >
          <Flex as="span" alignItems="center" justifyContent="flex-start">
            <Box style={{ position: 'relative' }}>
              <OnlineIndicatorIcon isActive={isOnline} />
              <Avatar
                name={getParticipantName(value)}
                size={userInfo.pictureSize}
                src={getPictureUrl(value.picture)}
              />
            </Box>
            <Spacer as="span" size="zr zr zr md">
              <UserInfoName maxWidth={userInfo.maxWidth}>
                {getParticipantName(value)}
              </UserInfoName>
            </Spacer>
          </Flex>
        </Link>
      </Spacer>
    </Box>
  );
};
