import { useParams } from 'react-router-dom';

import { updateArrayById } from 'shared_DEPRECATED/utils';

import { SESSION_ATTENDANCE_STATUS } from 'features/sessions/config';
import { SessionType } from 'features/sessions/config/types';
import { useSessionsQueryActions } from 'features/sessions/hooks/useSessionsQueryActions';

export const useSessionsPageCancelSessionMutateOptions = (
  sessionId: string
) => {
  const { getSessionList, updateSessions } = useSessionsQueryActions();
  const { email: userId } = useParams();

  const onMutate = ({ reason }: { reason: string }) => {
    const oldSessions = getSessionList();

    const updatedSessions = updateArrayById({
      array: oldSessions,
      idProperty: 'coachingSessionId',
      updatedItem: {
        ...oldSessions.find((item) => item.coachingSessionId === sessionId),
        attendance: SESSION_ATTENDANCE_STATUS.CANCELED,
        attendanceNote: reason,
      },
    });

    updateSessions(userId!, updatedSessions);

    return { oldSessions };
  };

  const onError = (
    err: Error,
    _: any,
    context: { oldSessions: SessionType[] }
  ) => {
    updateSessions(userId!, context.oldSessions);
  };

  return {
    onMutate,
    onError,
  };
};
