export const ACTION_TYPES = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

export const DEFAULT_REACTIONS = [
  '👍',
  '💪',
  '🚀',
  '👌',
  '❤️',
  '🎉',
  '🔥',
  '😂',
  '😭',
  '🫠',
  '😔',
  '😱',
  '😡',
];

export const RATED_CHALLENGE_REACTIONS = ['😫', '😣', '😐', '🙂', '😀'];

export const DEFAULT_REACTIONS_OPTIONS = {
  perLine: 7,
  skinTonePosition: 'none',
  theme: 'light',
  previewPosition: 'none',
  navPosition: 'none',
  categories: [
    'people',
    'nature',
    'foods',
    'activity',
    'places',
    'objects',
    'symbols',
    'flags',
  ],
};
