import { FormProvider, useForm } from 'react-hook-form';

import { Flex, Textarea, Typography, WarningText } from 'shared';
import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SessionsModalSection } from 'features/sessions/components/Modal';

import { ModalBody, ModalFooter, ModalHeader } from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';

type CoachSessionCancelModalProps = {
  onSubmit: ({ reason }: { reason: string }) => void;
};

export const CoachSessionCancelModal = ({
  onSubmit,
}: CoachSessionCancelModalProps) => {
  const formMethods = useForm({
    defaultValues: {
      reason: '',
    },
  });

  const { formState } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <ModalHeader>
          <Typography as="h3" type="body" fontWeight="semiBold" color="black">
            Cancel session
          </Typography>
        </ModalHeader>
        <ModalBody>
          <SessionsModalSection
            title="Please provide a reason for session cancellation"
            dataTestid="cancel-session-reason"
          >
            <Flex flexDirection="column" gap={3}>
              <WarningText>
                <Typography type="meta">
                  You won't be able to schedule this session again
                </Typography>
              </WarningText>
              <Textarea
                isInvalid={!!formState.errors.reason}
                placeholder="Write the reason for cancellation here"
                {...formMethods.register('reason', {
                  setValueAs: (v) => v.trim(),
                  required: true,
                })}
                data-testid="cancel-session-modal-reason-field"
              />
            </Flex>
          </SessionsModalSection>
        </ModalBody>
        <ModalFooter>
          <ModalButton variant={VARIANTS.SECONDARY}>Cancel</ModalButton>
          <Spacer size="zr md" />
          <ModalButton
            type="submit"
            variant={VARIANTS.PRIMARY}
            shouldClose={formState.isValid}
            dataTestid="cancel-session-bnt"
          >
            Confirm
          </ModalButton>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
