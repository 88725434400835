import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { ButtonIcon, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { ObjectValues } from 'types/common';

import { SPRINT_TYPES } from 'features/sprint/config';
import { useSprintStartSubmit } from 'features/sprint/hooks';
import useFinishSprintMutation from 'features/sprint/hooks/mutation/useFinishSprint';
import { useUserId } from 'features/user/hooks/useUserId';

type TProps = {
  sprintType?: ObjectValues<typeof SPRINT_TYPES>;
  sprintId: string;
  bridgeModeId: string;
};

export const PlanningDraftSprintHeaderStartButtonForBridgeMode = ({
  sprintType,
  sprintId,
  bridgeModeId,
}: TProps) => {
  const userId = useUserId();
  const queryClient = useQueryClient();
  const startSprint = useSprintStartSubmit({
    sprintId,
    sprintType,
  });
  const finishMutation = useFinishSprintMutation(
    // @ts-ignore
    {
      sprintId: bridgeModeId,
      onSuccess: async () => {
        await queryClient.invalidateQueries([queryKeys.sprints, userId]);

        startSprint();
      },
    }
  );

  return (
    <ButtonIcon
      ariaLabel="start sprint"
      icon={<Icon name={iconNames.play} width={16} height={16} />}
      onClick={() => finishMutation.mutate()}
      variant={VARIANTS.TERTIARY}
      dataTestid="start-sprint-button"
    />
  );
};
