import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  CheckboxGroup,
} from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

import { useDimensionsQuery } from 'features/lifeDimensions/hooks/query/useDimensions';

const DimensionsField = () => {
  const { control, formState } = useFormContext();
  const { data = [], isFetching } = useDimensionsQuery();
  const errorMessage = formState.errors.dimensions?.message;

  if (isFetching) {
    return <Loader dataTestid="dimensions-loader" size="sm" />;
  }

  return (
    <FormControl isInvalid={!!errorMessage} isRequired={true}>
      <FormLabel>Choose Dimensions</FormLabel>
      <Controller
        name="dimensions"
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <CheckboxGroup value={value.map(firstLetterUpperCase)} {...rest}>
            <Flex
              flexWrap="wrap"
              justifyContent="flex-start"
              gap="var(--spacing02) var(--spacing04)"
            >
              {data.map((dimension) => (
                <Checkbox
                  key={dimension}
                  ref={ref}
                  label={dimension}
                  value={dimension}
                  dataTestid={`challenge-dialog-dimension-${dimension.toLowerCase()}`}
                  size="sm"
                />
              ))}
            </Flex>
          </CheckboxGroup>
        )}
        rules={{
          required: 'At least one dimension should be selected',
        }}
      />
      <FormErrorMessage data-testid="challenge-dialog-dimension-field-validation">
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};

export default DimensionsField;
