import { Button, VARIANTS } from 'shared';
import { ObjectValues } from 'types/common';

import { CoachSessionScheduleModal } from 'features/sessions/components/Modal/CoachSessionScheduleModal';
import { useLatestSessionId } from 'features/sessions/hooks/useLatestSessionId';
import { useParticipantCoachingMode } from 'features/sessions/hooks/useParticipantCoachingMode';
import { useRecentSprints } from 'features/sessions/hooks/useRecentSprints';
import { useSessionsTableScheduleSessionMutateOptions } from 'features/sessions/hooks/useSessionsTableScheduleMutateOptions';
import { useUserId } from 'features/user/hooks/useUserId';

import { modal, SIZES } from 'shared/components/Modal';

type CoachSessionsScheduleButtonProps = {
  children: React.ReactNode;
  dataTestid?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  variant?: ObjectValues<typeof VARIANTS>;
};

export const CoachSessionsScheduleButton = ({
  children,
  dataTestid,
  icon,
  variant = VARIANTS.PRIMARY,
  fullWidth = true,
}: CoachSessionsScheduleButtonProps) => {
  const userId = useUserId();
  const twoRecentSprints = useRecentSprints();
  const options = useSessionsTableScheduleSessionMutateOptions();
  const previousSessionId = useLatestSessionId();
  const coachingMode = useParticipantCoachingMode();

  const handleModalOpen = () => {
    modal.open({
      content: <CoachSessionScheduleModal key={userId} />,
      props: { size: SIZES.SM, title: 'Schedule session modal' },
      context: {
        sprints: twoRecentSprints,
        options,
        userId,
        previousSessionId,
        coachingMode,
      },
    });
  };

  return (
    <Button
      dataTestid={dataTestid}
      leftIcon={<>{icon}</>}
      variant={variant}
      onClick={handleModalOpen}
      w={fullWidth ? 'full' : ''}
    >
      {children}
    </Button>
  );
};
