import { dateUtils } from 'shared_DEPRECATED/utils';

import { useUpdateParticipantsQuery } from 'features/participants/hooks/query/useUpdateParticipants';
import { SESSION_ATTENDANCE_STATUS } from 'features/sessions/config';
import {
  ScheduledSessionConfigType,
  SessionType,
} from 'features/sessions/config/types';

import { showSuccessToast } from 'shared/components/Toast';

export const useParticipantsTableScheduleSessionMutateOptions = ({
  userEmail,
}: {
  userEmail: string;
}) => {
  const updateParticipantsQuery = useUpdateParticipantsQuery({
    userEmail,
    fieldKey: 'nextCoachingSessionAppointment',
  });

  return {
    onMutate: (config: ScheduledSessionConfigType) => {
      updateParticipantsQuery({
        startAt: config.startAt,
        attendance: SESSION_ATTENDANCE_STATUS.SCHEDULED,
      });
    },
    onSuccess: (res: SessionType) => {
      updateParticipantsQuery({
        startAt: dateUtils.localDate(res.startAt).toISOString(),
        attendance: res.attendance,
        appointmentId: res.coachingSessionId,
      });
      showSuccessToast('Session scheduled successfully.');
    },
  };
};
