import { useMemo } from 'react';
import { useFilters, useSortBy, useTable } from 'react-table';

import {
  NEW_SESSION_CREATION_FLOW_FEATURE_FLAG,
  useFeatureFlag,
} from 'featureFlags';

import { coachSessionsTableColumnsData } from 'features/sessions/config/coachTableColumnsData';
import {
  SessionListItemType,
  SessionTableItemType,
} from 'features/sessions/config/types';
import { getSessionsTableColumnsData } from 'features/sessions/utils';

const defaultSorting = [
  {
    id: 'startAt',
    desc: true,
  },
];

export const useCoachSessionsTableData = (sessions: SessionListItemType[]) => {
  const isNewSessionCreationFlowFFEnabled = useFeatureFlag(
    NEW_SESSION_CREATION_FLOW_FEATURE_FLAG
  );

  const columns = useMemo(
    () =>
      isNewSessionCreationFlowFFEnabled
        ? coachSessionsTableColumnsData
        : coachSessionsTableColumnsData.filter(
            (column) => column.accessor !== 'recapStatus'
          ),
    [isNewSessionCreationFlowFFEnabled]
  );

  const data = useMemo(
    () => getSessionsTableColumnsData(sessions),
    [sessions]
  ) as SessionTableItemType[];

  return useTable(
    {
      columns,
      data,
      initialState: { sortBy: defaultSorting },
    },
    useFilters,
    useSortBy
  );
};
