import { ReactNode } from 'react';

import { Button, VARIANTS } from 'shared';

type TJournalAddNoteFormSectionOption = {
  isActive: boolean;
  onClick: () => void;
  children: ReactNode;
};

export const JournalAddNoteFormSectionOption = ({
  isActive,
  onClick,
  children,
}: TJournalAddNoteFormSectionOption) => (
  <Button
    onClick={onClick}
    variant={isActive ? VARIANTS.PRIMARY : VARIANTS.SECONDARY}
    size="xs"
    _active={{
      outline: 'none',
    }}
  >
    {children}
  </Button>
);
