import { ButtonIcon, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { ObjectValues } from 'types/common';

import { SPRINT_TYPES } from 'features/sprint/config';
import { useSprintStartSubmit } from 'features/sprint/hooks';

type TProps = {
  sprintType?: ObjectValues<typeof SPRINT_TYPES>;
  sprintId: string;
};

export const PlanningDraftSprintHeaderStartButtonForSprint = ({
  sprintType,
  sprintId,
}: TProps) => {
  const onStartSprintButtonClick = useSprintStartSubmit({
    sprintId,
    sprintType,
  });

  return (
    <ButtonIcon
      ariaLabel="start sprint"
      icon={<Icon name={iconNames.play} width={16} height={16} />}
      onClick={onStartSprintButtonClick}
      variant={VARIANTS.TERTIARY}
      dataTestid="start-sprint-button"
    />
  );
};
