import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { SessionType } from 'features/sessions/config/types';
import { mapSessionPostData } from 'features/sessions/utils';

export const useSessionUpdateMutation = (sessionId: string) => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();
  const { email } = useParams();

  return useMutation(
    (session: SessionType) =>
      request({
        url: `/api/coach/coaching-sessions/${sessionId}/update`,
        body: mapSessionPostData(session),
      }),
    {
      onSuccess: (updatedSession) => {
        queryClient.setQueryData<SessionType>(
          ['coach-session', updatedSession.coachingSessionId],
          (oldSession) => ({ ...oldSession, ...updatedSession })
        );
        queryClient.invalidateQueries([queryKeys.coachSession, sessionId]);
        queryClient.invalidateQueries([queryKeys.coachSessions, email]);
      },
      onError: (e) => {
        logError(e);
      },
    }
  );
};
