import Box from 'shared_DEPRECATED/components/Box/Box';

import { Typography } from 'shared/components/Typography';

export const RatedChallenge = () => {
  return (
    <Typography
      color="gray"
      type="small"
      fontWeight="regular"
      whiteSpace="nowrap"
      dataTestid="sprint-calendar-due-date"
    >
      <Box as="span">rated challenge</Box>
    </Typography>
  );
};
