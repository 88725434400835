import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

type TUseDeleteSprintChallengesMutation = {
  sprintId: string;
  options?: any;
};

export const useDeleteSprintChallengesMutation = ({
  sprintId,
  options,
}: TUseDeleteSprintChallengesMutation) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    async (challengeIds: string[]) =>
      await request({
        url: `/api/web/sprints/${sprintId}/challenges/delete`,
        body: { challengeIds },
      }),
    { ...options }
  );
};
