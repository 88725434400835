import { VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type TSessionSidebarFieldset = {
  children: ReactNode;
  dataTestid?: string;
};

export const SessionSidebarFieldset = ({
  children,
  dataTestid,
}: TSessionSidebarFieldset) => (
  <VStack align="stretch" gap="4px" pb="12px" w="full" data-testid={dataTestid}>
    {children}
  </VStack>
);
