import { USER_MODES } from '../../features/user/config';
import { useUserMode } from '../../features/user/hooks';
import { useFeatureFlag } from './useFeatureFlag';
import { useParticipantsFeatureFlag } from './useParticipantsFeatureFlag';

export const useMyOrParticipantFeatureFlag = (featureFlag: string) => {
  const userMode = useUserMode();

  const participantFeatureFlag = useParticipantsFeatureFlag(featureFlag);

  const myFeatureFlag = useFeatureFlag(featureFlag);

  return userMode === USER_MODES.dashboard
    ? myFeatureFlag
    : participantFeatureFlag;
};
