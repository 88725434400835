import { useFormContext, useWatch } from 'react-hook-form';
import { When } from 'react-if';
import { useParams } from 'react-router-dom';

import {
  Menu,
  MenuButton,
  MenuOptionGroup,
  MenuItemOption,
  MenuList,
  HStack,
  Typography,
} from 'shared';
import { FormSection } from 'shared_DEPRECATED/components';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import FieldLabel from 'shared_DEPRECATED/components/Form/FieldLabel';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { capitalize } from 'shared_DEPRECATED/utils';

import { ChallengeGoalsFieldAnchor } from 'features/challenge/components/Form/fields/GoalsField/Anchor';
import { GoalOption, GoalsDataResult } from 'features/challenge/config/types';
import { useGoalsQuery } from 'features/goal';
import { GoalTransformed } from 'features/goal/config/types';

export const ChallengeGoalsField = () => {
  const { setValue } = useFormContext();
  const { goalId: preselectedGoalId } = useParams();
  const { data: goals = [], isLoading: isGoalsLoading } = useGoalsQuery();

  const selectedGoals = useWatch({ name: 'goals' }) || [];

  const { goalOptions, selectedGoalIds } = getGoalsData(
    goals,
    selectedGoals,
    preselectedGoalId
  );

  const handleDropdownChange = (goalId: string) => {
    const updatedGoalOptions = goalOptions.map((goal) => {
      if (goal.goalId === goalId) {
        return { ...goal, isSelected: !goal.isSelected };
      }
      return goal;
    });

    setValue(
      'goals',
      updatedGoalOptions.filter((option) => option.isSelected)
    );
  };

  if (isGoalsLoading) {
    return <Loader />;
  }

  return (
    <When condition={goals.length > 0}>
      <FormSection>
        <Flex flexDirection="column" alignItems="flex-start">
          <FieldLabel>Connect goal</FieldLabel>
          <Spacer size="sm" />
          <Box
            style={{
              overflow: 'hidden',
              borderRadius: 'var(--border-radius)',
              border: 'var(--border-secondary)',
            }}
          >
            <Menu closeOnSelect={false}>
              <MenuButton>
                <ChallengeGoalsFieldAnchor
                  goalOptions={selectedGoals}
                  handleDeleteGoal={handleDropdownChange}
                />
              </MenuButton>
              <MenuList maxH="20rem" overflowX="hidden">
                <MenuOptionGroup defaultValue={selectedGoalIds} type="checkbox">
                  {goalOptions.map(
                    ({ goalId, title, isSelected, prioritized }) => (
                      <MenuItemOption
                        key={`${goalId}-${isSelected}`}
                        value={goalId}
                        isDisabled={goalId === preselectedGoalId}
                        onClick={() => handleDropdownChange(goalId)}
                        isChecked={isSelected}
                      >
                        <HStack justifyContent="space-between">
                          <Typography
                            w="200px"
                            cropped
                            title={capitalize(title)}
                          >
                            {title}
                          </Typography>
                          <When condition={prioritized}>
                            <Icon
                              name={iconNames.flagNew}
                              fill="var(--fgInteractive)"
                              stroke="var(--fgInteractive)"
                            />
                          </When>
                        </HStack>
                      </MenuItemOption>
                    )
                  )}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </FormSection>
    </When>
  );
};

export const getGoalsData = (
  goals: GoalTransformed[],
  selectedGoals: { goalId: string }[],
  preselectedGoalId?: string
): GoalsDataResult =>
  goals.reduce(
    (acc: GoalsDataResult, goal) => {
      const isSelected =
        selectedGoals.some((selected) => selected.goalId === goal.goalId) ||
        goal.goalId === preselectedGoalId;

      const goalOption: GoalOption = {
        goalId: goal.goalId,
        title: goal.title,
        prioritized: goal.prioritized,
        isSelected,
      };

      acc.goalOptions.push(goalOption);

      if (isSelected) {
        acc.selectedGoalIds.push(goal.goalId);
      }

      return acc;
    },
    {
      goalOptions: [],
      selectedGoalIds: [],
    }
  );
