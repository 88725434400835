import { Link } from 'react-router-dom';

import {
  IMPROVED_GROUP_VIEW_FEATURE_FLAG,
  RenderIfFFEnabled,
} from 'featureFlags';
import { Flex as FlexNew } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { FlexItem, Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalCardTitle,
  GoalCardMenu,
  VISIBLE_DIMENSIONS_COUNT,
  useGoalsNavigationLink,
  GoalImage,
  GoalBadge,
  PrioritizeGoalCallback,
} from 'features/goal';
import { GoalPrioritizeToggle } from 'features/goal/components/PrioritizeToggle';
import { useGoalCardContext } from 'features/goal/context/cardContext';
import { LifeDimensions } from 'features/lifeDimensions/components/LifeDimensions';

import { Createdat } from './Createdat';

type GoalCardProps = {
  togglePrioritize: PrioritizeGoalCallback;
  dataTestid?: string;
};

export const GoalCard = ({ dataTestid, togglePrioritize }: GoalCardProps) => {
  const url = useGoalsNavigationLink();
  const { goal } = useGoalCardContext();
  const {
    goalId,
    name,
    dimensions,
    deadline,
    columnId,
    completedAt,
    cover,
    createdAt,
    prioritizedAt,
  } = goal;

  const cardBackgroundColor = !!completedAt
    ? 'linear-gradient(180deg, var(--bgCompPrimary) 10%, var(--bgCompMinorSuccess) 90%)'
    : 'var(--bgCompPrimary)';

  return (
    <Box
      style={{
        background: cardBackgroundColor,
        minHeight: '50px',
        width: '100%',
        borderRadius: 'var(--border-radius)',
      }}
      dataTestid={dataTestid}
    >
      <Link
        to={`${url}/${goalId}`}
        style={{ textDecoration: 'none', cursor: 'inherit' }}
      >
        <FlexItem flexGrow={1}>
          <Spacer size="md">
            <Flex
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="var(--spacing01)"
            >
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="flex-start"
              >
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <GoalCardTitle name={name} />
                  <LifeDimensions
                    dimensions={dimensions}
                    visibleDimensionsCount={VISIBLE_DIMENSIONS_COUNT}
                    maxWidth="13rem"
                  />
                  {createdAt ? <Createdat createdAt={createdAt} /> : ''}
                </Flex>
                <GoalImage imageUrl={cover?.url} />
              </Flex>
              <Spacer size="xsm" />
              <FlexNew
                alignItems="center"
                justifyContent="space-between"
                gap="var(--spacing01)"
                w="100%"
              >
                <FlexNew alignItems="center" gap="var(--spacing01)">
                  <GoalBadge deadline={deadline} completedAt={completedAt} />
                  <RenderIfFFEnabled
                    featureFlag={IMPROVED_GROUP_VIEW_FEATURE_FLAG}
                  >
                    <GoalPrioritizeToggle
                      goalId={goalId}
                      isPrioritized={!!prioritizedAt}
                      columnId={columnId}
                      togglePrioritize={togglePrioritize}
                    />
                  </RenderIfFFEnabled>
                </FlexNew>
                <GoalCardMenu />
              </FlexNew>
            </Flex>
          </Spacer>
        </FlexItem>
      </Link>
    </Box>
  );
};
