import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalChallengeCard,
  GoalBreakdownChallengeCardMenu,
  useMoveGoalBreakdownChallenges,
} from 'features/goal';
import { useGoalBreakdownDataContext } from 'features/goal/context/goalBreakdown';
import { useMoveGoalBreakdownChallengesMutation } from 'features/goal/hooks/mutation/useMoveGoalBreakdownChallenge';

import { Droppable } from 'shared/components/DragNDrop/Droppable';
import { Sortable } from 'shared/components/DragNDrop/Sortable';

export const GoalBreakdownBoardChallenges = () => {
  const { goalBreakdownData } = useGoalBreakdownDataContext();
  const { mutate: mutateChallenges } = useMoveGoalBreakdownChallengesMutation();
  const { moveToChallenges, moveToFocusArea } =
    useMoveGoalBreakdownChallenges();

  return (
    <Droppable
      acceptType={['challenge']}
      onDrop={({ id, index }) => {
        mutateChallenges({
          customChallengeId: id,
          index,
        });
      }}
      onHover={(draggedItem: {
        id: string;
        focusAreaIndex: number;
        index: number;
      }) => moveToFocusArea(-1)(draggedItem)}
    >
      {goalBreakdownData?.challenges.map((challenge, challengeIndex) => (
        <Sortable
          key={`${challenge.customChallengeId}-${challengeIndex}`}
          item={{
            type: 'challenge',
            id: challenge.customChallengeId,
            index: challengeIndex,
            focusAreaIndex: -1,
          }}
          onHover={moveToChallenges(-1)}
          onDrop={({ id, index }) => {
            mutateChallenges({
              customChallengeId: id,
              index,
            });
          }}
        >
          <GoalChallengeCard
            title={challenge.title}
            picture={challenge.picture}
            emoji={challenge.emoji}
            dimensions={challenge.dimensions}
            goals={challenge.goals}
          >
            <GoalBreakdownChallengeCardMenu challenge={challenge} />
          </GoalChallengeCard>
          <Spacer size="xsm" />
        </Sortable>
      ))}
    </Droppable>
  );
};
