import { ObjectValues } from 'types/common';

import { PlanningSprintModel } from 'features/planning/Sprint';
import { PlanningDraftSprintHeaderStartButtonForBridgeMode } from 'features/planning/components/Board/DraftSprint/Header/StartButton/ForBridgeMode';
import { PlanningDraftSprintHeaderStartButtonForSprint } from 'features/planning/components/Board/DraftSprint/Header/StartButton/ForSprint';
import { SPRINT_ENTITY_TYPES } from 'features/planning/config';
import { useIsDraftSprintReadyToStart } from 'features/planning/hooks/useIsDraftSprintReadyToStart';
import { SPRINT_TYPES } from 'features/sprint/config';
import { useSprintsQuery } from 'features/sprint/hooks';

export const PlanningDraftSprintHeaderStartButton = ({
  sprintType,
  sprintId,
  sprintChallengesCount,
  sprintTitle,
}: {
  sprintType?: ObjectValues<typeof SPRINT_TYPES>;
  sprintId: string;
  sprintChallengesCount: number;
  sprintTitle: string;
}) => {
  const { data: sprints } = useSprintsQuery<PlanningSprintModel[]>();

  const isSprintReadyToStart = useIsDraftSprintReadyToStart({
    sprintChallengesCount,
    sprintTitle,
  });

  if (!isSprintReadyToStart) {
    return null;
  }

  const lastSprint = sprints![0];
  const isActiveBridgeMode =
    lastSprint?.entityType === SPRINT_ENTITY_TYPES.BRIDGE_MODE &&
    lastSprint?.isActive;

  return isActiveBridgeMode ? (
    <PlanningDraftSprintHeaderStartButtonForBridgeMode
      sprintType={sprintType}
      sprintId={sprintId}
      bridgeModeId={lastSprint.sprintId}
    />
  ) : (
    <PlanningDraftSprintHeaderStartButtonForSprint
      sprintType={sprintType}
      sprintId={sprintId}
    />
  );
};
