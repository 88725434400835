import { Flex, Typography, VARIANTS } from 'shared';

import { ModalBody, ModalFooter, ModalHeader } from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';

type PrioritizedGoalLimitReachedProps = {
  name: string;
  onSubmit: () => void;
};

export const GoalPrioritizeLimitReachedModal = ({
  name,
  onSubmit,
}: PrioritizedGoalLimitReachedProps) => (
  <>
    <ModalHeader>
      <Typography type="heading" fontWeight="semiBold" pr={2}>
        {name} already has the maximum prioritized goals
      </Typography>
    </ModalHeader>
    <ModalBody>
      <Typography type="small" fontWeight="regular" color="gray">
        If you want to review goals and decide what goals have more priority,
        please check the priorities below
      </Typography>
    </ModalBody>
    <ModalFooter>
      <Flex gap="var(--spacing04)">
        <ModalButton variant={VARIANTS.SECONDARY} dataTestid="cancel-modal-btn">
          Cancel
        </ModalButton>
        <ModalButton
          shouldClose={false}
          variant={VARIANTS.PRIMARY}
          onClick={onSubmit}
          dataTestid="check-the-priorities-modal-btn"
        >
          Check the priorities
        </ModalButton>
      </Flex>
    </ModalFooter>
  </>
);
