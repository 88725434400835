import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { produce } from 'immer';
import { logError } from 'lib/sentry/logError';

import { usePlanningDraftSprintsQueryActions } from 'features/planning';
import { useEditSprintTitleMutation } from 'features/sprint/hooks/mutation/useEditSprintTitle';
import { useUserId } from 'features/user/hooks/useUserId';

export const useEditDraftSprintTitle = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();
  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
    invalidateDraftSprintsQuery,
  } = usePlanningDraftSprintsQueryActions();

  const { mutateAsync: editSprintTitle } = useEditSprintTitleMutation({
    options: {
      onMutate: async ({
        title,
        sprintId,
      }: {
        title: string;
        sprintId: string;
      }) => {
        await cancelDraftSprintsQueries();

        const previousDraftSprintsValue = getDraftSprints();

        if (previousDraftSprintsValue) {
          const updatedSprints = produce(
            previousDraftSprintsValue.items,
            (draft) => {
              const sprintIndex = draft.findIndex(
                (sprint) => sprint.sprintId === sprintId
              );

              draft[sprintIndex].title = title;
            }
          );

          updateDraftSprints(updatedSprints);
        }

        return { previousDraftSprintsValue };
      },
      onError: (err: Error, __: any, context: any) => {
        if (context?.previousDraftSprintsValue) {
          queryClient.setQueryData(
            [queryKeys.draftSprints, userId],
            context.previousDraftSprintsValue
          );
        }
        logError(err);
      },
      onSuccess: () => {
        invalidateDraftSprintsQuery();
      },
    },
  });

  return { editSprintTitle };
};
