import { useFormContext } from 'react-hook-form';

import { Box, Textarea } from 'shared';

import { SessionsModalSection } from 'features/sessions/components/Modal';

export const CoachSessionRescheduleReason = () => {
  const { register } = useFormContext();

  return (
    <Box w="full">
      <SessionsModalSection
        title="Reason"
        dataTestid="reschedule-session-reason"
      >
        <Textarea
          size="sm"
          placeholder="Write the reason for rescheduling here"
          {...register('reason')}
          data-testid="reschedule-session-modal-reschedule-reason"
        />
      </SessionsModalSection>
    </Box>
  );
};
