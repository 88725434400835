import { MenuItem } from 'shared';
import { updateArrayById } from 'shared_DEPRECATED/utils/array/array';

import {
  IPlanningDraftSprint,
  useDraftSprintAddLibraryChallenge,
  usePlanningAnalyticsEvents,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning';

type TPlanningDraftSprintAddLibraryChallengeButton = {
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintAddLibraryChallengeButton = ({
  sprint,
}: TPlanningDraftSprintAddLibraryChallengeButton) => {
  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();
  const { getDraftSprints, updateDraftSprints } =
    usePlanningDraftSprintsQueryActions();

  const openExploreChallengesModal = useDraftSprintAddLibraryChallenge({
    sprint,
    options: {
      onSuccess: async (
        // @ts-ignore
        updatedSprint
      ) => {
        const previousDraftSprintsValue = getDraftSprints();

        const updatedItems = updateArrayById({
          array: previousDraftSprintsValue!.items,
          updatedItem: updatedSprint,
          idProperty: 'sprintId',
        });

        updateDraftSprints(updatedItems);
        sendPlanningChallengeAddedEvent('sprintDraft');
      },
    },
  });

  return (
    <MenuItem onClick={openExploreChallengesModal}>Add from library</MenuItem>
  );
};
