import {
  NEW_SESSION_CREATION_FLOW_FEATURE_FLAG,
  RenderIfFFEnabled,
} from 'featureFlags';

import { SessionsListDeleteButton } from 'features/sessions/components/Table/MoreOptions/DeleteButton';
import { SessionsTableMoreOptionsMenu } from 'features/sessions/components/Table/MoreOptions/SessionsTableMoreOptionsMenu';
import { SessionsTableRowDataType } from 'features/sessions/config/types';

type SessionsTableMoreOptionsCellProps = {
  value: string;
  row: SessionsTableRowDataType;
};

export const SessionsTableMoreOptionsCell = ({
  value: sessionId,
  row,
}: SessionsTableMoreOptionsCellProps) => {
  const { original } = row;
  const { startAt, endAt } = original;

  return (
    <RenderIfFFEnabled
      featureFlag={NEW_SESSION_CREATION_FLOW_FEATURE_FLAG}
      fallback={<SessionsListDeleteButton sessionId={sessionId} />}
    >
      <SessionsTableMoreOptionsMenu
        attendance={row.original.attendance}
        sessionId={sessionId}
        sessionStartAt={startAt}
        sessionEndAt={endAt}
      />
    </RenderIfFFEnabled>
  );
};
