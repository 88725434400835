import {
  NEW_SESSION_CREATION_FLOW_FEATURE_FLAG,
  RenderIfFFEnabled,
} from 'featureFlags';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { CoachSessionsCreateButton } from 'features/sessions/components';
import { CoachSessionsScheduleButton } from 'features/sessions/components/ScheduleButton';

import { Typography } from 'shared/components/Typography';

export const SessionsEmptyState = () => (
  <Flex
    flexDirection="column"
    gap="var(--spacing02)"
    height="100%"
    justifyContent="center"
  >
    <Typography>🧐</Typography>
    <Typography
      type="heading"
      fontWeight="semiBold"
      color="black"
      dataTestid="empty-session-list"
    >
      No sessions yet
    </Typography>
    <Typography type="meta" fontWeight="regular" color="gray">
      Start by adding a new session.
    </Typography>
    <Spacer size="lg zr zr">
      <RenderIfFFEnabled
        featureFlag={NEW_SESSION_CREATION_FLOW_FEATURE_FLAG}
        fallback={<CoachSessionsCreateButton />}
      >
        <CoachSessionsScheduleButton
          dataTestid="coach-sessions-schedule-btn"
          icon={
            <Icon
              width={12}
              height={12}
              name={iconNames.plus}
              stroke="var(--fgPrimaryInverse)"
            />
          }
          fullWidth={false}
        >
          Schedule session
        </CoachSessionsScheduleButton>
      </RenderIfFFEnabled>
    </Spacer>
  </Flex>
);
