import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { When } from 'react-if';

import { Box } from 'shared';
import FileUpload from 'shared_DEPRECATED/components/Form/FileUpload';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { GoalImage } from 'features/goal/components/Image';

export const GoalAddFormCoverSection = () => {
  const { setValue, control } = useFormContext();

  const [cover, imageToUpload] = useWatch({
    control,
    name: ['cover', 'imageToUpload'],
  });

  const hasImage = Boolean(cover?.url || imageToUpload);

  const handleFileChange = async (files: File[]) => {
    if (!files) {
      return;
    }

    const [file] = files;
    const base64Image = await convertFileToBase64Image(file);
    setValue('imageToUpload', base64Image);
  };

  return (
    <Box pb={5}>
      <FileUpload
        onFileUpload={handleFileChange}
        dataTestid="attachment-file-btn"
        extensions="image/*"
      >
        <ClearImage
          hasImage={hasImage}
          onClear={() => {
            setValue('cover', null);
            setValue('imageToUpload', null);
          }}
        >
          <GoalImage imageUrl={imageToUpload || cover?.url} />
        </ClearImage>
      </FileUpload>
    </Box>
  );
};

type ClearImageProps = {
  onClear: () => void;
  children: React.ReactNode;
  hasImage: boolean;
};

const ClearImage = ({ onClear, children, hasImage }: ClearImageProps) => {
  const handleClear = (e: React.MouseEvent<SVGElement>) => {
    e.preventDefault();
    onClear();
  };
  return (
    <Box position="relative">
      <When condition={hasImage}>
        <Box position="absolute" right={-1} top={-1}>
          <Icon
            name={iconNames.crossSmall}
            stroke="var(--fgPrimary)"
            onClick={handleClear}
          />
        </Box>
      </When>
      {children}
    </Box>
  );
};

const convertFileToBase64Image = async (file: File) => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.onload = (fileLoadedEvent) => {
      resolve(fileLoadedEvent.target?.result as string);
    };
    reader.readAsDataURL(file);
  });
};
