import { Nullable } from 'shared_DEPRECATED/types';

import { SessionDateSelector } from 'features/participants/components/Table/SessionDateSelector';
import { useSessionDateMutation } from 'features/participants/hooks';

import { showErrorToast } from 'shared/components/Toast';

type SessionDateCellContentProps = {
  email: string;
  appointmentId?: string;
  startAt?: string;
};

export const SessionDateCellContent__OLD = ({
  email,
  appointmentId,
  startAt,
}: SessionDateCellContentProps) => {
  const sessionDateMutation = useSessionDateMutation({
    userEmail: email,
    appointmentId,
  });

  const currentDateString = startAt;

  const handleSessionDateMutation = async (
    updatedDateString: Nullable<string>
  ) => {
    try {
      await sessionDateMutation.mutateAsync({
        currentDateString,
        updatedDateString,
      });
    } catch (e) {
      showErrorToast('Unable to update session date. Try again later.');
    }
  };

  return (
    <SessionDateSelector
      sessionDateString={currentDateString}
      onDateChange={handleSessionDateMutation}
      key={currentDateString}
    />
  );
};
