import { Radio } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  SELECT_BUTTON_SIZES,
  SELECT_BUTTON_TYPES,
  SelectButton,
} from 'shared_DEPRECATED/components/Form/SelectButton';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { dateUtils, range } from 'shared_DEPRECATED/utils';

import { CHECKBOX_BUTTONS_GAP, TARGET_TYPES } from 'features/challenge/config';
import { WeeklyTargetType } from 'features/challenge/config/types';
import { getWeekLabel } from 'features/sprint/utils';

import { useModalContext } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';

type ChallengeFrequencyDialogCustomWeeklyFieldProps = {
  frequency: {
    type: string;
    weekly: WeeklyTargetType;
  };
  onFrequencyChange: (frequency: { type: string; weekly: number[] }) => void;
  sprintWeeksCount: number;
};

export const ChallengeFrequencyDialogCustomWeeklyField = ({
  frequency,
  onFrequencyChange,
  sprintWeeksCount,
}: ChallengeFrequencyDialogCustomWeeklyFieldProps) => {
  const { currentWeekNumber } = useModalContext();
  const checked =
    frequency.type === TARGET_TYPES.WEEKLY && Array.isArray(frequency.weekly);
  const onRadioButtonChange = () =>
    onFrequencyChange({
      type: TARGET_TYPES.WEEKLY,
      weekly: new Array(sprintWeeksCount).fill(frequency.weekly || 1),
    });

  return (
    <Flex flexDirection="column" alignItems="start">
      <Radio
        isChecked={checked}
        onChange={onRadioButtonChange}
        data-testid="challenge-frequency-weekly-custom-radio"
      >
        Custom number of days each week
      </Radio>
      <Spacers sizes={['sm zr', 'xsm zr']} />
      {checked && (
        <Flex flexDirection="column" alignItems="end">
          {range(1, sprintWeeksCount).map((weekNumber) => {
            const weeklyFrequencyZeroCount = frequency.weekly.reduce(
              (sum, weekFrequency) => {
                if (weekFrequency === 0) {
                  sum++;
                }

                return sum;
              },
              0
            );
            const isSingleDaySelected =
              weeklyFrequencyZeroCount === sprintWeeksCount - 1;
            const weekIndex = weekNumber - 1;
            const isCurrentWeek = currentWeekNumber === weekNumber;

            const isPreviousWeek = weekNumber < currentWeekNumber;

            return (
              <>
                <Flex justifyContent="start" gap={CHECKBOX_BUTTONS_GAP}>
                  <Typography
                    type="small"
                    fontWeight={isCurrentWeek ? 'bold' : 'regular'}
                    color="black"
                    data-testid="challenge-frequency-weekly-custom-dropdown-label"
                  >
                    {getWeekLabel(weekIndex)}{' '}
                    {isCurrentWeek && <Typography as="sup">*</Typography>}
                  </Typography>
                  <Spacers sizes={['zr zr zr xsm']} />
                  {range(0, dateUtils.WEEK_DAYS_COUNT).map((day) => {
                    const onSelectButtonChange = () => {
                      const newWeekly = [...frequency.weekly];
                      newWeekly[weekIndex] = day;

                      onFrequencyChange({
                        type: TARGET_TYPES.WEEKLY,
                        weekly: newWeekly,
                      });
                    };
                    const checked = frequency.weekly[weekIndex] === day;

                    return (
                      <SelectButton
                        type={SELECT_BUTTON_TYPES.SINGLE}
                        checked={checked}
                        key={day}
                        minWidth="3.25rem"
                        onChange={onSelectButtonChange}
                        size={SELECT_BUTTON_SIZES.SM}
                        dataTestid={`challenge-frequency-daily-day-${day}`}
                        disabled={
                          (isSingleDaySelected && !checked && day === 0) ||
                          isPreviousWeek
                        }
                      >
                        <Typography
                          fontWeight={isCurrentWeek ? 'bold' : 'regular'}
                          color="black"
                        >
                          {day}
                        </Typography>
                      </SelectButton>
                    );
                  })}
                </Flex>
                <Spacers sizes={['sm zr', 'xsm zr']} />
              </>
            );
          })}
          <Spacers sizes={['sm zr', 'xsm zr']} />
        </Flex>
      )}
    </Flex>
  );
};
