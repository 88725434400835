import { iconNames, TIconName } from 'shared_DEPRECATED/components/Icon';

import { SPRINT_STATES, SPRINT_STATUS_LABELS } from 'features/sprint/config';
import { SprintStateValues } from 'features/sprint/config/types';

export const DEFAULT_DRAFT_SPRINTS_COUNT = 3;

export const UNLINKED_CHALLENGES_VALUE = 'unlinked_challenges';

export const DESELECT_ALL_GOALS_LABEL = 'Deselect all';
export const SELECT_ALL_GOALS_LABEL = 'Select all';

export const UNLINKED_CHALLENGES_CONFIG = {
  label: 'Other challenges',
  value: UNLINKED_CHALLENGES_VALUE,
};

export const BOARD_COLUMN_WIDTH = '23rem';

export const PLANNING_SPRINT_BACKGROUND_STYLES_MAP = {
  [SPRINT_STATES.ACTIVE]: 'var(--bgCompMinorSuccess)',
  [SPRINT_STATES.FINISHED]: 'var(--bgCompMinorAccent)',
};

export const DRAGGABLE_ITEM_TYPES = {
  CHALLENGE: 'challenge',
  COACHING_TOOL: 'coaching-tool',
} as const;

// a sub-state of "finished" state that was finished earlier
export const SPRINT_STATE_UNFINISHED = {
  KEY: 'unfinished',
  LABEL: 'Unfinished',
};

export const PLANNING_SPRINT_STATE_ICON_MAP: Record<
  SprintStateValues,
  TIconName
> = {
  [SPRINT_STATES.ACTIVE]: iconNames.playCircle,
  [SPRINT_STATES.FINISHED]: iconNames.checkCircle,
  [SPRINT_STATE_UNFINISHED.KEY]: iconNames.cancelCircle,
} as const;

export const SPRINT_STATE_LABELS_WITH_SUB_STATE = {
  ...SPRINT_STATUS_LABELS,
  [SPRINT_STATE_UNFINISHED.KEY]: SPRINT_STATE_UNFINISHED.LABEL,
};

export const SPRINT_ENTITY_TYPES = {
  SPRINT: 'sprint',
  BRIDGE_MODE: 'bridge_mode',
} as const;
