import {
  JournalItemType,
  JOURNAL_NOTE_ITEM_TYPES,
  JournalNote,
} from 'features/journal';
import { User } from 'features/user/User';

import { JournalNotesDateItem } from './Item/Date';
import { JournalNotesItem } from './Item/Note';
import { JournalNotesWithoutChallengesItem } from './Item/NoteWithoutChallenges';
import { JournalNotesSprintItem } from './Item/Sprint';

type TJournalNotesItem = JournalItemType & {
  user: User;
  setEditedNote: (note: JournalNote) => void;
};

export const JournalNoteItem = ({
  type,
  data,
  user,
  setEditedNote,
}: TJournalNotesItem) => {
  const SectionComponent =
    JOURNAL_NOTES_ITEM_COMPONENT_MAP[
      type as keyof typeof JOURNAL_NOTES_ITEM_COMPONENT_MAP
    ];

  if (type === JOURNAL_NOTE_ITEM_TYPES.SPRINT_SECTION) {
    const { sprintTitle, sprintDates } = data;

    const SprintComponent =
      SectionComponent as typeof JOURNAL_NOTES_ITEM_COMPONENT_MAP[typeof JOURNAL_NOTE_ITEM_TYPES.SPRINT_SECTION];

    return (
      <SprintComponent sprintTitle={sprintTitle} sprintDates={sprintDates} />
    );
  }

  if (type === JOURNAL_NOTE_ITEM_TYPES.DATE_SECTION) {
    const { date } = data;

    const DateComponent =
      SectionComponent as typeof JOURNAL_NOTES_ITEM_COMPONENT_MAP[typeof JOURNAL_NOTE_ITEM_TYPES.DATE_SECTION];

    return <DateComponent date={date} />;
  }

  if (type === JOURNAL_NOTE_ITEM_TYPES.NOTE) {
    const { note } = data;

    const isAuthor = Boolean(note.authorId === user.userId);

    const NoteComponent =
      SectionComponent as typeof JOURNAL_NOTES_ITEM_COMPONENT_MAP[typeof JOURNAL_NOTE_ITEM_TYPES.NOTE];

    return (
      <NoteComponent
        note={note}
        setEditedNote={setEditedNote}
        isAuthor={isAuthor}
      />
    );
  }

  if (type === JOURNAL_NOTE_ITEM_TYPES.NOTE_WITHOUT_CHALLENGES) {
    const { title } = data;

    const NoteWithoutChallengesComponent =
      SectionComponent as typeof JOURNAL_NOTES_ITEM_COMPONENT_MAP[typeof JOURNAL_NOTE_ITEM_TYPES.NOTE_WITHOUT_CHALLENGES];

    return <NoteWithoutChallengesComponent title={title} />;
  }

  throw new Error(`Invalid journal item type: ${type}`);
};

const JOURNAL_NOTES_ITEM_COMPONENT_MAP = {
  [JOURNAL_NOTE_ITEM_TYPES.NOTE]: JournalNotesItem,
  [JOURNAL_NOTE_ITEM_TYPES.SPRINT_SECTION]: JournalNotesSprintItem,
  [JOURNAL_NOTE_ITEM_TYPES.DATE_SECTION]: JournalNotesDateItem,
  [JOURNAL_NOTE_ITEM_TYPES.NOTE_WITHOUT_CHALLENGES]:
    JournalNotesWithoutChallengesItem,
} as const;
