import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { MainHeader } from 'app/layout/components/Header';
import classNames from 'classnames/bind';

import { useSendJWTTokenToExtension } from 'features/extension/hooks/useSendTokenToExtension';

import styles from 'app/layout/components/Layout/Layout.module.css';

const cx = classNames.bind(styles);

const CoachLayout = ({ children }) => {
  useSendJWTTokenToExtension();

  const [isInIframe, setIsInIframe] = useState(false);

  useEffect(() => {
    // Check if the current window is inside an iframe
    setIsInIframe(window.self !== window.top);
  }, []);

  return (
    <div className={styles.layout}>
      <div className={cx('main-wrapper')}>
        {!isInIframe && <MainHeader />}
        <main className={cx('main-content', 'main-content--coach')}>
          {children}
        </main>
      </div>
    </div>
  );
};

CoachLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoachLayout;
