import { VStack } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useJournalAnalytics } from 'app/analytics/useJournalAnalytics';
import { Flex } from 'shared';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  JournalAddNoteFormChallengesSection,
  JournalAddNoteFormPostToFeedSection,
  JournalAddNoteFormAttachMediaSection,
  JournalAddNoteFormTextSection,
  JournalAddNoteDialogFooter,
  JournalAddNoteDialogHeader,
  JOURNAL_NOTE_DEFAULT_VALUE,
  JournalNotePOSTType,
  useJournalAddNoteMutation,
} from 'features/journal';
import { useJournalAttachmentsUpload } from 'features/journal/hooks/useJournalAttachmentsUpload';

import { ModalBody, ModalHeader, ModalFooter } from 'shared/components/Modal';
import { showErrorToast } from 'shared/components/Toast';

type TJournalNoteAddDialog = {
  challengeId?: string;
  challengeStatusDate?: string;
  onAddNoteSuccess?: () => void;
};

export const JournalNoteAddDialog = ({
  challengeId,
  challengeStatusDate = dateUtils(new Date()).format('YYYY-MM-DD'),
  onAddNoteSuccess,
}: TJournalNoteAddDialog) => {
  const methods = useForm<JournalNotePOSTType>({
    defaultValues: {
      ...JOURNAL_NOTE_DEFAULT_VALUE,
      challengeStatusDate,
      ...(challengeId && {
        challengeId,
      }),
    },
  });

  const { mutateAsync: addNote } = useJournalAddNoteMutation({
    onSuccess: onAddNoteSuccess,
  });
  const { uploadAttachments } = useJournalAttachmentsUpload();

  const onSubmit = useCallback(
    async (note: JournalNotePOSTType) => {
      try {
        let mediaIds: string[] = [];

        if (Boolean(note.media.length)) {
          mediaIds = await uploadAttachments(note.media);
        }

        await addNote({
          ...note,
          mediaIds,
        });
      } catch (err) {
        showErrorToast(
          "There's an error with creating the note. Please try again later."
        );
      }
    },
    [addNote, uploadAttachments]
  );

  const { sendNoteAddedAnalyticsEvent } = useJournalAnalytics();

  const onSubmitButtonClick = methods.handleSubmit(
    (note: JournalNotePOSTType) => {
      onSubmit(note);

      sendNoteAddedAnalyticsEvent();
    }
  );

  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, [methods]);

  return (
    <>
      <ModalHeader>
        <JournalAddNoteDialogHeader />
      </ModalHeader>
      <FormProvider {...methods}>
        <ModalBody>
          <VStack width="100%">
            <JournalAddNoteFormTextSection />
            <JournalAddNoteFormChallengesSection />
            {/* <JournalAddNoteFormFailReasonsSection /> */}
            <JournalAddNoteFormAttachMediaSection />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <JournalAddNoteFormPostToFeedSection />
            <JournalAddNoteDialogFooter onSubmit={onSubmitButtonClick} />
          </Flex>
        </ModalFooter>
      </FormProvider>
    </>
  );
};

export default JournalNoteAddDialog;
