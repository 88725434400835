import { VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { When } from 'react-if';

import { logError } from 'lib/sentry/logError';
import { Typography, WarningText } from 'shared';
import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { CoachSessionRescheduleReason } from 'features/sessions/components/Form/Actions/components/RescheduleReason';
import { SessionsModalDatePicker } from 'features/sessions/components/Modal';
import { CoachSessionsScheduleModalTimeSelector } from 'features/sessions/components/ScheduleModal/TimeSelector';
import { SESSION_DATE_UPDATE_TIME_OVERLAP_ERROR } from 'features/sessions/config';
import { useRescheduleSessionMutation } from 'features/sessions/hooks/mutation/useRescheduleSession';
import { getSessionEndTime } from 'features/sessions/utils';

import {
  modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';
import { showErrorToast } from 'shared/components/Toast';

type CoachSessionRescheduleModalProps = {
  sessionId: string;
  mutateOptions?: any;
};

export const CoachSessionRescheduleModal = ({
  sessionId,
  mutateOptions,
}: CoachSessionRescheduleModalProps) => {
  const { sessionStartAt, coachingMode } = useModalContext();

  const { mutateAsync, isLoading, error } = useRescheduleSessionMutation(
    sessionId,
    mutateOptions
  );

  const isSessionDateTimeOverlapError =
    error?.name === SESSION_DATE_UPDATE_TIME_OVERLAP_ERROR;

  const rescheduleSession = async (data: {
    reason?: string;
    startAt: string;
  }) => {
    try {
      await mutateAsync(data);
      modal.close();
    } catch (err) {
      showErrorToast('Failed to reschedule session. Please try again.');
      logError(err);
    }
  };

  const formMethods = useForm({
    defaultValues: {
      startAt: dateUtils(dateUtils.localDate(sessionStartAt)).toISOString(),
      coachingSessionDate: dateUtils(
        dateUtils.localDate(sessionStartAt)
      ).toISOString(),
      endAt: getSessionEndTime(
        dateUtils(dateUtils.localDate(sessionStartAt)).toISOString(),
        coachingMode
      ),
      reason: '',
    },
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(rescheduleSession)}>
        <ModalHeader>
          <Typography as="h3" type="body" fontWeight="semiBold" color="black">
            Reschedule session
          </Typography>
        </ModalHeader>
        <ModalBody>
          <VStack alignItems="flex-start" gap={3}>
            <SessionsModalDatePicker isWarningVisible={false} />
            <CoachSessionsScheduleModalTimeSelector />
            <CoachSessionRescheduleReason />
            <When condition={isSessionDateTimeOverlapError}>
              <WarningText>
                <Typography type="meta">
                  Please select another date and time. The date and time you
                  selected overlaps with the next session scheduled for this
                  user.
                </Typography>
              </WarningText>
            </When>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ModalButton variant={VARIANTS.SECONDARY}>Cancel</ModalButton>
          <Spacer size="zr md" />
          <ModalButton
            type="submit"
            variant={VARIANTS.PRIMARY}
            dataTestid="coach-session-reschedule-btn"
            shouldClose={false}
            isLoading={isLoading}
          >
            Reschedule
          </ModalButton>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
