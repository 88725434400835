import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { dimensionFirstLetterToUpperCase } from 'features/challenge/utils';

const FIVE_MINUTES = 5 * 60 * 1000;

export const useDimensionsQuery = (options) => {
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.dimensions],
    () => request({ url: `/api/dimensions` }),
    {
      staleTime: FIVE_MINUTES,
      select: (data) =>
        data.items.map((dimension) =>
          dimensionFirstLetterToUpperCase(dimension.name)
        ),
      ...options,
    }
  );
};
