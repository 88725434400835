import { Flex, Typography } from 'shared';
import { dateUtils } from 'shared_DEPRECATED/utils';

type TJournalCardCreatedAtTime = {
  date: string;
};
export const JournalCardCreatedAtTime = ({
  date,
}: TJournalCardCreatedAtTime) => (
  <Flex flexShrink={0}>
    <Typography
      as="div"
      type="meta"
      color="black"
      dataTestid="journal-item-created-at-time"
    >
      {dateUtils.localDate(date).format('h:mm a')}
    </Typography>
  </Flex>
);
