import { Nullable } from 'shared_DEPRECATED/types';

import {
  FormSaveIndicatorSaved,
  FormSaveIndicatorSaving,
  FormSaveIndicatorUnsaved,
} from './SaveIndicator/';
import { FormSaveErrorIndicator } from './SaveIndicator/SaveError';

type TCoachSessionFormSaveIndicator = {
  savedAt?: Nullable<string>;
  isSubmitting: boolean;
  isDirty: boolean;
  error?: boolean;
};

export const FormSaveIndicator = ({
  savedAt,
  isSubmitting,
  isDirty,
  error,
}: TCoachSessionFormSaveIndicator) => (
  <>
    {isSubmitting && <FormSaveIndicatorSaving />}
    {!isSubmitting && isDirty && <FormSaveIndicatorUnsaved />}
    {!isSubmitting && !isDirty && !error && savedAt && (
      <FormSaveIndicatorSaved savedAt={savedAt} />
    )}
    {!isSubmitting && !isDirty && error && <FormSaveErrorIndicator />}
  </>
);
