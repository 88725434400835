import { useChatHistoryQuery } from 'features/participants/hooks/query/useChatHistory';

type LoadFirstUnreadMessageTimeProps = {
  unreadChatMessagesNumber: number;
  chatId: string;
};

export const useLoadFirstUnreadMessage = ({
  chatId,
  unreadChatMessagesNumber,
}: LoadFirstUnreadMessageTimeProps) => {
  const { data: chatMessages = [] } = useChatHistoryQuery({
    chatId,
    enabled: unreadChatMessagesNumber > 0,
    limit: unreadChatMessagesNumber,
  });

  return chatMessages ? chatMessages[chatMessages.length - 1] : null;
};
