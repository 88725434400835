import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { TIMELINE_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';

import { SPRINT_TYPES } from 'features/sprint/config';
import { useCreateDraftSprintInstanceWithWeeksCount } from 'features/sprint/hooks/useCreateDraftSprintInstanceWithWeeksCount';
import { mapSprints } from 'features/sprint/utils';

export const useMySprintsQuery = (options) => {
  const { request } = useQueryHTTPRequest();
  const createDraftSprintWithWeeksCount =
    useCreateDraftSprintInstanceWithWeeksCount();
  const isTimelineEnabled = useFeatureFlag(TIMELINE_FEATURE_FLAG);

  return useQuery(
    [queryKeys.mySprints],
    async () => await request({ url: `/api/sprints/mine` }),
    {
      ...options,
      select: (data) => {
        const sprints = mapSprints({
          sprints: data?.items || [],
          sprintType: SPRINT_TYPES.MY,
        });

        const draftSprint =
          data?.draftSprint &&
          createDraftSprintWithWeeksCount(data.draftSprint);

        return draftSprint && !isTimelineEnabled
          ? [draftSprint, ...sprints]
          : sprints;
      },
    }
  );
};
