import { useMutation } from '@tanstack/react-query';

import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { showErrorToast } from 'shared/components/Toast';

type TUseEditSprintTitleMutation = {
  options?: any;
};

export const useEditSprintTitleMutation = ({
  options,
}: TUseEditSprintTitleMutation) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ title, sprintId }: { title: string; sprintId: string }) =>
      request({
        url: `/api/draft-sprints/${sprintId}/title`,
        body: { title },
      }),
    {
      ...options,
      onError: (error) => {
        options?.onError?.(error);

        showErrorToast(
          "There's an error with editing the sprint title. Please try again later."
        );
        logError(error);
      },
    }
  );
};
