import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  IPlanningDraftSprint,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning';
import { useDeleteSprintChallengesMutation } from 'features/sprint/hooks/mutation/useDeleteChallenges';
import { useUserId } from 'features/user/hooks/useUserId';

import { showErrorToast } from 'shared/components/Toast/Toast';

type TUseRemovePlanningDraftSprintChallengesMutation = {
  sprint: IPlanningDraftSprint;
};

export const useRemovePlanningDraftSprintChallengesMutation = ({
  sprint,
}: TUseRemovePlanningDraftSprintChallengesMutation) => {
  const queryClient = useQueryClient();
  const userId = useUserId();
  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    invalidateDraftSprintsQuery,
  } = usePlanningDraftSprintsQueryActions();

  const { mutateAsync: deleteSprintChallenges } =
    useDeleteSprintChallengesMutation({
      sprintId: sprint.sprintId,
      options: {
        onMutate: async (challengeIds: string[]) => {
          await cancelDraftSprintsQueries();

          const previousDraftSprintsValue = getDraftSprints();

          if (previousDraftSprintsValue) {
            queryClient.setQueryData<{ items: IPlanningDraftSprint[] }>(
              [queryKeys.draftSprints, userId],
              (oldVal) => {
                const updatedSprint = {
                  ...sprint,
                  challenges:
                    sprint.challenges?.filter(
                      (challenge) =>
                        !challengeIds.includes(challenge.challengeId!)
                    ) || [],
                };

                return {
                  ...oldVal,
                  items: updateArrayById({
                    array: oldVal!.items,
                    updatedItem: updatedSprint,
                    idProperty: 'sprintId',
                  }),
                };
              }
            );
          }

          return { previousDraftSprintsValue };
        },
        onSuccess: () => {
          invalidateDraftSprintsQuery();
        },
        onError: (
          err: Error,
          _: any,
          context: {
            previousDraftSprintsValue?: (
              | INonRepeatedChallenge
              | IRepeatedChallenge
            )[];
          }
        ) => {
          queryClient.setQueryData(
            [queryKeys.draftSprints, userId],
            context?.previousDraftSprintsValue
          );

          logError(err);

          showErrorToast(
            'There was an error with deleting challenge. Please try again later.'
          );
        },
      },
    });

  return { deleteSprintChallenges };
};
