import { useParams, useLocation } from 'react-router-dom';

import {
  TIMELINE_FEATURE_FLAG,
  useMyOrParticipantFeatureFlag,
} from 'featureFlags';
import { getPathParams } from 'shared_DEPRECATED/utils';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { useSprintLink } from 'features/sprint/hooks/useSprintLink';
import { sharedSprintLinks } from 'features/sprint/utils';

export const useTimelineOrSprintNavigationLink = ({
  sprintId,
  userEmail,
}: {
  userEmail?: string;
  sprintId: string;
}) => {
  const isTimelineEnabled = useMyOrParticipantFeatureFlag(
    TIMELINE_FEATURE_FLAG
  );
  const sprintLink = useSprintLink(sprintId, userEmail);
  const timelineLink = useTimelineLink(userEmail);

  return isTimelineEnabled ? timelineLink : sprintLink;
};

const useTimelineLink = (emailParam?: string) => {
  const { pathname } = useLocation();
  const { email } = useParams();
  const userEmail = email || emailParam;

  let [firstPathParam] = getPathParams(pathname);

  const timelineUrlResolver = TIMELINE_URL_RESOLVER_MAP[firstPathParam];

  return userEmail
    ? timelineUrlResolver?.(userEmail)
    : `/${routeNames.CHALLENGE_TRACKING}`;
};

const TIMELINE_URL_RESOLVER_MAP = {
  [routeNames.COACH]: (email: string) =>
    `${sharedSprintLinks.participants}/${email}/${routeNames.CHALLENGE_TRACKING}`,
};
