import { useFormContext } from 'react-hook-form';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { SessionsModalSection } from 'features/sessions/components/Modal';
import { SESSION_PHASE, SESSION_PHASE_LABEL } from 'features/sessions/config';
import { SessionPhaseType } from 'features/sessions/config/types';
import { SprintsType } from 'features/sprint/config/types';

import { Dropdown, DropdownItem } from 'shared/components/Dropdown';

type PhaseSelectorPropTypes = {
  sprints: SprintsType;
};

export const SessionsModalPhaseSelector = ({
  sprints,
}: PhaseSelectorPropTypes) => {
  const { setValue, watch } = useFormContext();

  const sessionPhase = watch('coachingSessionPhase');

  const onChange = (sessionPhase: SessionPhaseType) => {
    setValue(
      'sprintId',
      sessionPhase === SESSION_PHASE.SPRINT ? sprints[0].sprintId : null
    );
    setValue('coachingSessionPhase', sessionPhase);
  };

  return (
    <SessionsModalSection
      title="Participant's mode"
      dataTestid="session-phase-selector"
    >
      <Dropdown
        //@ts-ignore
        onItemSelect={onChange as () => void}
        anchorContent={SESSION_PHASE_LABEL[sessionPhase]}
        selectedValue={sessionPhase}
        anchorSpacing={['md']}
      >
        <Box style={{ width: '17rem' }}>
          <Flex alignItems="stretch" flexDirection="column" width="100%">
            {Object.values(SESSION_PHASE)
              .filter(
                (sessionPhase) =>
                  sprints.length > 0 || sessionPhase !== SESSION_PHASE.SPRINT
              )
              .map((sessionPhase) => (
                <DropdownItem
                  value={sessionPhase}
                  key={sessionPhase}
                  dataTestid="session-phase-dropdown-option"
                >
                  {SESSION_PHASE_LABEL[sessionPhase]}
                </DropdownItem>
              ))}
          </Flex>
        </Box>
      </Dropdown>
    </SessionsModalSection>
  );
};
