import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { useParticipantsTable } from 'features/participants/hooks';

import { Typography } from 'shared/components/Typography';

import styles from './ParticipantsTable.module.css';

const TableHeaderContent = ({
  canSort,
  isSorted,
  isSortedDesc,
  children,
}: {
  canSort: boolean;
  isSorted: boolean;
  isSortedDesc: boolean;
  children: React.ReactNode;
}) => (
  <Spacer size="lg lg lg zr">
    <Flex
      justifyContent="flex-start"
      dataTestid="participants-table-header"
      gap="0.5rem"
      alignItems="center"
    >
      <Typography
        fontWeight="medium"
        type="small"
        whiteSpace="nowrap"
        color="gray"
      >
        {children}
      </Typography>
      {canSort && (
        <Icon
          name={
            isSorted && !isSortedDesc
              ? iconNames.chevronUp
              : iconNames.chevronDown
          }
          width={8}
          stroke={isSorted ? 'var(--fgPrimary)' : 'var(--fgSecondary)'}
        />
      )}
    </Flex>
  </Spacer>
);

const TableCellContent = ({
  children,
  isLastCell,
}: {
  children: React.ReactNode;
  isLastCell: boolean;
}) => (
  <Spacer size={`lg ${isLastCell ? 'zr' : 'lg'} lg zr`}>
    <Spacer size={`zr ${isLastCell ? 'zr' : 'lg'} zr zr`}>{children}</Spacer>
  </Spacer>
);

const ParticipantsTable = ({
  tableProps,
}: {
  tableProps: ReturnType<typeof useParticipantsTable>;
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableProps;

  return (
    <table className={styles['participants-table']} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => {
          const { key, ...headerGroupRest } = headerGroup.getHeaderGroupProps();

          return (
            <tr key={key} {...headerGroupRest} className={styles.row}>
              {headerGroup.headers.map((column) => {
                const { key, ...columnHeaderPropsRest } = column.getHeaderProps(
                  column.getSortByToggleProps()
                );

                return (
                  <th key={key} {...columnHeaderPropsRest}>
                    <TableHeaderContent
                      canSort={column.canSort}
                      isSorted={column.isSorted}
                      isSortedDesc={!!column.isSortedDesc}
                    >
                      {column.render('Header')}
                    </TableHeaderContent>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          const { key, ...rowPropsRest } = row.getRowProps();

          return (
            <tr
              key={key}
              {...rowPropsRest}
              className={styles.row}
              data-testid="participants-table-row"
            >
              {row.cells.map((cell, cellIndex) => {
                const { key, ...cellPropsRest } = cell.getCellProps();

                return (
                  <td key={key} {...cellPropsRest}>
                    <TableCellContent
                      isLastCell={cellIndex === row.cells.length - 1}
                    >
                      {cell.render('Cell')}
                    </TableCellContent>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ParticipantsTable;
