import { ReactNode } from 'react';

import { Button, VARIANTS } from 'shared';

type TJournalFilterButton = {
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
  children: ReactNode;
  dataTestid?: string;
};

export const JournalFilterButton = ({
  isActive = false,
  disabled,
  onClick,
  children,
  dataTestid,
}: TJournalFilterButton) => (
  <Button
    onClick={onClick}
    variant={isActive ? VARIANTS.PRIMARY : VARIANTS.SECONDARY}
    disabled={disabled}
    dataTestid={dataTestid}
  >
    {children}
  </Button>
);
