import { VStack } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { When } from 'react-if';
import { For } from 'react-loops';

import { useJournalAnalytics } from 'app/analytics/useJournalAnalytics';
import { SidebarBody, useSidebarContext, Box } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';

import {
  JournalNotesContainer,
  useJournalContext,
  JournalNote,
  useJournalEditNoteMutation,
  JournalForm,
  useJournalMetadataQuery,
  JournalFormHeader,
  JournalFormHeaderTitle,
  JournalAddNoteButton,
  getNotesItems,
  JournalItemType,
} from 'features/journal';
import { User } from 'features/user/User';
import { useUserMode } from 'features/user/hooks';

import { JournalNoteItem } from './Body/Item';

export const JournalNotesBody = () => {
  const [editedNote, setEditedNote] = useState<Nullable<JournalNote>>(null);
  const { notes, scrollRef, isFetchingNextPage } = useJournalContext();
  const { mutateAsync: editNote } = useJournalEditNoteMutation();
  const { user, participantId } = useSidebarContext() as {
    user: User;
    participantId: string;
  };
  const { sendNoteEditedAnalyticsEvent } = useJournalAnalytics();

  const userMode = useUserMode();

  const { data: { sprints } = { sprints: [] } } = useJournalMetadataQuery({
    participantId,
  });

  const groupedNotes = useMemo(() => {
    if (!notes) {
      return [];
    }

    return getNotesItems(notes, sprints);
  }, [notes, sprints]);

  const handleEditNote = async ({ text }: { text: string }) => {
    const noteId = editedNote?.noteId ?? '';
    setEditedNote(null);

    await editNote({ text, noteId });

    sendNoteEditedAnalyticsEvent();
  };

  return (
    <SidebarBody dataTestid="notes-sidebar-body">
      {userMode === USER_TYPES.USER && !editedNote && <JournalAddNoteButton />}
      <JournalNotesContainer>
        <When condition={Boolean(notes)}>
          {() => (
            <>
              <VStack p="0 0.5rem 0 1rem">
                <For of={groupedNotes}>
                  {({ type, data }: JournalItemType) => (
                    //@ts-ignore
                    <JournalNoteItem
                      type={type}
                      data={data}
                      user={user}
                      setEditedNote={setEditedNote}
                    />
                  )}
                </For>
              </VStack>
              <div ref={scrollRef}></div>
              <Spacer size="md">
                {isFetchingNextPage ? <Loader size="sm" /> : null}
              </Spacer>
            </>
          )}
        </When>
      </JournalNotesContainer>
      {editedNote && (
        <Box
          position="sticky"
          bottom="0"
          bg="var(--bgPrimary)"
          p="1rem"
          boxShadow="0 -2px 4px rgba(0, 0, 0, 0.1)"
        >
          <JournalForm
            text={editedNote.text}
            onSave={handleEditNote}
            placeholderText="Edit note text..."
          >
            <JournalFormHeader onCancel={() => setEditedNote(null)}>
              <JournalFormHeaderTitle>Edit note</JournalFormHeaderTitle>
            </JournalFormHeader>
          </JournalForm>
        </Box>
      )}
    </SidebarBody>
  );
};
