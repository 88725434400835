import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getJournalQueryKey } from 'api/utils';
import { logError } from 'lib/sentry/logError';

import { IBaseChallenge } from 'features/challenge/config/types';
import { JournalNote, useJournalQueryActions } from 'features/journal';
import { IFinalSprint } from 'features/sprint/config/types';
import { useIsCoachMode } from 'features/user/hooks/useIsCoachMode';

import { showErrorToast } from 'shared/components/Toast';

type TUseJournalMarkNotesAsReadMutation = {
  participantId: string;
};

export const useJournalMarkNotesAsReadMutation = ({
  participantId,
}: TUseJournalMarkNotesAsReadMutation) => {
  const { email } = useParams();

  const { request } = useMutationHTTPRequest();

  const isCoachMode = useIsCoachMode();

  const coachPrefix = isCoachMode ? `/coach/${participantId}` : '';

  const journalQueryKey = getJournalQueryKey({ participantId, isCoachMode });

  const queryClient = useQueryClient();

  const participantQueryKey = [queryKeys.participant, email];

  const {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    updateJournalRelevantQueries,
  } = useJournalQueryActions();

  return useMutation(
    async () =>
      await request({
        url: `/api${coachPrefix}/journal/notes/read`,
      }),
    {
      onMutate: () => {
        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) => {
              note.isRead = true;

              return note;
            }),
          }));
        });

        if (isCoachMode) {
          queryClient.setQueryData<{
            unreadNotesRepliesNumber: number;
          }>(participantQueryKey, (oldData) => ({
            ...oldData,
            unreadNotesRepliesNumber: 0,
          }));
        } else {
          queryClient.setQueryData<{
            unreadReplyReferences: { noteId: string; replyId: string }[];
            sprints: IFinalSprint[];
            challenges: IBaseChallenge[];
          }>([journalQueryKey(), 'metadata'], (oldData) => {
            if (!oldData) {
              return oldData;
            }

            return {
              ...oldData,
              unreadReplyReferences: [],
            };
          });
        }

        return { previousJournalNotesData };
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with marking notes as unread. Please try again later."
        );

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });

        logError(err);
      },
    }
  );
};
