import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import { PlanningSprintModel, IPlanningSprint } from 'features/planning';
import { useUserId } from 'features/user/hooks/useUserId';

export const useSprintsQuery = <T>(
  options: { enabled?: boolean } = {
    enabled: true,
  }
) => {
  const { request } = useQueryHTTPRequest();
  const userId = useUserId();

  return useQuery<{ items: IPlanningSprint[] }, unknown, T>(
    [queryKeys.sprints, userId],
    () => request({ url: `/api/web/sprints?user_id=${userId}` }),
    {
      enabled: !!userId && options.enabled,
      // @ts-ignore
      select,
      staleTime: 1000 * 20,
      onError: (error) => {
        logError(error);
      },
    }
  );
};

const select = (data: { items: IPlanningSprint[] }) =>
  data.items.map((sprint) => new PlanningSprintModel(sprint));
