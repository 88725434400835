import { ReactNode } from 'react';

import { useSidebarCollapseContext } from 'app/layout/context/drawer';
import { motion } from 'framer-motion';
import { ButtonIcon, Flex, VARIANTS } from 'shared';
import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

export const LayoutPageSidebarWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isOpen, setIsOpen } = useSidebarCollapseContext();
  const iconName = isOpen
    ? iconNames.doubleArrowLeft
    : iconNames.doubleArrowRight;
  const sidebarWidth = isOpen
    ? 'var(--sidebar-width)'
    : 'var(--sidebar-width-collapsed)';
  const sidebarBtnAlign = isOpen ? 'flex-end' : 'center';

  return (
    <motion.div
      initial={{ width: sidebarWidth }}
      animate={{ width: sidebarWidth }}
      transition={{ duration: 0.5 }}
      style={{
        height: '100%',
        borderRight: 'var(--border-secondary)',
        boxSizing: 'border-box',
        overflow: 'hidden',
        flexShrink: 0,
      }}
    >
      <Spacers height="100%" sizes={['lg']}>
        <Flex
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="stretch"
          height="100%"
        >
          <FlexItem alignSelf={sidebarBtnAlign}>
            <ButtonIcon
              ariaLabel="toggle sidebar"
              icon={<Icon name={iconName} width={24} height={24} />}
              onClick={setIsOpen}
              size="sm"
              variant={VARIANTS.TERTIARY}
            />
          </FlexItem>
          <Spacers sizes={['lg zr zr', 'md zr zr']} />
          <Flex
            height="100%"
            width="100%"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            {children}
          </Flex>
        </Flex>
      </Spacers>
    </motion.div>
  );
};
