import { useNavigate } from 'react-router-dom';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { MenuItem } from 'shared/components/Menu';

type SessionsTableMoreOptionsViewSessionMenuItemProps = {
  sessionId: string;
  userEmail: string;
};

export const SessionsTableMoreOptionsViewSessionMenuItem = ({
  sessionId,
  userEmail,
}: SessionsTableMoreOptionsViewSessionMenuItemProps) => {
  const navigate = useNavigate();

  return (
    <MenuItem
      onClick={(evt) => {
        evt.stopPropagation();
        navigate(`/coach/participants/${userEmail}/sessions/${sessionId}`);
      }}
      icon={
        <Icon
          name={iconNames.listAlt}
          fill="var(--fgPrimary)"
          width={16}
          height={16}
        />
      }
    >
      Go to session
    </MenuItem>
  );
};
