import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  COLOR_BY_SESSION_RECAP_STATUS,
  SESSION_RECAP_STATUS_LABEL,
} from 'features/sessions/config';
import { SessionRecapStatusType } from 'features/sessions/config/types';

import { Badge, BADGE_COLORS } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type SessionsTableRecapCellProps = {
  value: SessionRecapStatusType;
};

export const SessionsTableRecapCell = ({
  value: recapStatus,
}: SessionsTableRecapCellProps) => {
  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Badge
        bgColor={COLOR_BY_SESSION_RECAP_STATUS[recapStatus] as BADGE_COLORS}
      >
        <Typography
          type="small"
          color="whiteTotal"
          textTransform="uppercase"
          dataTestid="session-attendance-cell"
        >
          {SESSION_RECAP_STATUS_LABEL[recapStatus]}
        </Typography>
      </Badge>
    </Flex>
  );
};
