import Box from 'shared_DEPRECATED/components/Box/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';

import UserTypeSelect from 'features/user/components/TypeSelect/UserTypeSelect';
import { APOLLO_OPTION, typeEmojies } from 'features/user/config';

import { DropdownItem } from 'shared/components/Dropdown';

const UserTypeSelectCoach = () => {
  return (
    <UserTypeSelect>
      <Box style={{ width: '100%' }} role="listbox">
        <Flex alignItems="stretch" flexDirection="column">
          <DropdownItem value={USER_TYPES.COACH}>
            {`${typeEmojies.coach} Coach`}
          </DropdownItem>
        </Flex>
      </Box>
      <Box style={{ width: '100%' }} role="listbox">
        <Flex alignItems="stretch" flexDirection="column">
          <DropdownItem value={USER_TYPES.USER}>
            {`${typeEmojies.user} Participant`}
          </DropdownItem>
        </Flex>
      </Box>
      <Box style={{ width: '100%' }} role="listbox">
        <Flex alignItems="stretch" flexDirection="column">
          <DropdownItem value={APOLLO_OPTION}>
            {`${typeEmojies.apollo} Apollo`}
          </DropdownItem>
        </Flex>
      </Box>
    </UserTypeSelect>
  );
};

export default UserTypeSelectCoach;
