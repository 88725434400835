import { useParams } from 'react-router-dom';

import { useCurrentSprint, useSprints } from 'features/sprint/hooks';

export const useLastSprint = () => {
  const { email } = useParams();
  const { data: sprint } = useCurrentSprint();
  const { data: sprints } = useSprints(email);

  return sprint || sprints?.[0];
};
