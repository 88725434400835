import { isImage } from 'shared_DEPRECATED/components/File/utils';
import { dateUtils, firstLetterUpperCase } from 'shared_DEPRECATED/utils';
import { DAILY_STATUS_VALUES } from 'shared_DEPRECATED/utils/config';

import {
  CHALLENGE_TYPES,
  TARGET_TYPES,
  DAY_VALUE_MAP,
  WEEKDAY_TARGET_DEFAULT,
  WEEKDAYS_VALUE_ON_TYPE_MAP,
  CHALLENGE_FIELD_TYPES,
  TARGET_PERIODS,
  EVERYDAY,
} from 'features/challenge/config';

export const dimensionFirstLetterToUpperCase = (dimension) =>
  dimension
    .split(' & ')
    .map((word) => firstLetterUpperCase(word))
    .join(' & ');

export const mapWeekdayTargetToWeeklyTarget = (
  weekdayTarget,
  sprintWeeksCount
) => {
  weekdayTarget = weekdayTarget || {};
  const oneWeekTarget = Object.values(weekdayTarget).reduce((acc, val) => {
    if (val) {
      acc++;
    }

    return acc;
  }, 0);

  return new Array(sprintWeeksCount).fill(oneWeekTarget);
};

export const getDefaultChallengeType = (availableChallengeFields) =>
  Object.entries(availableChallengeFields).reduce(
    (challengeType, [field, isAvailable]) =>
      isAvailable ? CHALLENGE_FIELD_TYPES[field] : challengeType,
    null
  );

export const getChallengeTypeByTarget = (target) =>
  target.type === TARGET_TYPES.DUE_DATE
    ? CHALLENGE_TYPES.NON_REPEATING
    : CHALLENGE_TYPES.REPEATING;

export const getChallengeTargetAmountPluralUnitsText = (pluralUnits) =>
  `${pluralUnits.slice(0, -1)}(s)`;

export const getChallengeTargetText = (
  challengeTargetAmount,
  challengeTargetPeriod
) => {
  if (!challengeTargetAmount?.value || !challengeTargetAmount?.unit) {
    return 'Select target';
  }

  return `${
    challengeTargetAmount.value
  } ${getChallengeTargetAmountPluralUnitsText(
    challengeTargetAmount.unit
  )} per ${TARGET_PERIODS[challengeTargetPeriod.toUpperCase()]}`;
};

const setDefaultChallengeTarget = (challengeTarget) =>
  challengeTarget.type === TARGET_TYPES.DUE_DATE && !challengeTarget.dueDate
    ? {
        type: TARGET_TYPES.DUE_DATE,
        dueDate: dateUtils().format('YYYY-MM-DD'),
      }
    : challengeTarget;

export const mapChallenge = (challenge) => ({
  ...challenge,
  type: challenge.type ?? getChallengeTypeByTarget(challenge.target),
  target: setDefaultChallengeTarget(challenge.target),
});

const getWeekdayTargetValue = (weekdayTarget) =>
  Object.entries(weekdayTarget).reduce((acc, [day, value]) => {
    if (value) {
      acc += DAY_VALUE_MAP[day];
    }

    return acc;
  }, 0);

export const onFrequencyCheckboxChange = (day, value, setValue) => (event) => {
  const { checked } = event.target;
  const isOneDayChecked = Object.values(DAY_VALUE_MAP).find(
    (dayValue) => dayValue === getWeekdayTargetValue(value)
  );

  !(isOneDayChecked && !checked) &&
    setValue({
      type: TARGET_TYPES.WEEKDAY,
      weekday: {
        ...WEEKDAY_TARGET_DEFAULT,
        ...value,
        [day.toLowerCase()]: checked,
      },
    });
};

export const getWeekdayTargetText = (weekdayTarget, format = 'weekdaysMin') => {
  const weekdayTargetValue = getWeekdayTargetValue(weekdayTarget);

  const weekdayType = WEEKDAYS_VALUE_ON_TYPE_MAP[weekdayTargetValue];

  if (weekdayType) {
    return weekdayType;
  }

  const weekdaysMinString = dateUtils
    .weekDays('weekdaysShort')
    .map((day) => weekdayTarget[day.toLowerCase()])
    .map((isDayChecked, index) =>
      isDayChecked ? dateUtils.weekDays(format)[index] : isDayChecked
    )
    .filter(Boolean)
    .join('/');

  return weekdaysMinString;
};

export const getChallengeFrequencyText = (
  challengeTarget,
  sprintDuration = 6,
  weekdaysFormat = 'weekdaysMin'
) => {
  if (!challengeTarget?.type) {
    return 'Select frequency';
  }

  if (!Object.values(TARGET_TYPES).includes(challengeTarget.type)) {
    return '';
  }

  if (challengeTarget.type === TARGET_TYPES.DUE_DATE) {
    return dateUtils(challengeTarget.dueDate).format('MMM D, YYYY');
  }

  if (challengeTarget.type === TARGET_TYPES.WEEKLY) {
    const uniqueFrequencies = new Set(challengeTarget.weekly);
    const isConsistentFrequency = uniqueFrequencies.size === 1;
    const [singleFrequency] = uniqueFrequencies;

    if (isConsistentFrequency) {
      if (singleFrequency === dateUtils.WEEK_DAYS_COUNT) {
        return EVERYDAY;
      }

      return `${singleFrequency} day(s)/week`;
    }

    return challengeTarget.weekly.slice(0, sprintDuration).join('/');
  }

  return getWeekdayTargetText(challengeTarget.weekday, weekdaysFormat);
};

export const getDailyStatusImageUrl = (medias) => {
  const lastMedia = medias?.at(-1);
  if (!lastMedia) {
    return '';
  }

  return isImage(lastMedia.contentType)
    ? lastMedia.url
    : lastMedia.thumbnail.url;
};

export const getDailyStatusSetOnDate = (dailyStatuses) => {
  const statuses = dailyStatuses;

  for (const date in statuses) {
    if (
      statuses[date]?.status &&
      statuses[date].status !== DAILY_STATUS_VALUES.EMPTY
    ) {
      return date;
    }
  }
  return undefined;
};

export const getDailyStatusMediaList = (target, formattedDate) => {
  const notes =
    target.type === TARGET_TYPES.DUE_DATE
      ? target?.notesPerDate?.[formattedDate]
      : target.dailyStatus?.[formattedDate]?.notes;

  return notes?.flatMap((note) => note.media || []) || [];
};
