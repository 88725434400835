import { useCallback } from 'react';

import { GoalDeleteDialog } from 'features/goal/components/Button/Delete/Dialog';

import { modal, SIZES } from 'shared/components/Modal';

export const useOpenDeleteGoalModal = () =>
  useCallback(({ onSubmit }: { onSubmit: () => Promise<void> }) => {
    modal.open({
      content: <GoalDeleteDialog onSubmit={onSubmit} />,
      props: {
        size: SIZES.SM,
      },
    });
  }, []);
