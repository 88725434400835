import { useParams } from 'react-router-dom';

import {
  SprintNavigationActive,
  SprintNavigationEmpty,
} from 'features/sprint/components/Navigation';
import { useSprints } from 'features/sprint/hooks';

export const SprintNavigation = () => {
  const { email } = useParams();
  const { data: sprints } = useSprints(email);

  if (!sprints) {
    return null;
  }

  if (sprints?.length === 0) {
    return <SprintNavigationEmpty />;
  }

  return <SprintNavigationActive />;
};
