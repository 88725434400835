import {
  ACCEPTS,
  GOAL_ACCEPTS_TYPE,
  GoalCard,
  useMoveGoalMutation,
} from 'features/goal';
import { BoardColumnAddGoalButton } from 'features/goal/components/Board/AddGoal/AddGoalButton';
import { GoalCardProvider } from 'features/goal/context/cardContext';
import { useRoadmapDataContext } from 'features/goal/context/roadmapData';
import { useMoveRoadmapGoals } from 'features/goal/hooks/useMoveRoadmapGoals';
import { usePrioritizeToggleOnBoard } from 'features/goal/hooks/usePrioritizeToggleOnBoard';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
} from 'shared/components/Board';
import { BoardColumnItem } from 'shared/components/Board/components/Column/Item';
import { Droppable } from 'shared/components/DragNDrop/Droppable';
import { Typography } from 'shared/components/Typography';

export const GoalBoardColumns = () => {
  const { mutate } = useMoveGoalMutation();
  const { moveItemToColumn, moveItemInList } = useMoveRoadmapGoals();
  const togglePrioritizeOnBoard = usePrioritizeToggleOnBoard();
  const { roadmapData } = useRoadmapDataContext();

  return (
    <>
      {roadmapData?.map((column, columnIndex) => (
        <Droppable
          key={column.columnId}
          acceptType={ACCEPTS}
          onDrop={({ id, index, isColumnChanged }) => {
            isColumnChanged &&
              mutate({
                id,
                index,
                columnId: column.columnId,
              });
          }}
          onHover={moveItemToColumn(columnIndex)}
        >
          <BoardColumnWrapper
            fraction={roadmapData.length}
            key={`${column.label}-${columnIndex}`}
          >
            <BoardColumnHeader>
              <Typography color="gray" type="meta">
                {column.label}
              </Typography>
              <BoardColumnAddGoalButton columnId={column.columnId} />
            </BoardColumnHeader>
            <BoardColumn>
              {column.goals.length > 0
                ? column.goals.map((goal, index) => {
                    return (
                      // eslint-disable-next-line
                      <BoardColumnItem
                        key={goal.goalId}
                        id={goal.goalId}
                        index={index}
                        columnIndex={columnIndex}
                        columnId={column.columnId}
                        type={GOAL_ACCEPTS_TYPE}
                        onHover={moveItemInList}
                        onDrop={mutate}
                      >
                        <GoalCardProvider
                          columnIndex={columnIndex}
                          goalIndex={index}
                        >
                          <GoalCard
                            dataTestid={`goal-${columnIndex}-${index}`}
                            togglePrioritize={togglePrioritizeOnBoard}
                          />
                        </GoalCardProvider>
                      </BoardColumnItem>
                    );
                  })
                : null}
            </BoardColumn>
          </BoardColumnWrapper>
        </Droppable>
      ))}
    </>
  );
};
