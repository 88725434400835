import { MenuItem } from '@chakra-ui/react';

import { Typography } from 'shared';

import {
  useRemovePlanningDraftSprintChallengesMutation,
  IPlanningDraftSprint,
  usePlanningAnalyticsEvents,
} from 'features/planning';

type TPlanningDraftSprintMenuRemoveButton = {
  challengeId: string;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintMenuRemoveButton = ({
  challengeId,
  sprint,
}: TPlanningDraftSprintMenuRemoveButton) => {
  const { sendPlanningChallengeRemovedEvent } = usePlanningAnalyticsEvents();

  const { deleteSprintChallenges } =
    useRemovePlanningDraftSprintChallengesMutation({
      sprint,
    });

  const handleChallengeRemove = () => {
    sendPlanningChallengeRemovedEvent();

    deleteSprintChallenges([challengeId]);
  };

  return (
    <MenuItem
      onClick={handleChallengeRemove}
      _hover={{ outline: 'none', borderRadius: 'var(--border-radius)' }}
      width="100%"
    >
      <Typography color="black">Remove</Typography>
    </MenuItem>
  );
};
