import { ReactNode } from 'react';

import { Typography } from 'shared';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { Tooltip } from 'shared/components/Tooltip';

type TGoalCreatedAt = {
  createdAt: string;
};

function withTooltip(tooltip: string, element: ReactNode) {
  return <Tooltip anchor={element}>{tooltip}</Tooltip>;
}

export const Createdat = ({ createdAt }: TGoalCreatedAt) =>
  withTooltip(
    'Created',
    <Typography
      as="span"
      type="xsm"
      color="gray"
      fontWeight="medium"
      whiteSpace="nowrap"
    >
      {dateUtils(createdAt).format('D MMM, YYYY')}
    </Typography>
  );
