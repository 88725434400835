import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

export const GoalEditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <ButtonIcon
      ariaLabel="edit goal"
      icon={
        <Icon
          name={iconNames.pen}
          width={16}
          height={18}
          fill="var(--fgPrimary)"
        />
      }
      onClick={onClick}
      size="md"
      variant={VARIANTS.SECONDARY}
    />
  );
};
