import { Nullable } from 'shared_DEPRECATED/types';
import { ObjectValues } from 'types/common';

import { NonRepeatedChallenge } from 'features/challenge/NonRepeatedChallenge';
import { RepeatedChallenge } from 'features/challenge/RepeatedChallenge';
import {
  IRepeatedChallenge,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';
import { ICoachingTool } from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import { SPRINT_ENTITY_TYPES } from 'features/planning/config';
import { FinalSprint } from 'features/sprint';
import { SPRINT_TYPES } from 'features/sprint/config';
import {
  IFinalSprint,
  ISprint,
  SprintFinishedMetaData,
  SprintRestartedMetaData,
} from 'features/sprint/config/types';
import { BRIDGE_MODE_TYPES } from 'features/timeline/config/index';

export interface IPlanningSprint extends Omit<ISprint, 'oneTimeChallenges'> {
  finished: Nullable<SprintFinishedMetaData>;
  restartedFromSprint: Nullable<SprintRestartedMetaData>;
  durationInWeeks: Nullable<number>;
  quaziBridgeMode: ObjectValues<typeof BRIDGE_MODE_TYPES>;
}

class PlanningSprint extends FinalSprint implements IFinalSprint {
  challenges: (IRepeatedChallenge | INonRepeatedChallenge)[];
  readonly type: typeof SPRINT_TYPES[keyof typeof SPRINT_TYPES];
  coachingTools: ICoachingTool[];
  finished: Nullable<SprintFinishedMetaData>;
  sprintRestartedMetaData: Nullable<SprintRestartedMetaData>;
  durationInWeeks: Nullable<number>;
  quaziBridgeMode: ObjectValues<typeof BRIDGE_MODE_TYPES>;

  constructor(sprint: IPlanningSprint) {
    super(sprint);

    this.challenges = sprint.challenges.map((challenge) =>
      this.isNonRepeatedChallenge(challenge)
        ? new NonRepeatedChallenge({
            ...challenge,
            sprintId: sprint.sprintId,
          })
        : new RepeatedChallenge({
            ...challenge,
            sprintId: sprint.sprintId,
          })
    );
    this.coachingTools = sprint.coachingTools.map(
      (coachingTool) => new CoachingTool(coachingTool)
    );
    this.type = sprint.type;
    this.finished = sprint.finished;
    this.sprintRestartedMetaData = sprint.restartedFromSprint;
    this.durationInWeeks = sprint.durationInWeeks;
    this.quaziBridgeMode = sprint.quaziBridgeMode;
  }

  isNonRepeatedChallenge(
    challenge: INonRepeatedChallenge | IRepeatedChallenge
  ): challenge is INonRepeatedChallenge {
    return !!(challenge as INonRepeatedChallenge).target?.dueDate;
  }

  get entityType() {
    return !this.quaziBridgeMode
      ? SPRINT_ENTITY_TYPES.SPRINT
      : SPRINT_ENTITY_TYPES.BRIDGE_MODE;
  }
}

export { PlanningSprint as PlanningSprintModel };
