import { Typography } from 'shared';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { getTimeZoneName } from 'shared/utils/timezone';

type UserInfoTimezoneProps = {
  timezone: Nullable<string>;
};

export const UserInfoTimezone = ({ timezone }: UserInfoTimezoneProps) => {
  const tz = timezone || dateUtils.tz();
  const formattedTimeZone = `${tz.replace(/_/g, ' ')} ${getTimeZoneName(tz)}`;

  return (
    <Typography
      color="black"
      type="small"
      cropped
      maxWidth="150px"
      title={formattedTimeZone}
      data-testid="participant-time-zone"
    >
      {formattedTimeZone}
    </Typography>
  );
};
