import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { IPlanningDraftSprint } from 'features/planning';

type TUseAddChallengesToSprintMutation = {
  sprint: IPlanningDraftSprint;
  options?: any;
};

export const useAddChallengesToSprintMutation = ({
  sprint,
  options,
}: TUseAddChallengesToSprintMutation) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    async (challenges: (IRepeatedChallenge | INonRepeatedChallenge)[]) =>
      await request({
        url: `/api/web/sprints/${sprint.sprintId}/challenges/create`,
        body: { challenges },
      }),
    options
  );
};
