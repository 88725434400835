import { Flex } from 'shared_DEPRECATED/components/Flex';

import { ParticipantsTableEmptyCell } from 'features/participants/components/Table';
import { ParticipantsTableRowDataType } from 'features/participants/config/types';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type CurrentWeekCellProps = {
  value: number;
  row: ParticipantsTableRowDataType;
};

export const CurrentWeekCell = ({
  value: currentWeekNumber,
  row,
}: CurrentWeekCellProps) => {
  const { latestSprint } = row.original;
  const hasWeekNumber = currentWeekNumber > 0;

  return latestSprint ? (
    <Flex>
      <Badge bgColor={hasWeekNumber ? 'lightBlue' : 'gray'} size="sm">
        <Typography
          as="span"
          type="small"
          color={hasWeekNumber ? 'blue' : 'black'}
          fontWeight="regular"
          dataTestid="participant-sprint-week"
        >
          {latestSprint.weekLabelForSprintDate}
        </Typography>
      </Badge>
    </Flex>
  ) : (
    <ParticipantsTableEmptyCell dataTestid="participant-sprint-week" />
  );
};
