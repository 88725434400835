import { HStack } from '@chakra-ui/react';
import type React from 'react';
import { When } from 'react-if';
import { NavLink } from 'react-router-dom';

import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Typography,
  VARIANTS,
} from 'shared';
import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { getParticipantUrl } from 'features/participants/utils';

type Participant = {
  userName: string;
  userEmail: string;
  userId: string;
  pictureUrl: string;
};

type ParticipantsMenuProps = {
  participants: Participant[];
  isActive: boolean;
};

export const HeaderTabsParticipantsMenu = ({
  participants,
  isActive,
}: ParticipantsMenuProps) => {
  return (
    <Menu placement="bottom-end" offset={[0, 5]}>
      <MenuButton
        as={Button}
        variant={isActive ? VARIANTS.PRIMARY : VARIANTS.SECONDARY}
        size="xs"
        rightIcon={
          <Icon
            name={iconNames.chevronDown}
            stroke={isActive ? 'var(--fgPrimaryInverse)' : 'var(--fgPrimary)'}
          />
        }
      >
        <Typography color={isActive ? 'white' : 'black'} fontSize="small">
          Participants
        </Typography>
      </MenuButton>
      <MenuList p={0}>
        <ParticipantMenuItem to="/coach/participants" text="All participants" />
        {participants
          .sort((p1, p2) => p1.userName.localeCompare(p2.userName))
          .map(({ userName, userId, userEmail, pictureUrl }) => (
            <ParticipantMenuItem
              key={userId}
              to={getParticipantUrl(userEmail)}
              text={userName}
              pictureUrl={pictureUrl}
            />
          ))}
      </MenuList>
    </Menu>
  );
};

const ParticipantMenuItem = ({
  to,
  text,
  pictureUrl,
}: {
  to: string;
  text: string;
  pictureUrl?: string;
}) => (
  <MenuItem p={0}>
    <NavLink to={to} style={{ width: '100%', height: '100%' }}>
      <HStack w="full" h="full" px="12px" alignItems="center">
        <When condition={pictureUrl}>
          <Avatar name={text} size={SIZES.SM} src={pictureUrl} />
        </When>
        <Typography type="small" color="black">
          {text}
        </Typography>
      </HStack>
    </NavLink>
  </MenuItem>
);
