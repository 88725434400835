import { Button, Typography, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { CoachingMode } from 'features/participants/config/types';
import { useParticipantsTableScheduleSessionMutateOptions } from 'features/participants/hooks/useParticipantsTableScheduleSessionMutateOptions';
import { CoachSessionScheduleModal } from 'features/sessions/components/Modal/CoachSessionScheduleModal';
import { SprintsType } from 'features/sprint/config/types';

import { modal, SIZES } from 'shared/components/Modal';

type ParticipantsScheduleSessionDateTimeButtonProps = {
  recentSprints: SprintsType;
  userId: string;
  userEmail: string;
  coachingMode: CoachingMode;
};

export const ParticipantsScheduleSessionDateTimeButton = ({
  recentSprints,
  userId,
  userEmail,
  coachingMode,
}: ParticipantsScheduleSessionDateTimeButtonProps) => {
  const options = useParticipantsTableScheduleSessionMutateOptions({
    userEmail,
  });

  const handleModalOpen = () => {
    modal.open({
      content: <CoachSessionScheduleModal />,
      props: { size: SIZES.SM, title: 'Schedule session modal' },
      context: {
        sprints: recentSprints,
        userId,
        options,
        coachingMode,
      },
    });
  };
  return (
    <Button
      onClick={handleModalOpen}
      leftIcon={
        <Icon
          name={iconNames.editCalendar}
          fill="gray"
          width={16}
          height={16}
        />
      }
      variant={VARIANTS.TERTIARY}
      dataTestid="participants-table-schedule-session-button"
    >
      <Typography fontSize="small" color="gray">
        Schedule session
      </Typography>
    </Button>
  );
};
