import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { SPRINT_STATES } from 'features/sprint/config';

import { Typography } from 'shared/components/Typography';

const sprintInfoStyleMap = {
  [SPRINT_STATES.ACTIVE]: {
    backgroundColor: 'transparent',
    textColor: 'black',
  },
  [SPRINT_STATES.FINISHED]: {
    backgroundColor: 'var(--bgCompPrimaryInverse)',
    textColor: 'white',
  },
  [SPRINT_STATES.DRAFT]: {
    backgroundColor: 'var(--brdSuccess)',
    textColor: 'white',
  },
};

const SprintInfoStatusPropTypes = {
  status: PropTypes.oneOf(Object.values(SPRINT_STATES)).isRequired,
  successRate: PropTypes.number,
  weekIndex: PropTypes.number,
  weeksCount: PropTypes.number,
};

export const SprintInfoStatus = ({
  status,
  successRate,
  weekIndex,
  weeksCount,
}) => {
  const sprintInfoContentMap = {
    [SPRINT_STATES.ACTIVE]: `${weekIndex}/${weeksCount}`,
    [SPRINT_STATES.FINISHED]: `${successRate || 0}%`,
    [SPRINT_STATES.DRAFT]: 'D',
  };

  const progressPercentage = (weekIndex / weeksCount) * 100;

  return (
    <Box
      style={{
        width: '2.25rem',
        height: '2.25rem',
        borderRadius: '50%',
        position: 'relative',
        backgroundColor: sprintInfoStyleMap[status].backgroundColor,
      }}
    >
      {status === SPRINT_STATES.ACTIVE && (
        <>
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              background: 'var(--brdSecondary)',
              mask: 'radial-gradient(transparent 55%, black 56%)',
              WebkitMask: 'radial-gradient(transparent 55%, black 56%)',
            }}
          />
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              background: `conic-gradient(
                var(--brdAccent) ${progressPercentage}%,
                transparent ${progressPercentage}%
              )`,
              mask: 'radial-gradient(transparent 55%, black 56%)',
              WebkitMask: 'radial-gradient(transparent 55%, black 56%)',
            }}
          />
        </>
      )}
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          type="small"
          fontWeight="semiBold"
          color={sprintInfoStyleMap[status].textColor}
        >
          {sprintInfoContentMap[status]}
        </Typography>
      </Flex>
    </Box>
  );
};

SprintInfoStatus.propTypes = SprintInfoStatusPropTypes;
