import { Else, If, Then } from 'react-if';

import { ParticipantsSessionDateTimeMenu } from 'features/participants/components/Table/DateCellContent/ParticipantsSessionDateTimeMenu';
import { ParticipantsScheduleSessionDateTimeButton } from 'features/participants/components/Table/DateCellContent/SelectSessionDateTimeButton';
import { CoachingMode } from 'features/participants/config/types';
import { SessionAttendanceStatusType } from 'features/sessions/config/types';
import { IDraftSprint, ISprint } from 'features/sprint/config/types';

type SessionDateCellContentProps = {
  userEmail: string;
  userId: string;
  startAt: string;
  sessionId: string;
  attendance: SessionAttendanceStatusType;
  latestSprint?: ISprint | IDraftSprint;
  coachingMode: CoachingMode;
};

export const SessionDateCellContent = ({
  userEmail,
  userId,
  startAt,
  sessionId,
  attendance,
  latestSprint,
  coachingMode,
}: SessionDateCellContentProps) => {
  const recentSprints = latestSprint ? [latestSprint] : [];

  return (
    <If condition={!startAt}>
      <Then>
        <ParticipantsScheduleSessionDateTimeButton
          userEmail={userEmail}
          userId={userId}
          recentSprints={recentSprints}
          coachingMode={coachingMode}
        />
      </Then>
      <Else>
        <ParticipantsSessionDateTimeMenu
          userEmail={userEmail}
          startAt={startAt}
          sessionId={sessionId}
          attendance={attendance}
          coachingMode={coachingMode}
        />
      </Else>
    </If>
  );
};
