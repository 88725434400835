import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Typography } from 'shared';
import { DatePickerTime } from 'shared_DEPRECATED/components/Form/DatePicker';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  getDateStringWithUpdatedTime,
  getSessionEndTime,
} from 'features/sessions/utils';

import { useModalContext } from 'shared/components/Modal';

export const CoachSessionsScheduleStartTimeSelector = () => {
  const { setValue } = useFormContext();
  const { coachingMode } = useModalContext();
  const [sessionStartDate, sessionEndDate, coachingSessionDate]: [
    string,
    string,
    string
  ] = useWatch({
    name: ['startAt', 'endAt', 'coachingSessionDate'],
  });

  const onTimeChange = (date: Date) => {
    const updatedStartDate = getDateStringWithUpdatedTime(sessionEndDate, date);

    setValue('startAt', updatedStartDate, {
      shouldDirty: true,
    });

    const updatedSessionEndDate = getSessionEndTime(
      updatedStartDate,
      coachingMode
    );

    setValue('endAt', updatedSessionEndDate);
  };

  useEffect(() => {
    const updatedStartDate = getDateStringWithUpdatedTime(
      coachingSessionDate,
      sessionStartDate
    );
    setValue('startAt', updatedStartDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachingSessionDate, sessionStartDate]);

  return (
    <Typography as="div" type="meta" dataTestid="session-start-time">
      <DatePickerTime
        date={dateUtils(sessionStartDate).toDate()}
        onChange={onTimeChange}
      />
    </Typography>
  );
};
