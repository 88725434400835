import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { Messages } from 'features/chat/config/types/Message';

type ChatHistoryQueryProps = {
  chatId: string;
  enabled?: boolean;
  limit: number;
};

const CHAT_MESSAGES_TO_LOAD = 20;

export const useChatHistoryQuery = ({
  chatId,
  enabled = true,
  limit = CHAT_MESSAGES_TO_LOAD,
}: ChatHistoryQueryProps) => {
  const { request } = useQueryHTTPRequest();

  return useQuery<{ messages: Messages }, unknown, Messages>(
    [queryKeys.chatMessagesHistory, chatId],
    () => {
      const urlSearchParams = new URLSearchParams({
        limit: limit.toString(),
      });
      return request({
        url: `/api/chats/${chatId}/history?${urlSearchParams.toString()}`,
      });
    },
    {
      select: (data) => data.messages,
      enabled,
    }
  );
};
