import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { ScheduledSessionConfigType } from 'features/sessions/config/types';

type SessionScheduleMutationProps = {
  userId: string;
  previousSessionId: string;
  options: any;
};

export const useSessionScheduleMutation = ({
  userId,
  previousSessionId,
  options,
}: SessionScheduleMutationProps) => {
  const { request } = useMutationHTTPRequest();

  return useMutation<
    any,
    { name: string },
    Omit<ScheduledSessionConfigType, 'endAt'>
  >(
    (sessionConfig) =>
      request({
        url: '/api/coach/coaching-sessions/schedule',
        body: {
          clientId: userId,
          previousSessionId,
          ...sessionConfig,
        },
      }),
    {
      ...options,
    }
  );
};
