import { VARIANTS } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import ButtonLink from 'shared_DEPRECATED/components/Button/ButtonLink';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  SprintInfoBody,
  SprintInfoContainer,
  SprintInfoContent,
  SprintInfoStatus,
  SprintInfoTitle,
} from 'features/sprint/components/Info';
import { SprintSourceTypeBadge } from 'features/sprint/components/SprintSourceTypeBadge';
import { useLastSprint } from 'features/sprint/hooks/useLastSprint';
import { useTimelineOrSprintNavigationLink } from 'features/timeline/hooks/useTimelineOrSprintNavigationLink';

export const SprintNavigationActive = () => {
  const lastSprint = useLastSprint();
  const sprintLink = useTimelineOrSprintNavigationLink({
    sprintId: lastSprint?.sprintId,
  });

  return (
    <ButtonLink to={sprintLink} variant={VARIANTS.TERTIARY}>
      <SprintInfoContainer>
        <Spacer size="zr md zr zr">
          <Spacer size="zr sm zr zr">
            <SprintInfoStatus
              weekIndex={lastSprint.currentWeekNumber}
              successRate={lastSprint.successRate}
              status={lastSprint.state}
              weeksCount={lastSprint.weeksCount}
            />
          </Spacer>
        </Spacer>
        <SprintInfoBody>
          <Box style={{ overflow: 'hidden', width: '9rem' }}>
            <SprintInfoTitle>{lastSprint.title}</SprintInfoTitle>
          </Box>
          <SprintInfoContent>{lastSprint.formattedDate}</SprintInfoContent>
          <SprintSourceTypeBadge
            label={lastSprint.sprintTypeLabel}
            dataTestid="sprint-type-label"
          />
        </SprintInfoBody>
      </SprintInfoContainer>
    </ButtonLink>
  );
};
