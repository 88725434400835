export const findIndexByProp = (
  arr: any[],
  prop: string,
  value: any
): number => {
  return arr.findIndex((item) => item[prop] === value);
};

export const deleteItemMutative = (arr: any[], index: number) => {
  const [item] = arr.splice(index, 1);

  return item;
};

export const insertItemMutative = (
  arr: any[],
  index: number,
  item: any
): void => {
  arr.splice(index, 0, item);
};

export const partitionItems = <T>(
  items: T[],
  visibleItemCount: number = 1
): { firstItems: T[]; secondItems: T[]; remainingCount: number } => {
  const firstItems = items.slice(0, visibleItemCount);
  const secondItems = items.slice(visibleItemCount);
  const remainingCount = Math.max(0, items.length - visibleItemCount);

  return { firstItems, secondItems, remainingCount };
};

export const sortArrayByObjectFieldValue = <T extends Record<string, any>>(
  arr: T[],
  fieldName: keyof T,
  options: {
    caseSensitive?: boolean;
    nullsLast?: boolean;
  } = {}
): T[] => {
  const { caseSensitive = true, nullsLast = false } = options;

  const sorted = [...arr].sort((a, b) => {
    const aValue = a[fieldName];
    const bValue = b[fieldName];

    if (aValue == null && bValue == null) {
      return 0;
    }

    if (aValue == null) {
      return nullsLast ? 1 : -1;
    }

    if (bValue == null) {
      return nullsLast ? -1 : 1;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return caseSensitive
        ? aValue.localeCompare(bValue)
        : aValue.toLowerCase().localeCompare(bValue.toLowerCase());
    }

    return aValue - bValue;
  });

  return sorted;
};
