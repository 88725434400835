import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { SPRINT_WEEK_NUMBER } from 'features/participants/config';
import { Participant } from 'features/participants/config/types';
import { ParticipantSprint } from 'features/sprint';
import { SPRINT_STATES } from 'features/sprint/config';

export const useParticipantsQuery = (
  participantsMapper = mapParticipantData
) => {
  const { request } = useQueryHTTPRequest();
  const { data, isFetching, isLoading } = useQuery(
    [queryKeys.participants],
    () => request({ url: `/api/coach/clients` }),

    {
      select: (data) => data?.items.map(participantsMapper),
    }
  );

  return { participants: data || [], isFetching, isLoading };
};

export const mapParticipantData = (participant: Participant) => {
  const {
    userEmail,
    userName: fullName,
    firstName,
    lastName,
    userPicture,
    lastActive,
    latestSprint,
    coachingStatus,
    coachingMode,
    coachedSprintsCount,
    nextCoachingSessionAppointment,
    unreadChatMessagesNumber,
    unreadNotesRepliesNumber,
    chatId,
    userId,
    firstUnreadJournalRecordDatetime,
    unreadJournalRecordsNumber,
    timezone,
  } = participant;

  const hasSprint = !!latestSprint;
  const mappedSprint = hasSprint && new ParticipantSprint(latestSprint);

  return {
    userInfo: {
      email: userEmail,
      picture: userPicture,
      fullName,
      firstName,
      lastName,
      userId,
      timezone,
    },
    sprintDuration: hasSprint ? mappedSprint.duration : null,
    status: hasSprint ? mappedSprint.state : SPRINT_STATES.MISSING,
    latestSprint: mappedSprint,
    lastActive,
    coachingStatus,
    coachingMode,
    coachedSprintsCount,
    sprintType: mappedSprint?.sprintTypeLabel,
    currentWeekNumber: hasSprint
      ? mappedSprint.weekNumberLabel
      : SPRINT_WEEK_NUMBER.NO_SPRINT,
    nextCoachingSessionAppointment,
    unreadChatMessagesNumber,
    unreadNotesRepliesNumber,
    chatId,
    momentumScores: mappedSprint.momentumScores,
    journal: {
      firstUnreadJournalRecordDatetime,
      unreadJournalRecordsNumber,
    },
    chat: {
      unreadChatMessagesNumber,
      chatId,
    },
  };
};
