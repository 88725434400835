import PropTypes from 'prop-types';

import { PictureType } from 'shared_DEPRECATED/config/propTypes';
import { dateUtils } from 'shared_DEPRECATED/utils';
import { DAILY_STATUS_VALUES } from 'shared_DEPRECATED/utils/config';

import {
  CHALLENGE_FIELDS,
  CHALLENGE_FIELD_TYPES,
  DEFAULT_EMOJI,
  DIMENSIONS,
  TARGET_DATE_TYPES,
  TARGET_TYPES,
} from 'features/challenge/config';
import { ChallengeTargetType } from 'features/challenge/config/propTypes';
import { TARGET_PERIOD } from 'features/challenge/config/types';
import {
  getDailyStatusImageUrl,
  getDailyStatusMediaList,
} from 'features/challenge/utils';
import { ParticipantSprint, Sprint } from 'features/sprint';
import {
  SPRINT_STATES,
  SPRINT_TYPES,
  TEST_WEEK_NUMBER,
} from 'features/sprint/config';

const sortSprintsByDate = (sprints) =>
  sprints.sort((s1, s2) => {
    if (s1.state === SPRINT_STATES.ACTIVE) {
      return -1;
    }
    if (s2.state === SPRINT_STATES.ACTIVE) {
      return 1;
    }

    return new Date(s2.startDate) - new Date(s1.startDate);
  });

export const mapSprints = ({ sprints, sprintType }) => {
  const mappedSprints = sprints.map((sprint) => {
    const SprintClass =
      sprintType === SPRINT_TYPES.COACHED ? ParticipantSprint : Sprint;

    return new SprintClass({ ...sprint, type: sprintType });
  });

  return sortSprintsByDate(mappedSprints);
};

export const getDailyStatuses = (selectedWeekDates, challenges) =>
  selectedWeekDates
    ? challenges.map((challenge) =>
        selectedWeekDates.map((formattedDate) => {
          const target = challenge.target;

          const mediaList = getDailyStatusMediaList(target, formattedDate);

          if (target.type === TARGET_TYPES.DUE_DATE) {
            return {
              status:
                (target.statusSetOn === formattedDate && target.status) ||
                DAILY_STATUS_VALUES.EMPTY,
              imageUrl: getDailyStatusImageUrl(
                target?.notesPerDate?.[formattedDate]?.at(-1)?.media
              ),
              mediaList,
            };
          }

          if (target.period && target.period !== TARGET_PERIOD.PER_DAY) {
            return {
              status:
                target.dailyStatus?.[formattedDate]?.status ||
                DAILY_STATUS_VALUES.EMPTY,
              date: formattedDate,
              amount: target.dailyStatus?.[formattedDate]?.amount
                ? challenge.getAmountOnCurrentDate(formattedDate)
                : 0,
              delta: target.dailyStatus?.[formattedDate]?.amount,
              imageUrl: getDailyStatusImageUrl(
                target.dailyStatus?.[formattedDate]?.notes?.at(-1)?.media
              ),
              mediaList,
            };
          }

          return {
            status:
              target.dailyStatus?.[formattedDate]?.status ||
              DAILY_STATUS_VALUES.EMPTY,
            date: formattedDate,
            amount: target.dailyStatus?.[formattedDate]?.amount,
            imageUrl: getDailyStatusImageUrl(
              target.dailyStatus?.[formattedDate]?.notes?.at(-1)?.media
            ),
            mediaList,
          };
        })
      )
    : null;

export const ChallengeType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  sprintChallengeId: PropTypes.string,
  dimensions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DIMENSIONS)))
    .isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  emoji: PropTypes.string,
  picture: PictureType,
  target: ChallengeTargetType,
});

export const SprintType = PropTypes.shape({
  challenges: PropTypes.arrayOf(ChallengeType).isRequired,
  endDate: PropTypes.string.isRequired,
  sprintId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  state: PropTypes.oneOf(Object.values(SPRINT_STATES)).isRequired,
  title: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userName: PropTypes.string,
  userPicture: PictureType,
  userSharingCount: PropTypes.number,
});

export const sharedSprintLinks = {
  participants: '/coach/participants',
  connections: '/connections',
};

export const groupBySprintWeek = (items, sprint) => {
  const { startDate, weeks } = sprint;

  return items.reduce((sectionsAcc, item) => {
    const challengeStatusDateTimestamp = dateUtils.timestampFromFormattedDate(
      item?.data?.statusDate || item.createdAt
    );
    const sprintStartDateTimestamp =
      dateUtils.timestampFromFormattedDate(startDate);

    let currentWeekNumber =
      challengeStatusDateTimestamp < sprintStartDateTimestamp
        ? 0
        : weeks.findIndex(
            (weekDates) =>
              challengeStatusDateTimestamp >=
                dateUtils.timestampFromFormattedDate(weekDates.at(0)) &&
              challengeStatusDateTimestamp <=
                dateUtils.timestampFromFormattedDate(weekDates.at(-1))
          );

    if (currentWeekNumber === -1) {
      currentWeekNumber = weeks.length - 1;
    }

    if (!sectionsAcc[getWeekLabel(currentWeekNumber)]) {
      sectionsAcc[getWeekLabel(currentWeekNumber)] = [];
    }
    sectionsAcc[getWeekLabel(currentWeekNumber)].push(item);

    return sectionsAcc;
  }, {});
};

export const shouldShowFrequency = (challengeTarget) => {
  const period = challengeTarget?.period;
  // For per-week and per-sprint challenges, no need to show frequency
  return !(
    period &&
    [TARGET_PERIOD.PER_WEEK, TARGET_PERIOD.PER_SPRINT].includes(period)
  );
};

export const getIsDailyStatusDisabled = ({
  dayFormattedDate,
  sprintEndDate,
  sprintStartDate,
}) => {
  const isCurrentDateBeforeSprint =
    dateUtils.isCurrentDateBeforeDate(sprintStartDate);
  const isCurrentDateAfterSprint =
    dateUtils.isCurrentDateAfterDate(sprintEndDate);
  const isCurrentDateBeforeCurrentDailyStatusDay =
    dateUtils.isCurrentDateBeforeDate(dayFormattedDate);

  return isCurrentDateAfterSprint
    ? false
    : isCurrentDateBeforeSprint || isCurrentDateBeforeCurrentDailyStatusDay;
};

export const getIsDailyStatusDisabledByTargetType = ({
  target,
  dayIndex,
  dayFormattedDate,
  //statusSetOn and status fields are present only
  //in TimelineChallenge due to different data structure
  statusSetOn,
  status,
}) => {
  if (target.type === TARGET_TYPES.WEEKDAY) {
    const isDayPresentInWeekdayTarget =
      target.weekday[
        dateUtils.weekDays('weekdaysShort')[dayIndex].toLowerCase()
      ];

    return !isDayPresentInWeekdayTarget;
  }
  const dailyStatus = target.status ?? status;
  const dailyStatusSetOn = target.statusSetOn ?? statusSetOn;

  const isOneTimeChallengeStatusSet = dailyStatus !== DAILY_STATUS_VALUES.EMPTY;

  const isDayStatusSetOn = dayFormattedDate === dailyStatusSetOn;

  if (target.type === TARGET_TYPES.DUE_DATE) {
    return statusSetOn
      ? !isDayStatusSetOn
      : isOneTimeChallengeStatusSet && !isDayStatusSetOn;
  }

  return false;
};

export const addChallengeSource = (challenge) =>
  challenge.curatedChallengeId || challenge.customChallengeId
    ? {
        ...challenge,
        source: {
          curatedChallengeId: challenge.curatedChallengeId,
          customChallengeId: challenge.customChallengeId,
        },
      }
    : challenge;

export const getWeekLabel = (weekIndex) => {
  const weekNumber = weekIndex + 1;
  const testWeekLabel = ' (Test)';

  return `Week ${weekNumber}${
    weekNumber === TEST_WEEK_NUMBER ? testWeekLabel : ''
  }`;
};

export const getDueDateStatus = (target) => {
  const isEmptyStatus = target.status === DAILY_STATUS_VALUES.EMPTY;
  const isDueDateToday = dateUtils(target.dueDate).isToday();
  const isTodayBeforeDueDate = dateUtils().isBefore(target.dueDate, 'day');

  if (isEmptyStatus && isDueDateToday) {
    return TARGET_DATE_TYPES.TODAY_WITHOUT_STATUS;
  }

  if (isEmptyStatus && isTodayBeforeDueDate) {
    return TARGET_DATE_TYPES.IN_TIME_WITHOUT_STATUS;
  }

  const isStatusSetInTime = dateUtils(target.statusSetOn).isSameOrBefore(
    target.dueDate,
    'day'
  );
  if (!isEmptyStatus && isStatusSetInTime) {
    return TARGET_DATE_TYPES.IN_TIME_WITH_STATUS;
  }

  return TARGET_DATE_TYPES.LATE;
};

export const getParticipantSprintPageUrl = ({ email, sprintId }) =>
  `/coach/participants/${email}/${sprintId}`;

export const getChallengesByWeekDates = ({ challenges, fromDate, toDate }) =>
  challenges.filter((challenge) => {
    if (!challenge.startDate || !challenge.endDate) {
      return true;
    }

    return (
      dateUtils(challenge.startDate).isSameOrBefore(fromDate) &&
      dateUtils(challenge.endDate).isSameOrAfter(toDate)
    );
  });

export const mapSprintFormData = (sprint) => {
  const sprintChallenges = {};

  Object.values(CHALLENGE_FIELDS).forEach((challengeField) => {
    sprintChallenges[challengeField] = sprint[challengeField]
      .filter(
        ({ isLatestChallengeVersion }) => isLatestChallengeVersion ?? true
      )
      .map((challenge) => ({
        ...challenge,
        type: CHALLENGE_FIELD_TYPES[challengeField],
        emoji: !(challenge.emoji || challenge.picture)
          ? DEFAULT_EMOJI
          : challenge.emoji,
      }));
  });

  if (sprint.sprintId === SPRINT_TYPES.DRAFT) {
    delete sprint.sprintId;
  }

  return { ...sprint, ...sprintChallenges };
};

export const getSprintsWithoutDraft = (sprints) =>
  sprints.filter(({ state }) => state !== SPRINT_STATES.DRAFT);

export const isDraftSprint = (sprintState) =>
  !sprintState || sprintState === SPRINT_STATES.DRAFT;
