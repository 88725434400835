import { createContext, useContext, useMemo } from 'react';

import { IGoal } from 'features/goal';
import { useRoadmapDataContext } from 'features/goal/context/roadmapData';

interface GoalCardContextType {
  goal: IGoal;
}

const GoalCardContext = createContext<GoalCardContextType | undefined>(
  undefined
);

export const GoalCardProvider = ({
  children,
  columnIndex,
  goalIndex,
}: {
  children: React.ReactNode;
  columnIndex: number;
  goalIndex: number;
}) => {
  const { roadmapData } = useRoadmapDataContext();
  const goal = roadmapData![columnIndex].goals[goalIndex];

  const contextValue = useMemo(
    () => ({
      goal,
    }),
    [goal]
  );

  return (
    <GoalCardContext.Provider value={contextValue}>
      {children}
    </GoalCardContext.Provider>
  );
};

export const useGoalCardContext = () => {
  const goalCardContext = useContext(GoalCardContext);

  if (!goalCardContext) {
    throw new Error(
      'useGoalCardContext must be used within a GoalCardProvider'
    );
  }

  return goalCardContext;
};
