import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import { IGoal } from 'features/goal/types';

export const useUploadGoalCover = ({ options }: { options?: any }) => {
  const { request } = useMutationHTTPRequest();

  return useMutation(
    async ({ file, goal }: { file: string; goal: IGoal }) =>
      await request({
        url: `/api/goals/${goal.goalId}/upload-cover`,
        body: {
          file: {
            contents: file,
          },
        },
      }),
    {
      ...options,
      onError: (err, ...args) => {
        options?.onError?.(err, ...args);
        logError(err);
      },
    }
  );
};
