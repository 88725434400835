import { lazy, Suspense, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { IGoal } from 'features/goal/types';

import { modal, SIZES } from 'shared/components/Modal';

const GoalAddConfirmationDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/goal/components/Button/Add/Dialog'),
    'GoalAddDialog'
  )
);

export const useOpenEditGoalModal = (title?: string) =>
  useCallback(
    ({
      onSubmit,
      goal,
    }: {
      onSubmit: (goal: IGoal) => Promise<void>;
      goal: IGoal;
    }) => {
      modal.open({
        content: (
          <Suspense fallback={<Loader size="sm" />}>
            <GoalAddConfirmationDialogContent
              onSubmit={onSubmit}
              goal={goal}
              title={title}
            />
          </Suspense>
        ),
        props: {
          size: SIZES.MD,
        },
      });
    },
    [title]
  );
