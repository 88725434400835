import { MenuItem } from 'shared';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import {
  usePlanningDraftSprintsQueryActions,
  useAddDraftSprintChallenge,
  IPlanningDraftSprint,
  usePlanningAnalyticsEvents,
} from 'features/planning';

type TPlanningDraftSprintAddChallengeButton = {
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintAddChallengeButton = ({
  sprint,
}: TPlanningDraftSprintAddChallengeButton) => {
  const { sendPlanningChallengeAddedEvent } = usePlanningAnalyticsEvents();
  const { getDraftSprints, updateDraftSprints } =
    usePlanningDraftSprintsQueryActions();

  const openAddChallengeModal = useAddDraftSprintChallenge({
    sprint,
    options: {
      onSuccess: async (
        // @ts-ignore
        updatedSprint
      ) => {
        const previousDraftSprintsValue = getDraftSprints();

        const updatedItems = updateArrayById({
          array: previousDraftSprintsValue!.items,
          updatedItem: updatedSprint,
          idProperty: 'sprintId',
        });

        updateDraftSprints(updatedItems);
        sendPlanningChallengeAddedEvent('sprintDraft');
      },
    },
  });

  return (
    <MenuItem
      onClick={openAddChallengeModal}
      data-testid="add-challenge-draft-sprint-option"
    >
      Add challenge
    </MenuItem>
  );
};
