import { IconButton, Menu, MenuList } from '@chakra-ui/react';
import { Case, Default, Switch, When } from 'react-if';

import { MenuButton, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';

import { SessionsTableMoreOptionsCancelMenuItem } from 'features/sessions/components/Table/MoreOptions/CancelSessionMenuItem';
import { SessionsTableMoreOptionsDeleteMenuItem } from 'features/sessions/components/Table/MoreOptions/DeleteSessionMenuItem';
import { SessionsTableMoreOptionsRescheduleMenuItem } from 'features/sessions/components/Table/MoreOptions/RescheduleSessionMenuItem';
import { SESSION_ATTENDANCE_STATUS } from 'features/sessions/config';
import { SessionAttendanceStatusType } from 'features/sessions/config/types';
import { useParticipantCoachingMode } from 'features/sessions/hooks/useParticipantCoachingMode';
import { useSessionsPageCancelSessionMutateOptions } from 'features/sessions/hooks/useSessionsPageCancelSessionMutateOptions';
import { useSessionsPageRescheduleMutateOptions } from 'features/sessions/hooks/useSessionsPageRescheduleMutateOptions';

type SessionsTableMoreOptionsMenuProps = {
  sessionId: string;
  sessionStartAt: Nullable<string>;
  sessionEndAt: Nullable<string>;
  attendance: SessionAttendanceStatusType;
};

export const SessionsTableMoreOptionsMenu = ({
  attendance,
  sessionId,
  sessionStartAt,
  sessionEndAt,
}: SessionsTableMoreOptionsMenuProps) => {
  const cancelMutateOptions =
    useSessionsPageCancelSessionMutateOptions(sessionId);
  const rescheduleMutateOptions =
    useSessionsPageRescheduleMutateOptions(sessionId);
  const coachingMode = useParticipantCoachingMode();

  return (
    <Menu placement="bottom-start">
      <MenuButton
        as={IconButton}
        _hover={{
          bg: 'gray.200',
        }}
        icon={<Icon name={iconNames.dotsVert} />}
        variant={VARIANTS.TERTIARY}
        size="sm"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      />
      <MenuList>
        <Switch>
          <Case condition={attendance === SESSION_ATTENDANCE_STATUS.SCHEDULED}>
            <When condition={sessionStartAt && sessionEndAt}>
              <SessionsTableMoreOptionsRescheduleMenuItem
                sessionId={sessionId}
                sessionStartAt={sessionStartAt!}
                sessionEndAt={sessionEndAt!}
                options={rescheduleMutateOptions}
                coachingMode={coachingMode}
              />
            </When>
            <SessionsTableMoreOptionsCancelMenuItem
              sessionId={sessionId}
              options={cancelMutateOptions}
            />
            <SessionsTableMoreOptionsDeleteMenuItem sessionId={sessionId} />
          </Case>
          <Case
            condition={
              attendance === SESSION_ATTENDANCE_STATUS.CANCELED ||
              attendance === SESSION_ATTENDANCE_STATUS.NO_SHOW
            }
          >
            <SessionsTableMoreOptionsDeleteMenuItem sessionId={sessionId} />
          </Case>
          <Case
            condition={
              attendance === SESSION_ATTENDANCE_STATUS.ATTENDED ||
              attendance === SESSION_ATTENDANCE_STATUS.RESCHEDULED
            }
          >
            <SessionsTableMoreOptionsRescheduleMenuItem
              sessionId={sessionId}
              sessionStartAt={sessionStartAt!}
              sessionEndAt={sessionEndAt!}
              options={rescheduleMutateOptions}
              coachingMode={coachingMode}
            />
            <SessionsTableMoreOptionsCancelMenuItem
              sessionId={sessionId}
              options={cancelMutateOptions}
            />
            <SessionsTableMoreOptionsDeleteMenuItem sessionId={sessionId} />
          </Case>
          <Default>
            <SessionsTableMoreOptionsDeleteMenuItem sessionId={sessionId} />
          </Default>
        </Switch>
      </MenuList>
    </Menu>
  );
};
