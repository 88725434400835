import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { FailReasonLabels, FailReasonsType } from 'features/journal';

import { Typography } from 'shared/components/Typography';

type TJournalCardFailReasons = {
  reasons: FailReasonsType;
};

export const JournalCardFailReasons = ({
  reasons = [],
}: TJournalCardFailReasons) =>
  reasons.length > 0 ? (
    <Flex flexWrap="wrap" justifyContent="flex-start">
      {reasons.map((reason) => (
        <Spacer size="zr sm sm zr" key={reason}>
          <Box
            style={{
              borderRadius: 'var(--border-radius4)',
              backgroundColor: 'var(--bgCompSecondaryBig)',
            }}
          >
            <Spacer size="xsm md">
              <Typography
                type="small"
                color="black"
                data-testid="journal-sidebar-card-fail-reason"
              >
                {FailReasonLabels[reason]}
              </Typography>
            </Spacer>
          </Box>
        </Spacer>
      ))}
    </Flex>
  ) : null;
