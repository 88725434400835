import { Box, HStack } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

type WarningTextProps = {
  children: React.ReactNode;
};

export const WarningText = ({ children }: WarningTextProps) => {
  return (
    <Box
      border="1px solid #F49D25"
      bg="#FDEDD8"
      p="8px 12px"
      borderRadius="var(--border-radius4)"
      color="#C37E1E"
    >
      <HStack>
        <Icon name={iconNames.warning} width={20} height={20} />
        <Box w="90%">{children}</Box>
      </HStack>
    </Box>
  );
};
