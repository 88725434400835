import {
  NEW_SESSION_CREATION_FLOW_FEATURE_FLAG,
  useFeatureFlag,
} from 'featureFlags';

export const useNewSessionCreationFlowHeaders = () => {
  const isNewSessionCreationFlowEnabled = useFeatureFlag(
    NEW_SESSION_CREATION_FLOW_FEATURE_FLAG
  );

  return isNewSessionCreationFlowEnabled ? { 'hOS-API-Version': '14' } : {};
};
