import { useCallback, useEffect } from 'react';

import { CONSTANTS } from 'config/envVariables';
import { logError } from 'lib/sentry/logError';
import { useEventListener } from 'usehooks-ts';

import { useGetAccessToken } from 'features/auth/hooks/useGetAccessToken';

export const useSendJWTTokenToExtension = () => {
  const getAccessToken = useGetAccessToken();

  // @ts-ignore
  const extId = CONSTANTS.SESSION_TOOL_EXTENSION_ID;
  // @ts-ignore
  const hosIncStoreExtensionId = CONSTANTS.HOS_INC_STORE_EXTENSION_ID;

  const sendJwtToken = useCallback(async () => {
    // @ts-ignore
    const chromeInstance = chrome;
    if (!chromeInstance?.runtime) {
      return;
    }
    const token = await getAccessToken();

    try {
      chromeInstance.runtime.sendMessage(extId, { jwt: token });
    } catch (error) {
      logError(`Error sending JWT token to session tool extension: ${error}`);
    }

    try {
      chromeInstance.runtime.sendMessage(hosIncStoreExtensionId, {
        jwt: token,
      });
    } catch (error) {
      logError(`Error sending JWT token to hos inc store extension: ${error}`);
    }
  }, [getAccessToken, extId, hosIncStoreExtensionId]);

  useEffect(() => {
    sendJwtToken();
  }, [sendJwtToken]);

  const windowFocusHandler = useCallback(() => {
    sendJwtToken();
  }, [sendJwtToken]);

  useEventListener('focus', windowFocusHandler);
};
