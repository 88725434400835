import { HStack } from '@chakra-ui/react';
import { Case, Default, Switch } from 'react-if';

import {
  MenuButton,
  MenuList,
  Menu,
  Typography,
  Button,
  VARIANTS,
} from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { CoachingMode } from 'features/participants/config/types';
import { SessionsTableMoreOptionsCancelMenuItem } from 'features/sessions/components/Table/MoreOptions/CancelSessionMenuItem';
import { SessionsTableMoreOptionsDeleteMenuItem } from 'features/sessions/components/Table/MoreOptions/DeleteSessionMenuItem';
import { SessionsTableMoreOptionsRescheduleMenuItem } from 'features/sessions/components/Table/MoreOptions/RescheduleSessionMenuItem';
import { SessionsTableMoreOptionsViewSessionMenuItem } from 'features/sessions/components/Table/MoreOptions/ViewSessionMenuItem';
import { SESSION_ATTENDANCE_STATUS } from 'features/sessions/config';
import { SessionAttendanceStatusType } from 'features/sessions/config/types';
import { useParticipantsTableCancelMutateOptions } from 'features/sessions/hooks/useParticipantsTableCancelMutateOptions';
import { useParticipantsTableRescheduleMutateOptions } from 'features/sessions/hooks/useParticipantsTableRescheduleMutateOptions';

type ParticipantsSessionDateTimeMenuProps = {
  userEmail: string;
  startAt: string;
  sessionId: string;
  attendance: SessionAttendanceStatusType;
  coachingMode: CoachingMode;
};

export const ParticipantsSessionDateTimeMenu = ({
  userEmail,
  startAt,
  sessionId,
  attendance,
  coachingMode,
}: ParticipantsSessionDateTimeMenuProps) => {
  const cancelMutateOptions =
    useParticipantsTableCancelMutateOptions(sessionId);
  const rescheduleMutateOptions =
    useParticipantsTableRescheduleMutateOptions(sessionId);

  return (
    <Menu>
      <MenuButton as={Button} variant={VARIANTS.TERTIARY}>
        <HStack gap={0}>
          <Typography fontSize="small" fontWeight="medium">
            {dateUtils.localDate(startAt).format('MMM D, h:mm a')}
          </Typography>
          <Icon name={iconNames.dotsVert} />
        </HStack>
      </MenuButton>
      <MenuList w="140px">
        <Switch>
          <Case condition={attendance === SESSION_ATTENDANCE_STATUS.SCHEDULED}>
            <SessionsTableMoreOptionsViewSessionMenuItem
              userEmail={userEmail}
              sessionId={sessionId}
            />
            <SessionsTableMoreOptionsRescheduleMenuItem
              sessionId={sessionId}
              sessionStartAt={startAt}
              options={rescheduleMutateOptions}
              coachingMode={coachingMode}
            />
            <SessionsTableMoreOptionsCancelMenuItem
              sessionId={sessionId}
              options={cancelMutateOptions}
            />
            <SessionsTableMoreOptionsDeleteMenuItem sessionId={sessionId} />
          </Case>
          <Case
            condition={
              attendance === SESSION_ATTENDANCE_STATUS.ATTENDED ||
              attendance === SESSION_ATTENDANCE_STATUS.RESCHEDULED
            }
          >
            <SessionsTableMoreOptionsViewSessionMenuItem
              userEmail={userEmail}
              sessionId={sessionId}
            />
            <SessionsTableMoreOptionsRescheduleMenuItem
              sessionId={sessionId}
              sessionStartAt={startAt}
              options={rescheduleMutateOptions}
              coachingMode={coachingMode}
            />
            <SessionsTableMoreOptionsCancelMenuItem
              sessionId={sessionId}
              options={cancelMutateOptions}
            />
            <SessionsTableMoreOptionsDeleteMenuItem sessionId={sessionId} />
          </Case>
          <Case
            condition={
              attendance === SESSION_ATTENDANCE_STATUS.CANCELED ||
              attendance === SESSION_ATTENDANCE_STATUS.NO_SHOW
            }
          >
            <SessionsTableMoreOptionsViewSessionMenuItem
              userEmail={userEmail}
              sessionId={sessionId}
            />
            <SessionsTableMoreOptionsDeleteMenuItem sessionId={sessionId} />
          </Case>
          <Default>
            <SessionsTableMoreOptionsViewSessionMenuItem
              userEmail={userEmail}
              sessionId={sessionId}
            />
            <SessionsTableMoreOptionsDeleteMenuItem sessionId={sessionId} />
          </Default>
        </Switch>
      </MenuList>
    </Menu>
  );
};
