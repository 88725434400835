import { CoachingMode } from 'features/participants/config/types';
import { CoachSessionRescheduleModal } from 'features/sessions/components/Form/Actions/RescheduleModal';

import { modal, SIZES } from 'shared/components/Modal';

type TUseRescheduleSessionProps = {
  sessionId: string;
  sessionStartAt: string;
  sessionEndAt?: string;
  coachingMode: CoachingMode;
  options?: any;
};

export const useRescheduleSession = ({
  sessionId,
  sessionStartAt,
  sessionEndAt,
  options,
  coachingMode,
}: TUseRescheduleSessionProps) => {
  const handleRescheduleModalOpen = () => {
    modal.open({
      content: (
        <CoachSessionRescheduleModal
          sessionId={sessionId}
          mutateOptions={options}
        />
      ),
      props: { size: SIZES.SM, title: 'Reschedule session' },
      context: {
        sessionStartAt,
        sessionEndAt,
        coachingMode,
      },
    });
  };

  return {
    handleRescheduleModalOpen,
  };
};
