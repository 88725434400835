import { Box } from 'shared';

import { ChallengeCard } from 'features/challenge/components/ChallengeCard';
import { IChallenge } from 'features/challenge/config/types';
import {
  PlanningBacklogMenu,
  useOpenEditBacklogChallengeDialog,
} from 'features/planning';

type TPlanningBacklogChallengeCard = {
  challenge: IChallenge;
};

export const PlanningBacklogChallengeCard = ({
  challenge,
}: TPlanningBacklogChallengeCard) => {
  const { title, picture, emoji, dimensions, goals, tags, target } = challenge;
  const openEditChallengeModal = useOpenEditBacklogChallengeDialog({
    challenge,
  });

  return (
    <Box onClick={openEditChallengeModal}>
      <ChallengeCard
        title={title}
        picture={picture}
        emoji={emoji}
        dimensions={dimensions}
        target={target}
        goals={goals}
        tags={tags}
      >
        <PlanningBacklogMenu challenge={challenge} />
      </ChallengeCard>
    </Box>
  );
};
