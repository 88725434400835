import { Box } from 'shared';

import { ChallengeCard } from 'features/challenge/components/ChallengeCard';
import { IChallenge } from 'features/challenge/config/types';
import {
  PlanningDraftSprintsItemMenu,
  IPlanningDraftSprint,
  useOpenEditDraftSprintChallengeDialog,
} from 'features/planning';

type TPlanningDraftSprintChallengeCard = {
  challenge: IChallenge;
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintChallengeCard = ({
  challenge,
  sprint,
}: TPlanningDraftSprintChallengeCard) => {
  const { title, picture, emoji, dimensions, goals, tags, target } = challenge;

  const openEditChallengeModal = useOpenEditDraftSprintChallengeDialog({
    challenge,
    sprint,
  });

  return (
    <Box onClick={openEditChallengeModal}>
      <ChallengeCard
        title={title}
        picture={picture}
        emoji={emoji}
        dimensions={dimensions}
        target={target}
        goals={goals}
        tags={tags}
      >
        <PlanningDraftSprintsItemMenu challenge={challenge} sprint={sprint} />
      </ChallengeCard>
    </Box>
  );
};
