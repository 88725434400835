export const queryKeys = Object.freeze({
  addNoteReply: 'add-note-replies',
  assessments: 'assessments',
  attachment: 'attachment',
  mySprints: 'my-sprints',
  sprints: 'sprints',
  draftSprints: 'draft-sprints',
  participants: 'participants',
  taskManagerParticipants: 'task-manager-participants',
  participant: 'participant',
  user: 'user',
  challenges: 'challenges',
  challengeGoals: 'challenge-goals',
  libraryChallenges: 'library-challenges',
  connections: 'connections',
  dimensions: 'dimensions',
  sharedSprints: 'shared-sprints',
  notifications: 'notifications',
  challengeNotes: 'challenge-notes',
  users: 'users',
  coaches: 'coaches',
  coachingTools: 'coachingTools',
  chatMessages: 'chats-messages',
  chatMessagesHistory: 'chats-messages-history',
  chats: 'chats',
  coachProfile: 'coach-profile',
  coachSessions: 'coach-sessions',
  coachSessionAppointment: 'coach-session-appointment',
  quickStartSprint: 'quick-start-sprint',
  quickStartSprints: 'quick-start-sprints',
  goals: 'goals',
  goal: 'goal',
  goalBreakdown: 'goal-breakdown',
  reflection: 'reflection',
  statistic: 'statistic',
  participantsSprints: 'participants-sprints',
  noteReplies: 'note-replies',
  scheduledMessages: 'scheduled-messages',
  version: 'version',
  challengeTargetUnits: 'challenge-target-units',
  coachingTimezones: 'coaching-timezones',
  file: 'file',
  momentum: 'momentum',
  myGroups: 'my-groups',
  participantSessions: 'participant-sessions',
  coachSession: 'coach-session',
  coach: 'coach',
  history: 'history',
  linkPreview: 'link-preview',
  journal: 'journal',
  subAccounts: 'sub-accounts',
  tasks: 'tasks',
});

export const HTTP_ERROR_STATUS_CODES = {
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  INTERNAL_ERROR: '500',
  BAD_GATEWAY: '502',
  SERVICE_UNAVAILABLE: '503',
  GATEWAY_TIMEOUT: '504',
};

export const DEFAULT_RETRY_COUNT = 3;

export const API_VERSION = '13';

// relates to sub accounts
export const HOS_SUB_ACCOUNT_USER_ID = 'hos-sub-acc-user-id';
export const HOS_SUB_ACCOUNT_GROUP_ID = 'hos-sub-acc-group-id';
