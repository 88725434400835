import { useSprintsQuery } from 'features/sprint/hooks';

import { PlanningSprintModel } from '../../planning/Sprint';
import { SPRINT_ENTITY_TYPES } from '../config';

type TParams = {
  sprintChallengesCount: number;
  sprintTitle: string;
};

export const useIsDraftSprintReadyToStart = ({
  sprintChallengesCount,
  sprintTitle,
}: TParams) => {
  const { data: sprints } = useSprintsQuery<PlanningSprintModel[]>();
  const hasActiveSprint =
    sprints &&
    sprints.length > 0 &&
    sprints[0].isActive &&
    sprints[0].entityType !== SPRINT_ENTITY_TYPES.BRIDGE_MODE;

  return !hasActiveSprint && sprintChallengesCount > 0 && sprintTitle;
};
